import { TypedDocumentNode, gql } from '@apollo/client';

import useMutation from '@sorare/core/src/hooks/graphql/useMutation';

import { hasJoinedSquad } from 'lib/rivals';

import {
  joinSquadMutation,
  joinSquadMutationVariables,
} from './__generated__/useJoinRivalsSquad.graphql';

const JOIN_SQUAD_MUTATION = gql`
  mutation joinSquadMutation($input: joinSo5UserGroupInput!) {
    joinSo5UserGroup(input: $input) {
      currentUser {
        slug
        currentFootballRivalsManager {
          id
          ...hasJoinedSquad_footballRivalsCurrentManager
        }
      }
      errors {
        code
        message
      }
    }
  }
  ${hasJoinedSquad.fragments.footballRivalsCurrentManager}
` as TypedDocumentNode<joinSquadMutation, joinSquadMutationVariables>;
export const useJoinRivalsSquad = () => {
  return useMutation(JOIN_SQUAD_MUTATION, {
    showErrorsWithSnackNotification: true,
  });
};
