import isMobileWeb from 'is-mobile';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  MessagingContext,
  SignUp,
  Sport as WalletSport,
} from '@sorare/wallet-shared';
import { SignupPlatform, Sport } from '__generated__/globalTypes';
import { AcceptTermsInfo, useConnectionContext } from 'contexts/connection';
import { useCurrentUserContext } from 'contexts/currentUser';
import useSignUp from 'hooks/auth/useSignUp';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import useQueryString from 'hooks/useQueryString';
import useCreateJwtToken from 'hooks/users/useCreateJwtToken';
import { useEvents } from 'lib/events/useEvents';
import { LoginWithJWTToken_Sport } from 'protos/webview/channel/native_messages';

const readAcceptanceTerms = (
  args: SignUp['request']['args']
): AcceptTermsInfo => {
  return {
    acceptTerms: args.acceptTerms,
    acceptAgeLimit: args.acceptAgeLimit,
    acceptPrivacyPolicy: args.acceptPrivacyPolicy,
    agreedToReceiveOffersFromPartners: args.agreedToReceiveOffersFromPartners,
    acceptGameRules: true,
  };
};

const WALLET_SPORT_TO_SPORT: { [key in WalletSport]: Sport } = {
  FOOTBALL: Sport.FOOTBALL,
  NBA: Sport.NBA,
  BASEBALL: Sport.BASEBALL,
};

const LoginWithJWTTokenSports: Record<WalletSport, LoginWithJWTToken_Sport> = {
  FOOTBALL: LoginWithJWTToken_Sport.FOOTBALL,
  NBA: LoginWithJWTToken_Sport.NBA,
  BASEBALL: LoginWithJWTToken_Sport.BASEBALL,
};

export default () => {
  const { registerHandler } = useContext(MessagingContext)!;
  const signUp = useSignUp();
  const [acceptedTerms, setAcceptedTerms] = useState<AcceptTermsInfo | null>(
    null
  );
  const { refetch } = useCurrentUserContext();
  const { promptTerms, closeConnectionDialog } = useConnectionContext()!;
  const platform = useQueryString('signupPlatform');
  const track = useEvents();
  const { formatMessage } = useIntl();
  const { isAndroidApp, postMessage } = useIsMobileApp();
  const createJwtToken = useCreateJwtToken();

  useEffect(
    () =>
      registerHandler<SignUp>('signUp', async args => {
        const { mobile, sport, ...rest } = args;

        // Terms and Conditions Modal
        const acceptTerms = acceptedTerms || readAcceptanceTerms(args);

        setAcceptedTerms(acceptTerms);

        let signupPlatform: SignupPlatform = SignupPlatform.WEB;
        if (mobile) {
          if (platform === 'ios') {
            signupPlatform = SignupPlatform.IOS;
          } else if (platform === 'android') {
            signupPlatform = SignupPlatform.ANDROID;
          }
        } else if (isMobileWeb()) {
          signupPlatform = SignupPlatform.MOBILE_WEB;
        }

        track('Click Signup By Email', {});
        const { data } = await signUp({
          ...rest,
          signupPlatform,
          signupSport: WALLET_SPORT_TO_SPORT[sport],
          certified: 'certified',
          ...acceptTerms,
        });

        if (data!.signUp) {
          const { errors } = data!.signUp;
          if (errors && errors.length !== 0) {
            track('Fail Signup By Email', {});
            return {
              error: errors.reduce<Record<string, string>>((sum, err) => {
                if (err.path?.length !== 2) return sum;
                sum[err.path[1]] = err.message;
                return sum;
              }, {}),
            };
          }
        }

        setAcceptedTerms(null);
        closeConnectionDialog();

        if (data?.signUp?.currentUser) {
          if (isAndroidApp) {
            const { data: jwtData } = await createJwtToken({
              variables: { input: { aud: 'sorare-android' } },
            });
            if (jwtData?.createJwtToken?.jwtToken) {
              postMessage('loginWithJwtToken', {
                email: data.signUp.currentUser.email,
                userId: data.signUp.currentUser.id,
                token: jwtData.createJwtToken.jwtToken.token,
                expiredAt: jwtData.createJwtToken.jwtToken.expiredAt.getTime(),
                sport: LoginWithJWTTokenSports[sport],
              });
            } else {
              postMessage('logout', { isExplicit: true });
            }
          }
        }

        return {};
      }),
    [
      registerHandler,
      signUp,
      promptTerms,
      acceptedTerms,
      closeConnectionDialog,
      refetch,
      platform,
      track,
      formatMessage,
      isAndroidApp,
      postMessage,
      createJwtToken,
    ]
  );
};
