import { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';

import useQueryString from '@sorare/core/src/hooks/useQueryString';

import Form from '../Form';
import useConfirmSo5NoCardLineup from './useConfirmSo5NoCardLineup';

const messages = defineMessages({
  title: {
    id: 'NoCardEntry.Confirm.title',
    defaultMessage:
      'Your team is confirmed and will be submitted in the Game Week!',
  },
});

interface Props {
  so5LineupId: string;
}

const Confirm = ({ so5LineupId }: Props) => {
  const so5LeaderboardId = useQueryString('so5_leaderboard_id');
  const [confirmLineup, loading] = useConfirmSo5NoCardLineup();
  const [mutationResult, setMutationResult] =
    useState<Awaited<ReturnType<typeof confirmLineup>>>();

  useEffect(() => {
    confirmLineup({
      so5NoCardLineupId: `So5NoCardLineup:${so5LineupId}`,
      so5LeaderboardId: `So5Leaderboard:${so5LeaderboardId}`,
    }).then(setMutationResult);
  }, [so5LineupId, so5LeaderboardId, confirmLineup]);

  return (
    <Form
      title={messages.title}
      postAction="lobby"
      loading={loading}
      {...(mutationResult || {})}
    />
  );
};

export default Confirm;
