import classNames from 'classnames';
import { SpringValue, animated } from 'react-spring';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';

const Root = styled(Horizontal).attrs({ gap: 0 })`
  width: 100%;
  isolation: isolate;
`;
const Bar = styled(Horizontal)`
  background-color: var(--c-black);
  border: 2px solid var(--c-nd-50);
  height: var(--triple-unit);
  width: 100%;
  border-radius: 0 100px 100px 0;
  padding: 5px;
  margin-left: calc(-1 * var(--unit));

  .small & {
    height: var(--intermediate-unit);
    border-width: 1px;
    padding: 2px;
  }
`;
const Filled = styled(animated.div)`
  background-color: var(--c-rivals-friendlies);
  height: 100%;
  width: 100%;
  border-radius: 0 100px 100px 0;
`;
const LevelWrapper = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;
  width: var(--quadruple-unit);
  height: var(--quadruple-unit);
  aspect-ratio: 1;
  background: var(--c-black);
  border-radius: 50%;
  border: 2px solid var(--c-nd-50);
  font-family: var(--rigid-square);

  .small & {
    width: var(--double-unit);
    height: var(--double-unit);
    font-size: 8px;
    border-width: 1px;
  }
`;

type Props = {
  level: SpringValue<number>;
  percentage: SpringValue<number>;
  size?: 'small' | 'medium';
};
export const CardXpProgressBar = ({
  level,
  percentage,
  size = 'medium',
}: Props) => {
  return (
    <Root className={classNames(size)}>
      <LevelWrapper>{level.to(x => Math.round(x))}</LevelWrapper>
      <Bar>
        <Filled style={{ width: percentage.to(x => `${x}%`) }} />
      </Bar>
    </Root>
  );
};
