import classNames from 'classnames';
import { PropsWithChildren, useRef, useState } from 'react';
import styled, { CSSProperties, keyframes } from 'styled-components';

import { Boost } from '@sorare/core/src/atoms/icons/Boost';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';

const cardPosition = keyframes`
  0% {
    transform: translateY(0px);
  }
  30% {
    transform: translateY(-25px);
  }
  100% {
    transform: translateY(0px);
  }
`;
const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
const bigBoost = keyframes`
  0% {
    opacity: 0;
    top: 90%;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 20%;
  }
`;
const mediumBoost = keyframes`
  0% {
    opacity: 0;
    top: 80%;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 15%;
  }
`;
const mediumBoost2 = keyframes`
  0% {
    opacity: 0;
    top: 75%;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 10%;
  }
`;
const Animation = styled.div`
  pointer-events: none;
`;
const Root = styled(Vertical)`
  --delay: 0ms;

  position: relative;
  isolation: isolate;

  &.animating {
    animation: 1.7s ${cardPosition} var(--delay) cubic-bezier(1, 0, 0.67, 1);
  }
`;
const AnimatedBoostWrapper = styled.div`
  position: absolute;
  opacity: 0;
  z-index: 2;
`;
const BigAnimatedBoostWrapper = styled(AnimatedBoostWrapper)`
  left: 50%;
  transform: translateX(-50%);
  bottom: -50px;

  .animating & {
    animation: 500ms ${bigBoost} calc(var(--delay) + 400ms)
      cubic-bezier(0.19, -0, 0.23, 1) forwards;
  }
`;
const MediumAnimatedBoostWrapper = styled(AnimatedBoostWrapper)`
  left: -2%;
  bottom: 0;

  .animating & {
    animation: 500ms ${mediumBoost} calc(var(--delay) + 600ms)
      cubic-bezier(0.19, -0, 0.23, 1) forwards;
  }
`;
const MediumAnimatedBoostWrapper2 = styled(AnimatedBoostWrapper)`
  right: -1%;
  bottom: 0;

  .animating & {
    animation: 500ms ${mediumBoost2} calc(var(--delay) + 800ms)
      cubic-bezier(0.19, -0, 0.23, 1) forwards;
  }
`;
const BigBoost = styled(Boost)`
  height: 100px !important;
`;
const MediumBoost = styled(Boost)`
  height: 70px !important;
`;

const GlowFilter = styled.div`
  position: absolute;
  inset: 0;
  z-index: -1;
  filter: blur(100px) saturate(5);
  opacity: 0;

  & > * {
    max-width: 100%;
  }

  .animating & {
    animation: 1700ms ${fadeInOut} var(--delay) cubic-bezier(1, 0, 0.67, 1)
      forwards;
  }
`;
const BlueBg = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;

  opacity: 0;
  border-radius: var(--double-unit);
  background: linear-gradient(
    to bottom,
    transparent 10%,
    var(--c-rivals-friendlies)
  );

  .animating & {
    animation: 1400ms ${fadeInOut} var(--delay) cubic-bezier(1, 0, 0.67, 1)
      forwards;
  }
`;

type Props = { animate?: boolean; delay?: number; onAnimationEnd?: () => void };
export const LevelUpAnimation = ({
  children,
  animate,
  delay = 0,
  onAnimationEnd,
}: PropsWithChildren<Props>) => {
  const [animating, setAnimating] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  if (animate && !animating) {
    setAnimating(true);
  }

  const content = (
    <>
      {animating && (
        <Animation>
          <BigAnimatedBoostWrapper>
            <BigBoost color="var(--c-rivals-friendlies)" />
          </BigAnimatedBoostWrapper>
          <MediumAnimatedBoostWrapper>
            <MediumBoost color="var(--c-rivals-friendlies)" />
          </MediumAnimatedBoostWrapper>
          <MediumAnimatedBoostWrapper2>
            <MediumBoost color="var(--c-rivals-friendlies)" />
          </MediumAnimatedBoostWrapper2>
          <BlueBg />
        </Animation>
      )}
      {children}
    </>
  );

  return (
    <Root
      ref={ref}
      className={classNames({ animating })}
      style={{ '--delay': `${delay}ms` } as CSSProperties}
      onAnimationEndCapture={e => {
        // Triggers when the animation ends on the root element as the animation is the longest
        if (ref.current?.isEqualNode(e.target as HTMLElement)) {
          setAnimating(false);
          onAnimationEnd?.();
        }
      }}
    >
      {content}
      {animating && <GlowFilter>{content}</GlowFilter>}
    </Root>
  );
};
