import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Modal } from 'atoms/Modal';
import { Vertical } from 'atoms/layout/flex';
import useScreenSize from 'hooks/device/useScreenSize';
import useBodyLock from 'hooks/useBodyLock';
import { useShortcut } from 'hooks/useShortcut';
import { setCurrentBackgroundColor } from 'lib/currentBackgroundColor';

import { CloseButton } from './CloseButton';
import { Header } from './Header';

const Root = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  overflow: auto;
  background: var(--c-black);
  &:not(.fullScreen) {
    padding: var(--quadruple-unit);
    background: var(--c-nl-900);
  }
`;
const Content = styled(Vertical).attrs({ gap: 0 })`
  border-radius: var(--unit);
  overflow-x: hidden;
  ${setCurrentBackgroundColor('var(--c-dialog-background)')}
  margin: auto;
  &.fullScreen {
    width: 100vw;
    height: 100%;
    border-radius: 0;
    max-width: none;
    ${setCurrentBackgroundColor('var(--c-black)')}
  }
  &.transparent {
    ${setCurrentBackgroundColor('transparent')}
  }
`;
const BodyWrapper = styled.div`
  position: relative;
  min-height: 0;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;
const Footer = styled.footer`
  margin-top: auto;
`;

export type Props = {
  className?: string;
  children?:
    | FC<React.PropsWithChildren<{ CloseButton: typeof CloseButton }>>
    | ReactNode;
  title?: ReactNode;
  hideHeader?: boolean;
  footer?: ReactNode;
  open?: boolean;
  onBack?: () => void;
  onClose?: () => void;
  fullWidth?: boolean;
  transparent?: boolean;
  fullScreen?: boolean;
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  style?: React.CSSProperties;
};

const maxWidths = {
  xs: 444,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export const Dialog = ({
  className,
  children,
  title,
  hideHeader,
  footer,
  open,
  onBack,
  onClose,
  fullWidth,
  transparent,
  fullScreen,
  maxWidth = 'xl',
  disableEscapeKeyDown,
  disableBackdropClick,
  style,
}: Props) => {
  useBodyLock(open);
  useShortcut(
    'Escape',
    () => {
      if (open && !disableEscapeKeyDown) {
        onClose?.();
      }
    },
    [open]
  );
  const { up: isLaptop } = useScreenSize('laptop');

  const showHeader = !hideHeader && (onBack || title || onClose);

  if (!open) return null;
  const isFullScreen = fullScreen ?? !isLaptop;
  const dialogElement = (
    <Root
      className={classNames({
        fullScreen: isFullScreen,
      })}
      onClick={() => {
        if (!disableBackdropClick) {
          onClose?.();
        }
      }}
    >
      <Content
        role="dialog"
        className={classNames(className, {
          fullScreen: isFullScreen,
          transparent,
        })}
        style={{
          width: fullWidth ? '100%' : undefined,
          maxWidth: maxWidth && !isFullScreen ? maxWidths[maxWidth] : undefined,
          ...style,
        }}
        onMouseDown={e => e.stopPropagation()}
        onMouseUp={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
      >
        {showHeader && (
          <Header onBack={onBack} title={title} onClose={onClose} />
        )}
        <BodyWrapper>
          {typeof children === 'function'
            ? children?.({ CloseButton })
            : children}
        </BodyWrapper>
        <Footer>{footer}</Footer>
      </Content>
    </Root>
  );

  return <Modal>{dialogElement}</Modal>;
};

export default Dialog;
