import classNames from 'classnames';
import { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { Color } from 'style/types';

const Root = styled(Horizontal)`
  width: 100%;
`;
const SegmentsWrapper = styled(Horizontal)`
  gap: 1px;
  flex: 1;

  & > * {
    flex: 1;
  }
`;
const Segment = styled.div`
  height: var(--unit);
  border-radius: 2px;
  background-color: var(--c-nd-150);
  position: relative;
  overflow: hidden;
  isolation: isolate;

  --background: var(--color);
  --glow-color: var(--color);
  .iridescent & {
    --background: linear-gradient(
      84deg,
      #f8d3da 0%,
      #b3a9f4 28.32%,
      #fbe9fb 54.01%,
      #4f94fd 100%
    );
    --glow-color: #4f94fd;
  }

  &.full {
    background: var(--background);
    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.65) inset;
  }
  .glow &.full {
    filter: drop-shadow(0px 1px 7px var(--glow-color));
  }

  &.partial {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: calc(var(--progress) * 100%);
      background: var(--background);
      opacity: 0.5;
    }
  }
`;

type Props = {
  thresholds: { score: number }[];
  currentScore: number;
  color: Color | 'iridescent';
  label?: ReactNode;
  glow?: boolean;
};

export const SplitProgressBar = ({
  thresholds,
  currentScore,
  color,
  label,
  glow,
}: Props) => {
  if (!thresholds.length) {
    return null;
  }

  return (
    <Root
      style={{ '--color': color } as CSSProperties}
      className={classNames({ glow, iridescent: color === 'iridescent' })}
    >
      <SegmentsWrapper>
        {thresholds.map((threshold, index) => {
          const fullBar = currentScore >= threshold.score;
          const previousBonusThreshold = thresholds.at(index - 1);
          const previousBonusThresholdValue =
            previousBonusThreshold?.score || 0;
          const partialBar =
            !fullBar && currentScore >= previousBonusThresholdValue;
          const partialProgress = partialBar
            ? (currentScore - previousBonusThresholdValue) /
              (threshold.score - previousBonusThresholdValue)
            : undefined;

          return (
            <Segment
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={classNames({
                full: fullBar,
                partial: partialBar,
              })}
              style={{ '--progress': partialProgress } as CSSProperties}
            />
          );
        })}
      </SegmentsWrapper>
      {label}
    </Root>
  );
};
