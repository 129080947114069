import { Suspense, useCallback, useEffect, useState } from 'react';
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
} from 'react-intl';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import verifyPhoneNumber from 'assets/user/verify_phone_number.svg';
import { Info } from 'atoms/icons/Info';
import { Vertical } from 'atoms/layout/flex';
import { LoadingIndicator } from 'atoms/loader/LoadingIndicator';
import { Tooltip } from 'atoms/tooltip/Tooltip';
import { Caption, Text16, Title4 } from 'atoms/typography';
import { type GraphQLResult, GraphqlForm } from 'components/form/Form';
import IntlTelInput from 'components/form/Form/IntlTelInput';
import { errorMessages } from 'components/user/VerifyPhoneNumber/i18n';
import { PRIVACY_POLICY } from 'constants/__generated__/routes';
import { useConfigContext } from 'contexts/config';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useEvents } from 'lib/events/useEvents';

const messages = defineMessages({
  header: {
    id: 'Settings.PhoneNumberVerification.SendVerificationCode.header',
    defaultMessage: 'Verify your phone number',
  },
  description: {
    id: 'Settings.PhoneNumberVerification.SendVerificationCode.description',
    defaultMessage:
      'A 4-digit code will be sent via sms to verify your mobile number',
  },
  policyCompliance: {
    id: 'Settings.PhoneNumberVerification.SendVerificationCode.policy',
    defaultMessage:
      "By submitting your number you expressly agree to Sorare's <link>SMS policy</link>.",
  },
  optoutInstructions: {
    id: 'Settings.PhoneNumberVerification.SendVerificationCode.optout',
    defaultMessage:
      'Message frequency varies.{br}Message and data rates apply.{br}Send HELP for help and reply STOP to cancel.',
  },
  cta: {
    id: 'Settings.PhoneNumberVerification.SendVerificationCode.cta',
    defaultMessage: 'Send verification code',
  },
});

type Error = NonNullable<GraphQLResult['errors']>[number];

export interface SendVerificationCodeProps {
  sendVerificationCode: (phoneNumber: string) => Promise<Error[]>;
  title?: MessageDescriptor;
  onSuccess?: (phoneNumber: string) => void;
}

const RootDiv = styled(Vertical).attrs({ gap: 0, center: true })`
  width: 100%;
  text-align: center;
  padding: var(--triple-unit);
  justify-content: flex-start;
`;

const InfoDiv = styled.div`
  padding: var(--double-unit);
`;

const Form = styled(GraphqlForm)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--unit);
  margin: 0;
`;

const SmsPolicyLink = styled(Link).attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  white-space: nowrap;
`;

const SmsLegalParagraph = styled(Caption)`
  text-align: start;
  padding: 0 var(--unit);
`;

export const SendVerificationCode = ({
  sendVerificationCode,
  title,
  onSuccess,
}: SendVerificationCodeProps) => {
  const { currentLocation } = useConfigContext();
  const { currentUser } = useCurrentUserContext();
  const [phoneNumber, setFullPhoneNumber] = useState<string | null | undefined>(
    currentUser?.unverifiedPhoneNumber
  );
  const [validPhoneNumber, setValidPhoneNumber] = useState<boolean | null>(
    !!currentUser?.unverifiedPhoneNumber
  );

  const track = useEvents();

  useEffect(() => {
    track('Show Enter Phone Number', {});
  }, [track]);

  const sendVerificationCodeCb = useCallback(
    (
      _vars: any,
      onResult: (result: GraphQLResult) => void,
      onCancel: () => void | undefined
    ) => {
      if (!phoneNumber) {
        onCancel();
        return;
      }
      sendVerificationCode(phoneNumber).then(errors => {
        onResult({ errors });
      });
    },
    [sendVerificationCode, phoneNumber]
  );
  const onChange = useCallback(({ isValid, fullNumber }: any) => {
    setValidPhoneNumber(isValid);
    setFullPhoneNumber(fullNumber);
  }, []);

  const doOnSuccess = () => {
    if (onSuccess && phoneNumber) onSuccess(phoneNumber);
  };

  if (!currentUser) return null;

  return (
    <RootDiv>
      <img src={verifyPhoneNumber} alt="Verify Your Phone Number" />
      <InfoDiv>
        <Title4>
          <FormattedMessage {...(title || messages.header)} />
        </Title4>
        <Text16 color="var(--c-nd-600)">
          <FormattedMessage {...messages.description} />
        </Text16>
      </InfoDiv>
      <Suspense fallback={<LoadingIndicator />}>
        <Form
          onSubmit={sendVerificationCodeCb}
          onSuccess={doOnSuccess}
          errorMessages={errorMessages}
          render={(Error, SubmitButton) => (
            <>
              <Error />
              <IntlTelInput
                name="phoneNumber"
                defaultCountry={currentLocation.countryCode?.toLowerCase()}
                defaultPhone={
                  currentUser?.unverifiedPhoneNumber || currentUser?.phoneNumber
                }
                onChange={onChange}
              />
              <SmsLegalParagraph>
                <FormattedMessage
                  {...messages.policyCompliance}
                  values={{
                    link: children => (
                      <SmsPolicyLink
                        to={`${PRIVACY_POLICY}#sms-terms-and-conditions`}
                      >
                        {children}
                      </SmsPolicyLink>
                    ),
                  }}
                />{' '}
                <Tooltip
                  placement="left"
                  title={
                    <FormattedMessage
                      {...messages.optoutInstructions}
                      values={{ br: <br /> }}
                    />
                  }
                >
                  <button tabIndex={0} type="button">
                    <Info />
                  </button>
                </Tooltip>
              </SmsLegalParagraph>
              <SubmitButton size="medium" disabled={!validPhoneNumber}>
                <FormattedMessage {...messages.cta} />
              </SubmitButton>
            </>
          )}
        />
      </Suspense>
    </RootDiv>
  );
};
