import { TypedDocumentNode, gql } from '@apollo/client';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { animated, config, useSpring } from 'react-spring';
import styled from 'styled-components';

import useFontFaceObserver from '@sorare/use-font-face-observer';
import { So5LeaderboardType, Sport } from '__generated__/globalTypes';
import { RotatingStarball } from 'atoms/animations/RotatingStarball';
import { Button } from 'atoms/buttons/Button';
import { Vertical } from 'atoms/layout/flex';
import { TextWithLine } from 'atoms/ui/TextWithLine';
import { ContentContainer } from 'components/landing/LandingMultiSport/ui';
import { useConnectionContext } from 'contexts/connection';
import useUsersCount from 'hooks/config/useUsersCount';
import useScreenSize from 'hooks/device/useScreenSize';
import { useIsVisibleInViewport } from 'hooks/ui/useIsVisibleInViewport';
import { useEvents } from 'lib/events/useEvents';
import { getTextSize } from 'lib/text';
import { laptopAndAbove, tabletAndAbove } from 'style/mediaQuery';
import { hideScrollbar } from 'style/utils';

import { AnySportPastWinner } from './AnySportPastWinner';
import {
  AnySportPastLineupWinnerQuery,
  AnySportPastLineupWinnerQueryVariables,
} from './__generated__/index.graphql';

const messages = defineMessages({
  title: {
    id: 'Landing.CommunityBlock.title',
    defaultMessage: 'Join the community',
  },
  users: {
    id: 'Landing.CommunityBlock.users',
    defaultMessage: 'Sorare Managers',
  },
  playing: {
    id: 'Landing.CommunityBlock.playing',
    defaultMessage: 'Playing',
  },
  winners: {
    id: 'Landing.CommunityBlock.winners',
    defaultMessage: 'Recent competition winners',
  },
  cta: {
    id: 'Landing.CommunityBlock.cta',
    defaultMessage: 'See all upcoming competitions',
  },
});

const Root = styled(ContentContainer)`
  margin-top: calc(var(--unit) * 8);
  @media ${tabletAndAbove} {
    margin-top: calc(var(--unit) * 15);
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  gap: var(--intermediate-unit);
  align-items: center;
  flex-direction: row-reverse;
  margin-bottom: var(--quadruple-unit);
  @media ${tabletAndAbove} {
    flex-direction: row;
  }
`;

const SmallStarball = styled(RotatingStarball)`
  width: 45px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-family: 'Druk Wide';
  text-transform: uppercase;

  @media ${laptopAndAbove} {
    font-size: 28px;
  }
`;

const Druk = styled.div`
  line-height: 1;
  font-weight: var(--t-bold);
  font-family: Druk Wide;
  color: var(--c-white);
  &:not(.active) {
    font-family: verdana;
  }
`;

const WinnersList = styled.div`
  display: grid;
  margin-top: var(--double-and-a-half-unit);
  gap: var(--double-and-a-half-unit);

  @media ${tabletAndAbove} {
    ${hideScrollbar}
    overflow: auto;
    grid-auto-flow: column;
    gap: var(--intermediate-unit);
    margin-top: calc(var(--unit) * 7);
    grid-template-columns: repeat(3, 1fr);
  }
`;

const CTAWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: var(--double-and-a-half-unit) 0;

  @media ${tabletAndAbove} {
    margin: calc(var(--unit) * 7) 0;
  }
  @media ${laptopAndAbove} {
    margin: var(--double-and-a-half-unit) 0;
  }
`;

type Props = {
  title?: ReactNode;
  cta?: ReactNode;
  leaderboards: { sport: Sport; leaderboardType: So5LeaderboardType }[];
  showSubtitle?: boolean;
};

const ANY_SPORT_PAST_LINEUP_WINNER_QUERY = gql`
  query AnySportPastLineupWinnerQuery(
    $has1: Boolean!
    $leaderboardType1: So5LeaderboardType!
    $sport1: Sport!
    $has2: Boolean!
    $leaderboardType2: So5LeaderboardType!
    $sport2: Sport!
    $has3: Boolean!
    $leaderboardType3: So5LeaderboardType!
    $sport3: Sport!
  ) {
    so5 {
      f1: so5Fixture(type: PAST, sport: $sport1) @include(if: $has1) {
        slug
        so5Leaderboard(so5LeaderboardType: $leaderboardType1) {
          slug
          ...AnySportPastWinner_so5Leaderboard
        }
      }
      f2: so5Fixture(type: PAST, sport: $sport2) @include(if: $has2) {
        slug
        so5Leaderboard(so5LeaderboardType: $leaderboardType2) {
          slug
          ...AnySportPastWinner_so5Leaderboard
        }
      }
      f3: so5Fixture(type: PAST, sport: $sport3) @include(if: $has3) {
        slug
        so5Leaderboard(so5LeaderboardType: $leaderboardType3) {
          slug
          ...AnySportPastWinner_so5Leaderboard
        }
      }
    }
  }
  ${AnySportPastWinner.fragments.so5Leaderboard}
` as TypedDocumentNode<
  AnySportPastLineupWinnerQuery,
  AnySportPastLineupWinnerQueryVariables
>;

export const CommunityBlock = ({
  title,
  cta,
  leaderboards,
  showSubtitle = true,
}: Props) => {
  const track = useEvents();
  const { counts } = useUsersCount();
  const { signUp } = useConnectionContext();
  const { up: isDesktop } = useScreenSize('laptop');
  const { formatMessage, formatNumber } = useIntl();
  const fontStatus = useFontFaceObserver(
    [{ family: 'Druk Wide', weight: 'normal' }],
    {
      timeout: 1000,
    }
  );
  const numberFormat = isDesktop
    ? undefined
    : {
        notation: 'compact' as const,
        compactDisplay: 'short' as const,
        maximumFractionDigits: 1,
      };
  const { width: viewPortSize } = getTextSize(
    `${formatNumber(counts.usersCount, numberFormat)}`,
    `900 16px ${fontStatus !== 'active' ? 'verdana' : 'Druk Wide'}`
  );
  const { data } = useQuery(ANY_SPORT_PAST_LINEUP_WINNER_QUERY, {
    variables: {
      has1: leaderboards[0] !== undefined,
      leaderboardType1:
        leaderboards[0]?.leaderboardType ||
        So5LeaderboardType.GLOBAL_ALL_STAR_LIMITED,
      sport1: leaderboards[0]?.sport || Sport.FOOTBALL,
      has2: leaderboards[1] !== undefined,
      leaderboardType2:
        leaderboards[1]?.leaderboardType ||
        So5LeaderboardType.GLOBAL_ALL_STAR_LIMITED,
      sport2: leaderboards[1]?.sport || Sport.FOOTBALL,
      has3: leaderboards[2] !== undefined,
      leaderboardType3:
        leaderboards[2]?.leaderboardType ||
        So5LeaderboardType.GLOBAL_ALL_STAR_LIMITED,
      sport3: leaderboards[2]?.sport || Sport.FOOTBALL,
    },
  });

  const { handleRef, isVisible } = useIsVisibleInViewport<HTMLDivElement>();

  const { number } = useSpring({
    number: counts.usersCount,
    from: { number: 0 },
    pause: isVisible === false,
    config: isDesktop ? config.molasses : config.gentle,
  });

  return (
    <Root>
      <Vertical>
        <TitleWrapper>
          <SmallStarball />
          <Title>{title || formatMessage(messages.title)}</Title>
        </TitleWrapper>
        {showSubtitle && (
          <TextWithLine>
            {formatMessage(messages.users)}
            {formatMessage(messages.playing)}
          </TextWithLine>
        )}
        <div>
          {counts.usersCount && (
            <Druk ref={handleRef} className={fontStatus}>
              <svg viewBox={`0 0 ${viewPortSize} 16`}>
                <animated.text x="0" y="14" fill="currentColor">
                  {number.to(n => formatNumber(Math.floor(n), numberFormat))}
                </animated.text>
              </svg>
            </Druk>
          )}
        </div>
        {(data?.so5.f1?.so5Leaderboard ||
          data?.so5.f2?.so5Leaderboard ||
          data?.so5.f3?.so5Leaderboard) && (
          <>
            <TextWithLine>{formatMessage(messages.winners)}</TextWithLine>
            <WinnersList>
              {data?.so5.f1?.so5Leaderboard && (
                <AnySportPastWinner
                  so5Leaderboard={data.so5.f1.so5Leaderboard}
                />
              )}
              {data?.so5.f2?.so5Leaderboard && (
                <AnySportPastWinner
                  so5Leaderboard={data.so5.f2.so5Leaderboard}
                />
              )}
              {data?.so5.f3?.so5Leaderboard && (
                <AnySportPastWinner
                  so5Leaderboard={data.so5.f3.so5Leaderboard}
                />
              )}
            </WinnersList>
          </>
        )}
        <CTAWrapper>
          <Button
            size="medium"
            color="tertiary"
            onClick={() => {
              track('Click See all competitions');
              signUp();
            }}
          >
            {cta || formatMessage(messages.cta)}
          </Button>
        </CTAWrapper>
      </Vertical>
    </Root>
  );
};
