import { ReactNode, useMemo } from 'react';
import { animated, easings, useTransition } from 'react-spring';
import styled from 'styled-components';

import { Modal } from 'atoms/Modal';
import { Backdrop } from 'atoms/loader/Backdrop';
import useBodyLock from 'hooks/useBodyLock';

import { DrawerSide } from './types';

const Isolation = styled.div`
  isolation: isolate;
`;

const Wrapper = styled(animated.aside)`
  position: fixed;
  isolation: isolate;
  inset: 0;
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export type Props = {
  children: ReactNode;
  open: boolean;
  className?: string;
  side?: DrawerSide;
  fullScreen?: boolean;
  onBackdropClick?: () => void;
  blur?: boolean;
  keepMounted?: boolean;
  animated?: boolean;
  transitionDuration?: number;
};

export const Drawer = ({
  children,
  className,
  side = 'right',
  fullScreen,
  open,
  onBackdropClick,
  blur = false,
  animated: animatedProp = true,
  keepMounted,
  transitionDuration = 250,
}: Props) => {
  const hiddenStyle = useMemo(() => {
    switch (side) {
      case 'right':
        return {
          x: '100%',
          y: '0%',
        };
      case 'left':
        return {
          x: '-100%',
          y: '0%',
        };
      case 'bottom':
        return {
          x: '0%',
          y: '100%',
        };
      case 'top':
      default:
        return {
          x: '0%',
          y: '-100%',
        };
    }
  }, [side]);
  const positionning = useMemo(() => {
    if (fullScreen) return null;
    switch (side) {
      case 'right':
        return {
          left: 'unset',
        };
      case 'left':
        return {
          right: 'unset',
        };
      case 'bottom':
        return {
          top: 'unset',
        };
      case 'top':
      default:
        return {
          bottom: 'unset',
        };
    }
  }, [fullScreen, side]);

  useBodyLock(!!(open && onBackdropClick));

  const transitions = useTransition(open, {
    from: { ...positionning, ...hiddenStyle },
    enter: { ...positionning, x: '0%', y: '0%' },
    leave: { ...positionning, ...hiddenStyle },
    reverse: open,
    config: {
      duration: animatedProp ? transitionDuration : 0,
      easing: open ? easings.easeOutSine : easings.easeInSine,
    },
    expires: !keepMounted,
  });

  if (!open && !keepMounted) {
    return null;
  }

  return (
    <Modal>
      {(onBackdropClick || blur) && open && (
        <Isolation>
          <Backdrop blur={blur} onClick={onBackdropClick} />
        </Isolation>
      )}
      <div>
        {transitions(
          (styles, isOpen) =>
            (isOpen || keepMounted) && (
              <Wrapper
                style={{
                  ...styles,
                  ...(keepMounted ? { display: isOpen ? 'flex' : 'none' } : {}),
                }}
                className={className}
              >
                {children}
              </Wrapper>
            )
        )}
      </div>
    </Modal>
  );
};
