import { useConfigContext } from 'contexts/config';

const DEFAULT_MARKET_COUNTS = {
  starterPacksCount: 0,
  managerSalesCount: 0,
  auctionsCount: 0,
};

export default () => {
  const { counts } = useConfigContext();

  return {
    counts: counts || {
      usersCount: 0,
      football: DEFAULT_MARKET_COUNTS,
      nba: DEFAULT_MARKET_COUNTS,
      baseball: DEFAULT_MARKET_COUNTS,
    },
  };
};
