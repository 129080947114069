import { TypedDocumentNode, gql } from '@apollo/client';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { withFragments } from '@sorare/core/src/gql';

import {
  getLineupDisplayName_managerTeam,
  getLineupDisplayName_so5Leaderboard,
  getLineupDisplayName_so5Lineup,
} from './__generated__/getLineupDisplayName.graphql';

const getLineupDisplayName = withFragments(
  ({
    so5Lineup,
    managerTeam,
    so5Leaderboard,
    sport,
    addLeaguePrefix,
  }: {
    managerTeam?: Nullable<getLineupDisplayName_managerTeam>;
    so5Lineup: Nullable<getLineupDisplayName_so5Lineup>;
    so5Leaderboard: Nullable<getLineupDisplayName_so5Leaderboard>;
    sport?: Sport;
    addLeaguePrefix?: boolean;
  }) => {
    if (sport === Sport.NBA || sport === Sport.BASEBALL) {
      const teamNumber = (managerTeam?.name || '').match(/#(\d+)/);
      if (so5Leaderboard?.displayName && teamNumber) {
        return `${so5Leaderboard.displayName} #${teamNumber[1]}`;
      }
      return so5Leaderboard?.displayName || '';
    }
    const lineupName =
      sport === Sport.FOOTBALL
        ? so5Lineup?.name
        : `${
            so5Lineup?.name && (so5Leaderboard?.teamsCap || 0) > 1
              ? ` ${so5Lineup.name} - `
              : ''
          }${so5Leaderboard?.displayName || ''}`;

    return managerTeam?.name
      ? `${addLeaguePrefix ? `${so5Leaderboard?.so5LeaderboardGroup?.displayName} - ` : ''}${managerTeam.name}`
      : lineupName;
  },
  {
    so5Leaderboard: gql`
      fragment getLineupDisplayName_so5Leaderboard on So5Leaderboard {
        slug
        displayName
        teamsCap
        so5LeaderboardGroup {
          slug
          displayName
        }
      }
    ` as TypedDocumentNode<getLineupDisplayName_so5Leaderboard>,
    so5Lineup: gql`
      fragment getLineupDisplayName_so5Lineup on So5Lineup {
        id
        name
      }
    ` as TypedDocumentNode<getLineupDisplayName_so5Lineup>,
    managerTeam: gql`
      fragment getLineupDisplayName_managerTeam on ManagerTeam {
        id
        name
      }
    ` as TypedDocumentNode<getLineupDisplayName_managerTeam>,
  }
);

export default getLineupDisplayName;
