import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { SorareLogo } from 'atoms/icons/SorareLogo';
import { Horizontal } from 'atoms/layout/flex';
import Dialog from 'components/dialog';
import { useWalletContext } from 'contexts/wallet';
import { useResetPassword } from 'contexts/wallet/Frame/handlers';

import { ResetPasswordContent } from './ResetPasswordContent';

const Root = styled.div`
  padding: var(--triple-unit);
`;

type Props = {
  open: boolean;
  onClose: () => void;
};
export const ResetPasswordDialog = ({ open, onClose: doOnclose }: Props) => {
  const { promptResetPassword } = useWalletContext();
  const [, setSearchParams] = useSearchParams();
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  const onSuccess = useCallback(() => setHasSubmitted(true), []);

  useResetPassword({ onSuccess });

  useEffect(() => {
    if (open) promptResetPassword();
  }, [open, promptResetPassword]);

  const onClose = () => {
    setSearchParams({});
    doOnclose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      title={
        <Horizontal center className="text-center">
          <SorareLogo variant="currentColor" />
        </Horizontal>
      }
    >
      <Root>
        <ResetPasswordContent hasSubmitted={hasSubmitted} />
      </Root>
    </Dialog>
  );
};

export default ResetPasswordDialog;
