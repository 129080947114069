import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { StarBall } from '@sorare/core/src/atoms/icons/StarBall';
import { Container } from '@sorare/core/src/atoms/layout/Container';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { HeadlineL, LabelM } from '@sorare/core/src/atoms/typography';
import { FOOTBALL_PLAY_PRO_ONBOARDING_CHOOSE_YOUR_LEVEL } from '@sorare/core/src/constants/__generated__/routes';
import { glossary } from '@sorare/core/src/lib/glossary';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import players from './assets/players.png';

const StyledContainer = styled(Container)`
  background: linear-gradient(to bottom, #2c2b70 0%, rgba(44, 43, 112, 0) 100%);
`;

const Wrapper = styled(Vertical)`
  height: var(--100vh);
  align-items: center;
  padding: var(--double-unit) 0;
  @media ${tabletAndAbove} {
    padding: var(--quadruple-unit) 0;
    gap: calc(6 * (var(--unit)));
  }
`;
const ImgContent = styled.div`
  flex: 1;
  overflow: auto;
  background-image: url(${players});
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: center;
  width: 100vw;
  @media ${tabletAndAbove} {
    background-size: contain;
  }
`;

const WelcomePage = () => {
  return (
    <StyledContainer>
      <Wrapper>
        <Vertical center>
          <StarBall />
          <HeadlineL>
            <FormattedMessage
              id="WelcomePage.title"
              defaultMessage="Welcome to Sorare"
            />
          </HeadlineL>
          <LabelM color="var(--c-nd-600)">
            <FormattedMessage
              id="WelcomePage.subtitle"
              defaultMessage="Are you ready to build your club?"
            />
          </LabelM>
        </Vertical>
        <ImgContent />
        <Button
          color="secondary"
          size="large"
          to={FOOTBALL_PLAY_PRO_ONBOARDING_CHOOSE_YOUR_LEVEL}
        >
          <FormattedMessage {...glossary.letsGo} />
        </Button>
      </Wrapper>
    </StyledContainer>
  );
};
export default WelcomePage;
