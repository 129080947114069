import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { To } from 'react-router-dom';
import styled from 'styled-components';

import { Rarity } from '@sorare/core/src/__generated__/globalTypes';
import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { NotIOsAppFeature } from '@sorare/core/src/components/BuyableFeature';
import { CongratsScreen } from '@sorare/core/src/components/congratulations/CongratsScreen';
import Dialog from '@sorare/core/src/components/dialog';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import { glossary } from '@sorare/core/src/lib/glossary';

const Body = styled.div`
  position: relative;
  isolation: isolate;
  min-height: 100%;
`;

const Content = styled(Vertical)`
  padding: var(--quadruple-unit) 0;
`;

type Props = {
  main: ReactNode;
  fallbackIosRoute: To;
  playerDisplayName: string;
  open: boolean;
  onClose: () => void;
};

export const BuyCongratsScreen = ({
  main,
  fallbackIosRoute,
  playerDisplayName,
  open,
  onClose,
}: Props) => {
  const { up: upTablet } = useScreenSize('tablet');
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      hideHeader
      fullScreen={!upTablet}
    >
      <Body>
        <Content>
          <CongratsScreen
            rarityBackground={Rarity.common}
            title={
              <FormattedMessage
                id="buyTokenConfirmation.buy.title"
                defaultMessage="New{br}signing"
                values={{ br: <br /> }}
              />
            }
            subtitle={
              <FormattedMessage
                id="buyTokenConfirmation.buy.subtitle"
                defaultMessage="Welcome {playerDisplayName} to your team!"
                values={{
                  playerDisplayName,
                }}
              />
            }
            main={main}
            actions={
              <>
                <Button size="medium" color="secondary" onClick={onClose}>
                  <FormattedMessage {...glossary.continue} />
                </Button>
                <NotIOsAppFeature>
                  <Button
                    size="medium"
                    color="transparent"
                    to={fallbackIosRoute}
                    onClick={onClose}
                  >
                    <FormattedMessage {...glossary.play} />
                  </Button>
                </NotIOsAppFeature>
              </>
            }
            isBundle={false}
            onClose={onClose}
          />
        </Content>
      </Body>
    </Dialog>
  );
};
