import { TypedDocumentNode, gql } from '@apollo/client';
import { differenceInDays } from 'date-fns';
import styled from 'styled-components';

import { Rarity } from '@sorare/core/src/__generated__/globalTypes';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Caption } from '@sorare/core/src/atoms/typography';
import { Gauge } from '@sorare/core/src/atoms/ui/Gauge';
import {
  DetailedScoreKey,
  blockchainDetailedScores,
  commonDetailedScores,
} from '@sorare/core/src/components/collections/DetailedScoreLine';

import { DetailedScoreLine } from '../DetailsDialog/DetailedScoreLine';
import { CollectionBonuses_cardCollectionCard } from './__generated__/index.graphql';

const Root = styled(Vertical).attrs({ gap: 2 })`
  align-self: stretch;
`;
const DaysLeft = styled(Caption)`
  text-align: right;
  margin-left: auto;
  margin-bottom: var(--half-unit);
`;
const StyledVertical = styled(Vertical).attrs({ gap: 0.5 })`
  padding-top: var(--half-unit);
`;

type Props = {
  cardCollectionCard: CollectionBonuses_cardCollectionCard;
  displayWarning: boolean;
};

export const CollectionBonuses = ({
  cardCollectionCard,
  displayWarning,
}: Props) => {
  const {
    holdingThresholdDays,
    scoreBreakdown,
    heldSince,
    anyCard: { rarityTyped },
  } = cardCollectionCard;
  const { total, __typename, ...scores } = scoreBreakdown;
  const remainingDays = differenceInDays(new Date(), heldSince);
  const detailedScores =
    rarityTyped === Rarity.common
      ? commonDetailedScores
      : blockchainDetailedScores;

  return (
    <Root>
      {Object.entries(scores).map(([key, value]) => {
        const showProgression =
          !value && key === 'holding' && remainingDays >= 0;
        if (!value && !showProgression) {
          return null;
        }
        const detailedScore = detailedScores?.[key as DetailedScoreKey];
        if (!detailedScore) return null;
        return (
          <DetailedScoreLine
            key={key}
            listed={displayWarning}
            {...detailedScore}
            value={value}
            explanation={
              <>
                {detailedScore.explanation}
                {showProgression && remainingDays >= 0 && (
                  <StyledVertical>
                    <Gauge
                      percentage={`${(remainingDays * 100) / holdingThresholdDays}%`}
                    />
                    <DaysLeft>
                      {Math.ceil(remainingDays)}/{holdingThresholdDays}
                    </DaysLeft>
                  </StyledVertical>
                )}
              </>
            }
          />
        );
      })}
    </Root>
  );
};

CollectionBonuses.fragments = {
  cardCollectionCard: gql`
    fragment CollectionBonuses_cardCollectionCard on CardCollectionCard {
      id
      holdingThresholdDays
      heldSince
      anyCard {
        slug
        rarityTyped
      }
      scoreBreakdown {
        firstOwner
        firstSerialNumber
        holding
        owner
        shirtMatchingSerialNumber
        specialEdition
        firstTimeOwnedPlayerCard
        total
      }
    }
  ` as TypedDocumentNode<CollectionBonuses_cardCollectionCard>,
};
