import {
  ComponentType,
  FunctionComponent,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled, { css } from 'styled-components';

import { SorareLogo } from 'atoms/icons/SorareLogo';
import { Horizontal } from 'atoms/layout/flex';
import { Text14, Title4, Title5 } from 'atoms/typography';
import {
  GraphQLResult,
  GraphqlForm,
  SubmitButtonProps,
} from 'components/form/Form';
import { useIntlContext } from 'contexts/intl';
import { messages as walletMessages } from 'contexts/wallet/messages';
import useToggle from 'hooks/useToggle';
import { glossary } from 'lib/glossary';

import AcceptAgeLimit from '../AcceptAgeLimit';
import AcceptTermsAndPrivacyPolicy from '../AcceptTermsAndPrivacyPolicy';
import AgreeToReceiveOffersFromPartners from '../AgreeToReceiveOffersFromPartners';
import { AcceptTermsArgs } from '../useAcceptTerms';

const messages = defineMessages({
  required: {
    id: 'oneMoreThing.required',
    defaultMessage: 'Required',
  },
  optional: {
    id: 'oneMoreThing.optional',
    defaultMessage: 'Optional',
  },
  justOneMoreStep: {
    id: 'oneMoreThing.justOneMoreStep',
    defaultMessage: 'Just One More Step',
  },
});

const TitleSection = styled(Title5)`
  text-align: center;
  padding: 0 24px;
  width: 100%;
`;

export const Title = () => {
  const { formatMessage } = useIntlContext();
  return <TitleSection>{formatMessage(glossary.signup)}</TitleSection>;
};

const Form = styled(GraphqlForm)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  margin-bottom: 0;
`;

const Section = styled.div`
  margin: 8px 0;
`;

const SorareHeader = styled(Horizontal).attrs({ gap: 0, center: true })`
  gap: 6px;
`;

const sectionInError = css`
  border: 2px solid var(--c-red-600);
`;

const AcceptTermsAndPrivacyPolicySection = styled.div<{ error?: boolean }>`
  ${props => (props.error ? sectionInError : '')}
`;

const StyledSorareLogo = styled(SorareLogo).attrs({
  variant: 'white',
})`
  height: 16px;
`;
const JustOneMoreStepDiv = styled(Title4)`
  text-align: center;
  margin: 24px;
`;

const ErrorMessage = styled.div`
  color: var(--c-red-600);
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  height: 56px;
  padding: var(--unit);
`;

type EffectiveAcceptTermsArgs = Omit<AcceptTermsArgs, 'tcuToken'>;

interface Props {
  onFormSuccess: (
    acceptedTerms: EffectiveAcceptTermsArgs,
    args: GraphQLResult
  ) => void;
  acceptTerms: (args: EffectiveAcceptTermsArgs) => Promise<GraphQLResult>;
}

const hasAcceptedMandatoryTerms = (terms: EffectiveAcceptTermsArgs) =>
  terms.acceptTerms && terms.acceptAgeLimit && terms.acceptPrivacyPolicy;

const OneMoreStep = ({ onFormSuccess, acceptTerms }: Props) => {
  const { formatMessage } = useIntlContext();
  const [error, setError] = useState<ReactNode | null>(null);
  const [
    acceptTermsAndPrivacyPolicyLabel,
    toggleAcceptTermsAndPrivacyPolicyLabel,
  ] = useToggle(false);
  const [acceptAgeLimit, toggleAcceptAgeLimit] = useToggle(false);
  const [
    agreedToReceiveOffersFromPartners,
    toggleAgreedToReceiveOffersFromPartners,
  ] = useToggle(false);
  const [
    hasAttemptedSubmittingWithoutAccepting,
    setHasAttemptedSubmittingWithoutAccepting,
  ] = useState(false);

  const onError = useCallback(
    (result: GraphQLResult) => {
      if (result?.errors?.length) {
        setError(formatMessage(walletMessages.youMustAcceptTermsAndConditions));
      }
    },
    [setError, formatMessage]
  );

  const termsInput = useMemo<EffectiveAcceptTermsArgs>(
    () => ({
      acceptTerms: acceptTermsAndPrivacyPolicyLabel,
      acceptAgeLimit,
      acceptPrivacyPolicy: acceptTermsAndPrivacyPolicyLabel,
      agreedToReceiveOffersFromPartners,
      acceptGameRules: true, // technically, current terms imply game rules acceptance
    }),
    [
      acceptTermsAndPrivacyPolicyLabel,
      acceptAgeLimit,
      agreedToReceiveOffersFromPartners,
    ]
  );

  const doAcceptTerms = useCallback(
    (values: any, onResult: (result: GraphQLResult) => void) => {
      setError(null);
      if (hasAcceptedMandatoryTerms(termsInput)) {
        acceptTerms(termsInput).then(result => onResult(result));
      } else {
        const youMustAcceptTermsAndConditions = formatMessage(
          walletMessages.youMustAcceptTermsAndConditions
        );
        setHasAttemptedSubmittingWithoutAccepting(true);
        onResult({
          error: youMustAcceptTermsAndConditions,
          errors: [
            {
              message: youMustAcceptTermsAndConditions,
            },
          ],
        });
      }
    },
    [acceptTerms, termsInput, formatMessage]
  );

  const youMustAcceptTermsAndConditions =
    hasAttemptedSubmittingWithoutAccepting &&
    !hasAcceptedMandatoryTerms(termsInput);

  const onSuccess = useCallback(
    (result: GraphQLResult) => onFormSuccess(termsInput, result),
    [onFormSuccess, termsInput]
  );

  return (
    <Form
      onSubmit={doAcceptTerms}
      onSuccess={onSuccess}
      onError={onError}
      render={(
        Error: ComponentType<React.PropsWithChildren<unknown>>,
        SubmitButton: FunctionComponent<
          React.PropsWithChildren<SubmitButtonProps>
        >
      ) => (
        <>
          <SorareHeader>
            <StyledSorareLogo variant="currentColor" />
          </SorareHeader>
          <JustOneMoreStepDiv>
            <FormattedMessage {...messages.justOneMoreStep} />
          </JustOneMoreStepDiv>
          <Text14 bold>
            <FormattedMessage {...messages.required} />
          </Text14>
          <Section>
            <AcceptTermsAndPrivacyPolicySection
              error={youMustAcceptTermsAndConditions}
            >
              <AcceptTermsAndPrivacyPolicy
                name="acceptTermsAndPrivacyPolicyLabel"
                checked={acceptTermsAndPrivacyPolicyLabel}
                onChange={toggleAcceptTermsAndPrivacyPolicyLabel}
              />
              <AcceptAgeLimit
                name="acceptAgeLimit"
                checked={acceptAgeLimit}
                onChange={toggleAcceptAgeLimit}
              />
            </AcceptTermsAndPrivacyPolicySection>
            {youMustAcceptTermsAndConditions && error && (
              <ErrorMessage>{error}</ErrorMessage>
            )}
          </Section>
          <Text14 bold>
            <FormattedMessage {...messages.optional} />
          </Text14>
          <Section>
            <AgreeToReceiveOffersFromPartners
              name="agreedToReceiveOffersFromPartners"
              checked={agreedToReceiveOffersFromPartners}
              onChange={toggleAgreedToReceiveOffersFromPartners}
            />
          </Section>
          <Actions>
            <SubmitButton size="medium" fullWidth>
              <FormattedMessage {...glossary.signup} />
            </SubmitButton>
          </Actions>
        </>
      )}
    />
  );
};

export default OneMoreStep;
