import { useTypedParams } from '@sorare/routing';

import ConfirmLineupForm from '@sorare/gameplay/src/components/noCardRoute/ConfirmLineupForm';

import { PageParams } from './__generated__/routeParams';

const Confirm = () => {
  const { so5LineupId } = useTypedParams<PageParams>();

  return <ConfirmLineupForm so5LineupId={so5LineupId} />;
};

export default Confirm;
