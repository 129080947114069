import { FC, ReactNode } from 'react';

import useFeatureFlags from 'hooks/useFeatureFlags';

import { CloseButton } from './CloseButton';
import NewDialog from './Dialog';
import LegacyDialog from './LegacyDialog';

export type Props = {
  className?: string;
  contentStyle?: React.CSSProperties;
  children?:
    | FC<React.PropsWithChildren<{ CloseButton: typeof CloseButton }>>
    | ReactNode;
  title?: ReactNode;
  hideHeader?: boolean;
  footer?: ReactNode;
  open?: boolean;
  onBack?: () => void;
  onClose?: () => void;
  fullWidth?: boolean;
  transparent?: boolean;
  fullScreen?: boolean;
  disableBackdropClick?: boolean;
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
  disableEscapeKeyDown?: boolean;
  transitionDuration?: number;
  style?: React.CSSProperties;
};

export const Dialog = (props: Props) => {
  const {
    flags: { useNewDialog = false },
  } = useFeatureFlags();

  if (useNewDialog) {
    return <NewDialog {...props} />;
  }
  return <LegacyDialog {...props} />;
};

export default Dialog;
