import styled from 'styled-components';

import { AnimatedNumber } from 'atoms/animations/AnimatedNumber';
import { ButtonBase } from 'atoms/buttons/ButtonBase';
import { Horizontal } from 'atoms/layout/flex';
import { LabelM } from 'atoms/typography';
import { FOOTBALL_MARKET_COMMON_PACKS } from 'constants/__generated__/routes';

import { CommonEssenceIcon } from '../CommonEssenceIcon';

const IconWrapper = styled(Horizontal).attrs({ center: true })`
  width: var(--triple-unit);
  height: var(--triple-unit);
  border-radius: 50%;
  background: var(--c-nd-150);

  & > img {
    height: 16px;
    object-fit: contain;
  }
`;

const Root = styled(ButtonBase)`
  border-radius: var(--double-unit);
  padding-left: var(--unit);
  background: var(--c-nd-50);
  display: flex;
  &:hover {
    background: var(--c-nd-100);
  }
`;

type Props = { commonCardPoints: number; withoutLink?: boolean };
export const CommonCoinsBalance = ({
  commonCardPoints,
  withoutLink,
}: Props) => {
  return (
    <Root
      to={withoutLink ? undefined : FOOTBALL_MARKET_COMMON_PACKS}
      as={withoutLink ? 'div' : undefined}
    >
      <Horizontal gap={0.5}>
        <LabelM bold>
          <AnimatedNumber
            value={commonCardPoints}
            disableAnimationOnFirstRender
          />
        </LabelM>
        <IconWrapper>
          <CommonEssenceIcon />
        </IconWrapper>
      </Horizontal>
    </Root>
  );
};
