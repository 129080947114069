import { Children, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { SBHorizontal } from 'atoms/layout/flex';
import { BodyL } from 'atoms/typography';
import { tabletAndAbove } from 'style/mediaQuery';

const Line = styled.div`
  display: none;
  @media ${tabletAndAbove} {
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--c-white);
  }
`;

export const TextWithLine = ({ children }: PropsWithChildren) => {
  return (
    <SBHorizontal gap={2.5}>
      {Children.map(children, (child, i) => {
        return (
          <>
            <BodyL className="whitespace-nowrap">{child}</BodyL>
            {i === 0 && <Line />}
          </>
        );
      })}
    </SBHorizontal>
  );
};
