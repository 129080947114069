import { Sport } from '__generated__/globalTypes';
import { useSportContext } from 'contexts/sport';
import useFeatureFlags from 'hooks/useFeatureFlags';

export const useIsNewXpEnabled = () => {
  const { sport } = useSportContext();
  const {
    flags: {
      useNewXpModeFrontend = {
        [Sport.FOOTBALL]: false,
        [Sport.NBA]: false,
        [Sport.BASEBALL]: false,
      },
    },
  } = useFeatureFlags();

  return !!useNewXpModeFrontend[sport];
};
