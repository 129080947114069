import { useCallback, useEffect, useMemo } from 'react';
import { useHref } from 'react-router-dom';
import styled from 'styled-components';

import { Horizontal, Vertical } from 'atoms/layout/flex';
import Dialog from 'components/dialog';
import { GraphQLResult } from 'components/form/Form';
import { LANDING } from 'constants/__generated__/routes';
import { useSnackNotificationContext } from 'contexts/snackNotification';
import useScreenSize from 'hooks/device/useScreenSize';
import { useEvents } from 'lib/events/useEvents';
import { tabletAndAbove } from 'style/mediaQuery';

import {
  PromptTermsCallback,
  PromptTermsOptions,
  useConnectionContext,
} from '..';
import OneMoreStep, { Title as OneMoreStepTitle } from './OneMoreStep';
import ReadThroughForm, {
  Title as TermsAndConditionsTitle,
} from './ReadThroughForm';
import { AcceptTermsMutation } from './__generated__/useAcceptTerms.graphql';
import useAcceptTerms, { AcceptTermsArgs } from './useAcceptTerms';

type AcceptTermsMutation_acceptTerms = NonNullable<
  AcceptTermsMutation['acceptTerms']
>;

const Root = styled(Vertical)`
  padding: var(--triple-unit);
`;
const Content = styled.div`
  --dialog-header-height: 61px;
  display: flex;
  flex-direction: row;
  max-height: calc(100vh - var(--dialog-header-height));
  @media ${tabletAndAbove} {
    max-height: calc(100vh - var(--dialog-header-height) - 40px);
  }
`;

type Props = {
  open: boolean;
  onAccept: PromptTermsCallback | null;
  onClose: () => void;
  options: PromptTermsOptions;
  isMobileWebviewSignUp?: boolean;
};
export const PromptTermsDialog = ({
  open,
  onAccept,
  onClose,
  options,
  isMobileWebviewSignUp,
}: Props) => {
  const {
    closable,
    withoutAcceptTermsMutation,
    tcuToken,
    initialTermsDisplay,
  } = options;
  const [acceptTermsMutation] = useAcceptTerms();
  const track = useEvents();
  const { up: isLaptop } = useScreenSize('laptop');
  const { up: isTablet } = useScreenSize('tablet');
  const { showNotification } = useSnackNotificationContext();
  const { signIn } = useConnectionContext();
  const PREFIXED_LANDING = useHref(LANDING);

  const acceptTerms = useMemo<
    (args: AcceptTermsArgs) => Promise<GraphQLResult>
  >(() => {
    if (withoutAcceptTermsMutation) {
      return async () => {
        return {};
      };
    }
    return async (newTermsInputs: AcceptTermsArgs) => {
      return (
        (await acceptTermsMutation({
          ...newTermsInputs,
          tcuToken,
        })) || {}
      );
    };
  }, [tcuToken, withoutAcceptTermsMutation, acceptTermsMutation]);

  const onFormSuccess = useCallback(
    (newTermsInputs: AcceptTermsArgs, result: GraphQLResult) => {
      if (onAccept) onAccept.resolve(newTermsInputs);
      const res = result as AcceptTermsMutation_acceptTerms;
      // we only show the snack notification if the user has accepted the terms
      // after the grace period (sign in blocked)

      if (tcuToken && !res.currentUser) {
        track('View Please Sign In');
        showNotification('termsAccepted');
        signIn();
      } else if (res.currentUser) {
        // refetching the config query breaks the wallet window
        // as a temporary fix, we reload the page, which does not have the same behavior
        // navigate(LANDING, { replace: true });
        // eslint-disable-next-line no-restricted-properties
        window.location.href = PREFIXED_LANDING;
      }
      onClose();
    },
    [
      onAccept,
      onClose,
      showNotification,
      signIn,
      tcuToken,
      track,
      PREFIXED_LANDING,
    ]
  );

  const handleClose = useCallback(() => {
    if (onAccept) onAccept.reject('rejected');
    onClose();
  }, [onAccept, onClose]);

  useEffect(() => {
    track('See TCU Modal', {
      mobile: !isLaptop,
    });
    return () => {};
  }, [track, isLaptop]);

  const doUseNewSignUp = initialTermsDisplay && !isMobileWebviewSignUp;

  const effectiveOnClose = useMemo(() => {
    if (doUseNewSignUp) {
      if (!closable) {
        return undefined;
      }
      return handleClose;
    }
    if (closable) {
      return handleClose;
    }
    return () => false;
  }, [doUseNewSignUp, closable, handleClose]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={effectiveOnClose}
      hideHeader
      fullScreen={!isTablet}
    >
      {({ CloseButton }) => (
        <Root>
          {!isMobileWebviewSignUp && (
            <Horizontal>
              {doUseNewSignUp ? (
                <OneMoreStepTitle />
              ) : (
                <TermsAndConditionsTitle />
              )}
              {closable && <CloseButton onClose={() => effectiveOnClose?.()} />}
            </Horizontal>
          )}
          <Content>
            {doUseNewSignUp ? (
              <OneMoreStep
                onFormSuccess={onFormSuccess}
                acceptTerms={acceptTerms}
              />
            ) : (
              <ReadThroughForm
                onFormSuccess={onFormSuccess}
                acceptTerms={acceptTerms}
              />
            )}
          </Content>
        </Root>
      )}
    </Dialog>
  );
};

export default PromptTermsDialog;
