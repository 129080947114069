import { TypedDocumentNode, gql } from '@apollo/client';

import { DumbPastWinner } from 'components/landing/CommunityBlock/DumbPastWinner';

import { AnySportPastWinner_so5Leaderboard } from './__generated__/index.graphql';

type Props = {
  so5Leaderboard: AnySportPastWinner_so5Leaderboard;
};

export const AnySportPastWinner = ({ so5Leaderboard }: Props) => {
  const lineupDataSimplified = so5Leaderboard?.so5Rankings.nodes[0]?.so5Lineup;

  if (!lineupDataSimplified || !so5Leaderboard?.so5LineupsCount) return null;
  return (
    <DumbPastWinner
      cards={lineupDataSimplified.so5Appearances.map(appearance => ({
        imageUrl: appearance.pictureUrl,
      }))}
      leaderboardIconUrl={so5Leaderboard.iconUrl}
      leaderboardName={so5Leaderboard.displayName}
      leaderboardRarity={so5Leaderboard.mainRarityType}
      lineupsCount={so5Leaderboard.so5LineupsCount}
      winner={{
        nickname: lineupDataSimplified.user.nickname,
        avatarUrl: lineupDataSimplified.user.pictureUrl,
      }}
      score={so5Leaderboard.so5Rankings.nodes[0].score}
    />
  );
};

AnySportPastWinner.fragments = {
  so5Leaderboard: gql`
    fragment AnySportPastWinner_so5Leaderboard on So5Leaderboard {
      slug
      displayName
      iconUrl
      mainRarityType
      so5LineupsCount
      so5Rankings(first: 1) {
        nodes {
          id
          score
          ranking
          so5Lineup {
            id
            so5Appearances {
              id
              pictureUrl
            }
            user {
              slug
              nickname
              pictureUrl
            }
          }
        }
      }
    }
  ` as TypedDocumentNode<AnySportPastWinner_so5Leaderboard>,
};
