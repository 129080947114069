import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { IconButton } from 'atoms/buttons/IconButton';
import { Locker } from 'atoms/icons/Locker';
import { Horizontal } from 'atoms/layout/flex';
import { glossary } from 'lib/glossary';

const LockerOverlay = styled(Horizontal).attrs({ gap: 0, center: true })<{
  small: boolean;
}>`
  position: absolute;
  inset: 0;
  background: rgba(var(--c-rgb-black), 0.8);
  border-radius: ${props => (props.small ? '2px' : `4px`)};
  opacity: 0.85;
`;

type Props = {
  small?: boolean;
  active: boolean;
};

export const CardLockerOverlay = ({ active, small = false }: Props) => {
  const { formatMessage } = useIntl();
  if (!active) {
    return null;
  }
  return (
    <LockerOverlay small={small}>
      <IconButton color="secondary" small={!small} smaller={small} asSpan>
        <Locker aria-label={formatMessage(glossary.locked)} />
      </IconButton>
    </LockerOverlay>
  );
};
