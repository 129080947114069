import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { CardCollectionCardScoreBreakdown } from '__generated__/globalTypes';
import { Caption, Text14 } from 'atoms/typography';
import { Score } from 'components/collections/Score';
import firstOwner from 'components/collections/assets/firstOwner.png';
import firstSerialNumber from 'components/collections/assets/firstSerialNumber.png';
import holding from 'components/collections/assets/holding.png';
import owner from 'components/collections/assets/owner.png';
import shirtMatchingSerialNumber from 'components/collections/assets/shirtMatchingSerialNumber.png';
import specialEdition from 'components/collections/assets/specialEdition.png';

import { Image } from './Image';

const Item = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    'img label score'
    'img explanation score';
  padding: var(--unit);
  justify-content: start;
  align-items: center;
  column-gap: var(--double-unit);
  &:not(:last-child) {
    border-bottom: 1px solid var(--c-nd-200);
  }
`;
const StyledText14 = styled(Text14)`
  grid-area: label;
`;
const StyledCaption = styled(Caption)`
  grid-area: explanation;
`;
const ScoreWrapper = styled.div`
  grid-area: score;
  margin-left: auto;
`;

export type DetailedScoreKey = keyof Omit<
  CardCollectionCardScoreBreakdown,
  '__typename' | 'total'
>;
export const detailedScores: Record<
  DetailedScoreKey,
  { img: string; label: ReactNode; value: number; explanation: ReactNode }
> = {
  owner: {
    img: owner,
    label: (
      <FormattedMessage
        id="collections.DetailsDialog.stages.owner"
        defaultMessage="Owned"
      />
    ),
    explanation: (
      <FormattedMessage
        id="collections.DetailsDialog.explanations.owner"
        defaultMessage="Card is in your collection"
      />
    ),
    value: 10,
  },
  holding: {
    img: holding,
    label: (
      <FormattedMessage
        id="collections.DetailsDialog.stages.holding"
        defaultMessage="90d"
      />
    ),
    explanation: (
      <FormattedMessage
        id="collections.DetailsDialog.explanations.holding"
        defaultMessage="Card was not listed or transferred in the last 90 days"
      />
    ),
    value: 10,
  },
  firstOwner: {
    img: firstOwner,
    label: (
      <FormattedMessage
        id="collections.DetailsDialog.stages.firstOwner"
        defaultMessage="New Card"
      />
    ),
    explanation: (
      <FormattedMessage
        id="collections.DetailsDialog.explanations.firstOwner"
        defaultMessage="You are the first owner of this card"
      />
    ),
    value: 20,
  },
  specialEdition: {
    img: specialEdition,
    label: (
      <FormattedMessage
        id="collections.DetailsDialog.stages.specialEdition"
        defaultMessage="Special"
      />
    ),
    explanation: (
      <FormattedMessage
        id="collections.DetailsDialog.explanations.specialEdition"
        defaultMessage="Special edition card design"
      />
    ),
    value: 20,
  },
  firstSerialNumber: {
    img: firstSerialNumber,
    label: (
      <FormattedMessage
        id="collections.DetailsDialog.stages.firstSerialNumber"
        defaultMessage="#1"
      />
    ),
    explanation: (
      <FormattedMessage
        id="collections.DetailsDialog.explanations.firstSerialNumber"
        defaultMessage="#1 Serial number"
      />
    ),
    value: 30,
  },
  shirtMatchingSerialNumber: {
    img: shirtMatchingSerialNumber,
    label: (
      <FormattedMessage
        id="collections.DetailsDialog.stages.shirtMatchingSerialNumber"
        defaultMessage="#Jersey"
      />
    ),
    explanation: (
      <FormattedMessage
        id="collections.DetailsDialog.explanations.shirtMatchingSerialNumber"
        defaultMessage="Serial number = Jersey number"
      />
    ),
    value: 30,
  },
  firstTimeOwnedPlayerCard: {
    img: firstOwner,
    label: (
      <FormattedMessage
        id="collections.DetailsDialog.stages.firstTimeOwnedPlayerCard"
        defaultMessage="First time owned player card"
      />
    ),
    explanation: (
      <FormattedMessage
        id="collections.DetailsDialog.explanations.firstTimeOwnedPlayerCard"
        defaultMessage="First time you owned a card from that player"
      />
    ),
    value: 30,
  },
};

export const blockchainDetailedScores = {
  ...detailedScores,
  firstTimeOwnedPlayerCard: null,
};
export const commonDetailedScores = {
  ...detailedScores,
  firstOwner: null,
  firstSerialNumber: null,
  shirtMatchingSerialNumber: null,
  specialEdition: {
    img: specialEdition,
    label: (
      <FormattedMessage
        id="collections.DetailsDialog.stages.specialEdition.rookie"
        defaultMessage="Rookie"
      />
    ),
    explanation: (
      <FormattedMessage
        id="collections.DetailsDialog.explanations.specialEdition.rookie"
        defaultMessage="This player is a rookie"
      />
    ),
    value: 20,
  },
  holding: {
    img: holding,
    label: (
      <FormattedMessage
        id="collections.DetailsDialog.stages.holding.7days"
        defaultMessage="7d"
      />
    ),
    explanation: (
      <FormattedMessage
        id="collections.DetailsDialog.explanations.holding.7days"
        defaultMessage="Card was not swapped in the last 7 days"
      />
    ),
    value: 20,
  },
  firstTimeOwnedPlayerCard: {
    img: firstOwner,
    label: (
      <FormattedMessage
        id="collections.DetailsDialog.stages.firstOwner"
        defaultMessage="New Card"
      />
    ),
    explanation: (
      <FormattedMessage
        id="collections.DetailsDialog.explanations.common.firstTimeOwnedPlayerCard"
        defaultMessage="You have never added or dropped the card before"
      />
    ),
    value: 10,
  },
};

type Props = {
  img: string;
  label: ReactNode;
  value: number;
  explanation: ReactNode;
  listed?: boolean;
};

export const DetailedScoreLine = ({
  img,
  label,
  explanation,
  value,
  listed,
}: Props) => {
  return (
    <Item>
      <Image img={img} listed={listed} />
      <StyledText14 bold>
        <span>{label}</span>
      </StyledText14>
      <StyledCaption color="var(--c-nd-700)" as="div">
        {explanation}
      </StyledCaption>
      <ScoreWrapper>
        <Score score={value} />
      </ScoreWrapper>
    </Item>
  );
};
