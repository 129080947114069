import { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';

import Form from '../Form';
import useAcceptSo5NoCardLineup from './useAcceptSo5NoCardLineup';

const messages = defineMessages({
  title: {
    id: 'NoCardEntry.Accept.title',
    defaultMessage:
      'We’ll send you an email for you to select the competition your team can be entered into.',
  },
});

interface Props {
  so5LineupId: string;
}

const Accept = ({ so5LineupId }: Props) => {
  const [acceptLineup, loading] = useAcceptSo5NoCardLineup();

  const [mutationResult, setMutationResult] =
    useState<Awaited<ReturnType<typeof acceptLineup>>>();

  useEffect(() => {
    acceptLineup({
      so5NoCardLineupId: `So5NoCardLineup:${so5LineupId}`,
    }).then(setMutationResult);
  }, [so5LineupId, acceptLineup]);

  return <Form title={messages.title} loading={loading} {...mutationResult} />;
};

export default Accept;
