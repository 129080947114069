import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
  useIntl,
} from 'react-intl';
import styled from 'styled-components';

import { SBHorizontal, Vertical } from 'atoms/layout/flex';
import { Ellipsis } from 'atoms/typography/Ellipsis';

import { InjuryTable_injury } from './__generated__/index.graphql';

export type PlayerInjuryColor = 'yellow' | 'red';

const messages = defineMessages({
  status: {
    id: 'Nba.Cards.InjuryTable.injuryStatus',
    defaultMessage: 'Status',
  },
  injury: {
    id: 'Nba.Cards.InjuryTable.injury',
    defaultMessage: 'Injury',
  },
  returnDate: {
    id: 'Nba.Cards.InjuryTable.returnDate',
    defaultMessage: 'Estimated Return',
  },
  returnDateAbbr: {
    id: 'Nba.Cards.InjuryTable.returnDateAbbr',
    defaultMessage: 'Est. Return',
  },
});

const ColorCell = styled.span`
  font-weight: var(--t-bold);
  &.red {
    color: var(--c-red-600);
  }
  &.yellow {
    color: var(--c-yellow-400);
  }
`;

type RowProps = {
  children: ReactNode;
  message: MessageDescriptor;
  color: PlayerInjuryColor;
};

const Row = ({ children, message, color }: RowProps) => {
  return (
    <SBHorizontal gap={3}>
      <span>
        <FormattedMessage {...message} />
      </span>
      <ColorCell className={color}>{children}</ColorCell>
    </SBHorizontal>
  );
};

type Props = {
  abbreviate?: boolean;
  injury: InjuryTable_injury;
  getPlayerInjuryColor: (status: string | null) => PlayerInjuryColor;
  getPlayerInjuryMessage: (
    status: string | null,
    options?: { long?: boolean }
  ) => MessageDescriptor;
};

export const InjuryTable = ({
  injury,
  abbreviate,
  getPlayerInjuryColor,
  getPlayerInjuryMessage,
}: Props) => {
  const { formatDate } = useIntl();
  const { status, kind, expectedEndDate } = injury;
  const color = getPlayerInjuryColor(status);
  return (
    <Vertical>
      {status && (
        <Row message={messages.status} color={color}>
          <Ellipsis>
            <FormattedMessage
              {...getPlayerInjuryMessage(status, { long: true })}
            />
          </Ellipsis>
        </Row>
      )}
      <Row message={messages.injury} color={color}>
        {kind}
      </Row>
      {expectedEndDate && (
        <Row
          message={abbreviate ? messages.returnDateAbbr : messages.returnDate}
          color={color}
        >
          {formatDate(expectedEndDate)}
        </Row>
      )}
    </Vertical>
  );
};

InjuryTable.fragments = {
  injury: gql`
    fragment InjuryTable_injury on Injury {
      id
      status
      kind
      expectedEndDate
    }
  ` as TypedDocumentNode<InjuryTable_injury>,
};
