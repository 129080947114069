import classNames from 'classnames';
import {
  ComponentProps,
  FC,
  FormEventHandler,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { useDebounce, useKey } from 'react-use';
import styled from 'styled-components';

import { Drawer } from 'atoms/layout/Drawer';
import { BodyM } from 'atoms/typography';
import {
  PopoverContent,
  PopoverPortal,
  PopoverRoot,
  PopoverTrigger,
} from 'atoms/ui/Popover';
import { useIsLaptop } from 'hooks/device/useIsLaptop';
import { useTouchScreen } from 'hooks/device/useTouchScreen';
import { regularMaterial, thickMaterial } from 'style/utils';

const Content = styled.div`
  box-shadow: var(--shadow-200);
  border-radius: var(--intermediate-unit) var(--intermediate-unit) 0 0;
  max-height: calc(
    var(--radix-popover-content-available-height, 100vh) - var(--unit)
  );
  overflow: auto;
  &:not(.transparent) {
    ${thickMaterial}
    border: 1px solid var(--c-nd-100);
  }
`;
const StyledForm = styled.form`
  margin: 0;
  outline: none;
`;
const StyledPopoverContent = styled(PopoverContent)`
  border-radius: var(--intermediate-unit);
  outline: none;

  &.forceMount {
    display: none;
    &[data-state='open'] {
      display: inherit;
    }
  }

  &.fullWidth {
    width: var(--radix-popover-trigger-width);
  }
`;

export const THRESHOLD = 10;

const useOpenState = ({
  open,
  onClose,
  onOpen,
}: Pick<Props, 'open' | 'onClose' | 'onOpen'>) => {
  const [openState, setOpenState] = useState(false);
  const isControlledMode = typeof open === 'boolean';
  if (isControlledMode) {
    return [
      open,
      (v: boolean) => {
        if (v) {
          onOpen?.();
        } else {
          onClose?.();
        }
      },
    ] as [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  }
  return [openState, setOpenState] as const;
};

export const DropdownOptionLabel = styled(BodyM).attrs({ as: 'label' })`
  display: flex;
  align-items: center;
  gap: var(--unit);
  padding: var(--unit) var(--quadruple-unit) var(--unit) var(--double-unit);
  color: var(--c-nd-600);
  cursor: pointer;
  &:focus,
  &:focus-within {
    background: var(--c-nd-100);
  }
  &:hover,
  &.selected {
    background: var(--c-nd-200);
  }
`;

const StyledDrawer = styled(Drawer)`
  width: 100%;
  ${regularMaterial}
`;

// Since React Portal maintain event propagation, we need to manually stop them in the DOM in case this Drawer is used inside an Element with mouse events
// There is no scenario where we want to keep the event propagation in a Popover
// https://github.com/facebook/react/issues/11387
// https://react.dev/reference/react-dom/createPortal#:~:text=more%20examples%20below.-,A%20portal%20only%20changes%20the%20physical%20placement%20of%20the%20DOM%20node,-.%20In%20every%20other
const stopPropagationEvents: Record<
  string,
  MouseEventHandler<HTMLButtonElement>
> = {
  onMouseEnter: e => e.stopPropagation(),
  onMouseLeave: e => e.stopPropagation(),
};

export type Props = {
  children:
    | ReactNode
    | FC<React.PropsWithChildren<{ closeDropdown: () => void }>>;
  label: (props: {
    onMouseEnter: MouseEventHandler<HTMLElement> | undefined;
    onMouseLeave: MouseEventHandler<HTMLElement> | undefined;
    onClick: MouseEventHandler<HTMLElement> | undefined;
    open: boolean;
  }) => ReactNode;
  gap?: 0 | 4 | 8 | 16;
  horizontalGap?: -16 | -8 | -4 | 0 | 4 | 8 | 16;
  onChange?: FormEventHandler<HTMLFormElement>;
  triggerOnHover?: boolean;
  closeOnChange?: boolean;
  open?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  fullWidth?: boolean;
  transparent?: boolean;
  align?: 'left' | 'right';
  side?: ComponentProps<typeof StyledPopoverContent>['side'];
  collisionPadding?: 0 | 4 | 8 | 16;
  forceDesktopBehavior?: boolean;
  forceMount?: boolean;
  trapClickOutside?: boolean;
};

export const Dropdown = ({
  open: openProp,
  children,
  label,
  gap = 0,
  horizontalGap = 0,
  onChange,
  triggerOnHover = false,
  closeOnChange = false,
  onClose = () => {},
  onOpen = () => {},
  fullWidth,
  align = 'left',
  side,
  collisionPadding,
  transparent,
  forceDesktopBehavior,
  forceMount,
  trapClickOutside,
}: Props) => {
  const [open, setOpen] = useOpenState({ open: openProp, onClose });
  const [mouseEntered, setMouseEntered] = useState(false);
  const isTouchScreen = useTouchScreen();
  const isLaptopAndAbove = useIsLaptop();

  const triggerOnHoverEnabled =
    !isTouchScreen && isLaptopAndAbove && triggerOnHover;

  const closeDropdown = useCallback(() => {
    onClose();
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  const openDropdown = useCallback(() => {
    onOpen();
    setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen]);

  useKey('Escape', () => open && closeDropdown(), undefined, [open]);

  useDebounce(
    () => {
      if (triggerOnHoverEnabled && !mouseEntered) {
        closeDropdown();
      }
    },
    100,
    [mouseEntered]
  );

  const mouseLeave = () => {
    if (triggerOnHoverEnabled) {
      setMouseEntered(false);
    }
  };

  const mouseEnter = () => {
    if (triggerOnHoverEnabled) {
      setMouseEntered(true);
      openDropdown();
    }
  };

  const button = label({
    onClick: e => {
      if (triggerOnHoverEnabled) {
        e.preventDefault();
      } else if (open) {
        closeDropdown();
      } else {
        openDropdown();
      }
    },
    onMouseEnter: mouseEnter,
    onMouseLeave: mouseLeave,
    open,
  });

  const content = (
    <Content className={classNames({ transparent })}>
      <StyledForm
        onChange={e => {
          if (onChange) {
            onChange(e);
          }
          if (closeOnChange) {
            closeDropdown();
          }
        }}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      >
        {typeof children === 'function'
          ? children({ closeDropdown })
          : children}
      </StyledForm>
    </Content>
  );

  if (forceDesktopBehavior || isLaptopAndAbove) {
    return (
      <PopoverRoot open={open} onOpenChange={setOpen} modal={trapClickOutside}>
        <PopoverTrigger {...stopPropagationEvents} asChild>
          {button}
        </PopoverTrigger>
        <PopoverPortal forceMount={forceMount || undefined}>
          <StyledPopoverContent
            sideOffset={gap}
            side={side}
            align={
              align
                ? ({ left: 'start', right: 'end' } as const)[align]
                : 'start'
            }
            alignOffset={horizontalGap}
            className={classNames({ forceMount, fullWidth })}
            asChild
            collisionPadding={collisionPadding}
            {...stopPropagationEvents}
          >
            {content}
          </StyledPopoverContent>
        </PopoverPortal>
      </PopoverRoot>
    );
  }

  return (
    <>
      {button}
      <StyledDrawer
        open={open}
        side="bottom"
        keepMounted={forceMount}
        onBackdropClick={closeDropdown}
      >
        {content}
      </StyledDrawer>
    </>
  );
};
