import { defineMessages } from 'react-intl';

import { Checkbox } from 'atoms/inputs/Checkbox';
import { SIGNUP_AGE_LIMIT } from 'config';
import { useIntlContext } from 'contexts/intl';

const messages = defineMessages({
  iAgreeToAgeLimit: {
    id: 'AcceptAgeLimit.iAgreeToAgeLimitWithVariable',
    defaultMessage: 'I certify that I am {signupAgeLimit} years old or older.',
  },
});

export const AcceptAgeLimit = (props: {
  checked: boolean;
  onChange: () => void;
  name: string;
}) => {
  const { formatMessage } = useIntlContext();

  return (
    <Checkbox
      {...props}
      label={formatMessage(messages.iAgreeToAgeLimit, {
        signupAgeLimit: SIGNUP_AGE_LIMIT,
      })}
    />
  );
};

export default AcceptAgeLimit;
