import { defineMessages } from 'react-intl';

import { BonusOrdinalRatingEnum } from '@sorare/core/src/__generated__/globalTypes';
import { Color, RGBColor } from '@sorare/core/src/style/types';

export const colorPerBonusRating: Record<BonusOrdinalRatingEnum, Color> = {
  [BonusOrdinalRatingEnum.VERY_LOW]: 'var(--c-score-veryLow)',
  [BonusOrdinalRatingEnum.LOW]: 'var(--c-score-low)',
  [BonusOrdinalRatingEnum.MEDIUM]: 'var(--c-score-mediumLow)',
  [BonusOrdinalRatingEnum.HIGH]: 'var(--c-score-medium)',
  [BonusOrdinalRatingEnum.VERY_HIGH]: 'var(--c-score-mediumHigh)',
  [BonusOrdinalRatingEnum.EXCEPTIONAL]: 'var(--c-score-high)',
};

export const rgbColorPerBonusRating: Record<BonusOrdinalRatingEnum, RGBColor> =
  {
    [BonusOrdinalRatingEnum.VERY_LOW]: 'var(--c-rgb-score-veryLow)',
    [BonusOrdinalRatingEnum.LOW]: 'var(--c-rgb-score-low)',
    [BonusOrdinalRatingEnum.MEDIUM]: 'var(--c-rgb-score-mediumLow)',
    [BonusOrdinalRatingEnum.HIGH]: 'var(--c-rgb-score-medium)',
    [BonusOrdinalRatingEnum.VERY_HIGH]: 'var(--c-rgb-score-mediumHigh)',
    [BonusOrdinalRatingEnum.EXCEPTIONAL]: 'var(--c-rgb-score-high)',
  };

export const bonusRatingMessages = defineMessages<BonusOrdinalRatingEnum>({
  [BonusOrdinalRatingEnum.VERY_LOW]: {
    id: 'BonusRating.veryLow',
    defaultMessage: 'Very Low',
  },
  [BonusOrdinalRatingEnum.LOW]: {
    id: 'BonusRating.low',
    defaultMessage: 'Low',
  },
  [BonusOrdinalRatingEnum.MEDIUM]: {
    id: 'BonusRating.medium',
    defaultMessage: 'Medium',
  },
  [BonusOrdinalRatingEnum.HIGH]: {
    id: 'BonusRating.high',
    defaultMessage: 'High',
  },
  [BonusOrdinalRatingEnum.VERY_HIGH]: {
    id: 'BonusRating.veryHigh',
    defaultMessage: 'Very High',
  },
  [BonusOrdinalRatingEnum.EXCEPTIONAL]: {
    id: 'BonusRating.exceptional',
    defaultMessage: 'Exceptional',
  },
});
