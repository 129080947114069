import { RefObject, useContext, useEffect } from 'react';
import type ReCAPTCHA from 'react-google-recaptcha';

import { ForgotPassword, MessagingContext } from '@sorare/wallet-shared';
import { useAuthContext } from 'contexts/auth';
import { useConnectionContext } from 'contexts/connection';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSentryContext } from 'contexts/sentry';
import { useSnackNotificationContext } from 'contexts/snackNotification';
import { useWalletContext } from 'contexts/wallet';
import { useWalletDrawerContext } from 'contexts/walletDrawer';
import { useIsMobileApp } from 'hooks/useIsMobileApp';

export default () => {
  const { registerHandler } = useContext(MessagingContext)!;
  const { createResetPasswordRequest } = useAuthContext();
  const { currentUser } = useCurrentUserContext();
  const { showNotification } = useSnackNotificationContext();
  const {
    setPasswordForgotten,
    passwordForgotten,
    recaptchaRef: connectionRecaptcha,
  } = useConnectionContext();
  const { logOut } = useWalletContext();
  const {
    hideWallet,
    hideDrawer,
    recaptchaRef: walletDrawerRecaptcha,
  } = useWalletDrawerContext();
  const { sendSafeError } = useSentryContext();
  const { isAndroidApp, postMessage } = useIsMobileApp();

  useEffect(
    () =>
      registerHandler<ForgotPassword>(
        'forgotPassword',
        async ({ email, remainOpened }) => {
          const processRecaptchaRef = async (
            reCAPTCHA: RefObject<ReCAPTCHA>['current']
          ) => {
            reCAPTCHA?.reset();
            const recaptchaToken = await reCAPTCHA?.executeAsync();

            if (!recaptchaToken) return {};

            const result = await createResetPasswordRequest(
              email,
              recaptchaToken
            );

            // The case where it was triggered from the Login Dialog
            if (passwordForgotten && !isAndroidApp) {
              setPasswordForgotten(false);
            }

            if (!remainOpened) {
              // If it was triggered from the drawer
              hideWallet();
              hideDrawer();
            }

            if (result.error) {
              showNotification('passwordForgottenError');
              return { error: 'unable to request password reset' };
            }

            showNotification('passwordForgottenSuccess');
            if (currentUser && !remainOpened) {
              await logOut();
            }

            if (isAndroidApp) {
              postMessage('logout', { isExplicit: true });
            }
            return {};
          };
          if (connectionRecaptcha.current) {
            return processRecaptchaRef(connectionRecaptcha.current);
          }
          if (walletDrawerRecaptcha.current) {
            return processRecaptchaRef(walletDrawerRecaptcha.current);
          }
          sendSafeError(
            'Trying to trigger a password forgotten procedure without a recaptcha context.'
          );
          return processRecaptchaRef(null);
        }
      ),
    [
      createResetPasswordRequest,
      registerHandler,
      showNotification,
      setPasswordForgotten,
      passwordForgotten,
      hideWallet,
      hideDrawer,
      logOut,
      currentUser,
      connectionRecaptcha,
      walletDrawerRecaptcha,
      sendSafeError,
      isAndroidApp,
      postMessage,
    ]
  );
};
