import classNames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { So5LeaderboardRarity } from '__generated__/globalTypes';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { Text14 } from 'atoms/typography';
import { Img } from 'atoms/ui/Img';
import { proxyUrl } from 'atoms/ui/ResponsiveImg';
import { PictureAvatar, PlaceHolderAvatar } from 'components/user/Avatar';
import useScreenSize from 'hooks/device/useScreenSize';
import { Scarcity } from 'lib/cards';
import { laptopAndAbove, tabletAndAbove } from 'style/mediaQuery';

const messages = defineMessages({
  firstPlace: {
    id: 'Landing.communityBlock.pastWinners.firstPlace',
    defaultMessage: '1st place',
  },
  points: {
    id: 'Landing.communityBlock.pastWinners.points',
    defaultMessage: 'PTS',
  },
});

const Wrapper = styled(Vertical).attrs({ gap: 1.5 })`
  padding: var(--double-unit);
  border-radius: var(--triple-unit);
  background-color: #1c1c1c;
`;

const LeaderboardName = styled.p`
  font-size: var(--t-14);
`;

const Ranking = styled.div`
  display: flex;
  justify-content: space-between;

  padding-bottom: var(--intermediate-unit);
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  margin-bottom: var(--intermediate-unit);
`;

const UserPanel = styled.div`
  display: flex;
  gap: var(--unit);
`;

const Rank = styled.div`
  span {
    color: var(--c-white);
    &.limited {
      color: #dfad46;
    }
    &.rare {
      color: #f2412d;
    }
    &.superRare,
    &.super_rare {
      color: #366bf2;
    }
    &.unique {
      color: #feecb3;
    }
  }
`;

const Score = styled(Text14)`
  font-weight: bold;

  @media ${tabletAndAbove} {
    font-size: 18px;
  }
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  height: 100%;
  gap: var(--unit);
  align-items: center;
  grid-template-columns: repeat(5, 1fr);

  &.sevenCards {
    gap: var(--half-unit);
    grid-template-columns: repeat(7, 1fr);
  }

  @media ${tabletAndAbove} {
    grid-template-columns: repeat(5, minmax(70px, 1fr));
    &.sevenCards {
      grid-template-columns: repeat(7, minmax(60px, 1fr));
    }
  }

  @media ${laptopAndAbove} {
    grid-template-columns: repeat(5, 1fr);
    &.sevenCards {
      grid-template-columns: repeat(7, 1fr);
    }
  }
`;

const UserName = styled.p`
  font-size: var(--t-20);
  font-family: 'Druk Wide';
  text-transform: uppercase;
  max-width: 10ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media ${tabletAndAbove} {
    font-size: 26px;
  }
  @media ${laptopAndAbove} {
    font-size: var(--t-20);
  }
`;

type PastWinnerProps = {
  leaderboardIconUrl: string | null;
  leaderboardName: string;
  leaderboardRarity: Scarcity | So5LeaderboardRarity | null;
  lineupsCount: number;
  winner: { nickname: string; avatarUrl: string | null };
  score: number;
  cards: {
    imageUrl: string | null;
  }[];
};

export const DumbPastWinner = ({
  cards,
  leaderboardIconUrl,
  leaderboardName,
  leaderboardRarity,
  lineupsCount,
  score,
  winner,
}: PastWinnerProps) => {
  const { up: isTabletOrDesktop } = useScreenSize('tablet');
  const { formatMessage, formatNumber } = useIntl();

  return (
    <Wrapper>
      <Horizontal>
        {leaderboardIconUrl && (
          <Img
            width="24"
            height="24"
            src={leaderboardIconUrl}
            alt={leaderboardName}
          />
        )}
        <LeaderboardName>{leaderboardName}</LeaderboardName>
      </Horizontal>
      <Ranking>
        {winner?.nickname && (
          <UserPanel>
            {winner?.avatarUrl ? (
              <PictureAvatar
                rounded
                variant={isTabletOrDesktop ? 'large' : 'medium'}
                user={winner}
                pictureUrl={winner.avatarUrl}
              />
            ) : (
              <PlaceHolderAvatar
                rounded
                user={winner}
                variant={isTabletOrDesktop ? 'large' : 'medium'}
              />
            )}
            <div>
              <Rank>
                <span className={leaderboardRarity?.toLocaleLowerCase()}>
                  {formatMessage(messages.firstPlace)}
                </span>
                &nbsp;/&nbsp;{formatNumber(lineupsCount)}
              </Rank>
              <UserName>{winner.nickname}</UserName>
            </div>
          </UserPanel>
        )}
        <Score>
          {formatNumber(score || 0)}&nbsp;
          {formatMessage(messages.points)}
        </Score>
      </Ranking>
      <CardsWrapper className={classNames({ sevenCards: cards?.length === 7 })}>
        {cards?.map((card, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            {card.imageUrl && (
              <Img
                src={proxyUrl(card.imageUrl, {
                  cropWidth: 80,
                })}
                alt=""
                width="160"
                height="258"
              />
            )}
          </div>
        ))}
      </CardsWrapper>
    </Wrapper>
  );
};
