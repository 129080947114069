import { TypedDocumentNode, gql } from '@apollo/client';
import { useEffect } from 'react';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useTypedParams } from '@sorare/routing';

import { LoadingButton } from '@sorare/core/src/atoms/buttons/LoadingButton';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { LabelM } from '@sorare/core/src/atoms/typography';
import Dialog from '@sorare/core/src/components/dialog';
import {
  FOOTBALL_PLAY_RIVALS_ARENA,
  FOOTBALL_PLAY_RIVALS_MY_SQUAD,
} from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useSeoContext } from '@sorare/core/src/contexts/seo';
import { isType } from '@sorare/core/src/gql';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { glossary } from '@sorare/core/src/lib/glossary';

import { SquadOverview } from 'components/rivals/squads/InviteFlow/SquadOverview';
import { useJoinRivalsSquad } from 'hooks/squads/useJoinRivalsSquad';

import {
  JoinRivalsSquadQuery,
  JoinRivalsSquadQueryVariables,
} from './__generated__/layout.graphql';
import { LayoutParams } from './__generated__/routeParams';

const CtaWrapper = styled(Vertical)`
  width: 100%;
  padding: var(--double-unit);
`;

const titleMessage = defineMessage({
  id: 'JoinRivalsSquadPage.seo.title',
  defaultMessage: 'Join my squad',
});

const JOIN_RIVALS_SQUAD_QUERY = gql`
  query JoinRivalsSquadQuery($joinSecret: String!) {
    football {
      rivals {
        id
        squadMembershipsMaxCount
      }
    }
    userGroup(joinSecret: $joinSecret) {
      slug
      displayName
      ... on FootballRivalsSquad {
        slug
        socialPictureUrls {
          post
        }
      }
      ...SquadOverview_footballRivalsSquad
    }
  }
  ${SquadOverview.fragments.footballRivalsSquad}
` as TypedDocumentNode<JoinRivalsSquadQuery, JoinRivalsSquadQueryVariables>;

const JoinRivalsSquadLayout = () => {
  const { joinSecret } = useTypedParams<LayoutParams>();
  const { setPageMetadata } = useSeoContext();
  const { formatMessage } = useIntl();
  const { isMe } = useCurrentUserContext();
  const navigate = useNavigate();
  const [joinSquad, { loading: mutationLoading }] = useJoinRivalsSquad();

  const { data, loading } = useQuery(JOIN_RIVALS_SQUAD_QUERY, {
    variables: { joinSecret },
  });

  const { userGroup, football } = data || {};
  const { squadMembershipsMaxCount = 10 } = football?.rivals || {};

  useEffect(() => {
    if (isType(userGroup, 'FootballRivalsSquad')) {
      setPageMetadata(
        formatMessage(titleMessage, { name: userGroup.displayName }),
        {
          img: userGroup.socialPictureUrls?.post,
        }
      );
    }
  }, [userGroup, setPageMetadata, formatMessage]);

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }
  if (!userGroup || !isType(userGroup, 'FootballRivalsSquad')) {
    return <Navigate to={FOOTBALL_PLAY_RIVALS_ARENA} replace />;
  }
  const currentUserAlreadyInSquad = userGroup.managers.some(({ user }) =>
    isMe(user)
  );
  if (currentUserAlreadyInSquad) {
    return <Navigate to={FOOTBALL_PLAY_RIVALS_MY_SQUAD} replace />;
  }
  const squadFull = squadMembershipsMaxCount === userGroup.membershipsCount;

  const onClick = () => {
    joinSquad({
      variables: {
        input: {
          joinSecret,
        },
      },
    }).then(() => {
      navigate(FOOTBALL_PLAY_RIVALS_MY_SQUAD);
    });
  };

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <Vertical gap={2}>
        <SquadOverview
          title={
            <LabelM color="var(--c-rivals-purple-600)" bold uppercase>
              <FormattedMessage
                id="JoinRivalsSquadPage.title"
                defaultMessage="You’ve been invited to join"
              />
            </LabelM>
          }
          squad={userGroup}
          squadMembershipsMaxCount={squadMembershipsMaxCount}
        />
        <CtaWrapper>
          <LoadingButton
            disabled={squadFull}
            onClick={onClick}
            color="secondary"
            size="medium"
            fullWidth
            loading={mutationLoading}
          >
            {squadFull ? (
              <FormattedMessage
                id="JoinRivalsSquadPage.squadFull"
                defaultMessage="Squad is full"
              />
            ) : (
              <FormattedMessage {...glossary.join} />
            )}
          </LoadingButton>
        </CtaWrapper>
      </Vertical>
    </Dialog>
  );
};

export default JoinRivalsSquadLayout;
