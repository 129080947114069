import classnames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';
import styled, { CSSProperties, css } from 'styled-components';

import { Link } from '@sorare/routing';

import { LabelS } from 'atoms/typography';
import { useAutoscroll } from 'hooks/useAutoscroll';
import { desktopAndAbove } from 'style/mediaQuery';
import { Color } from 'style/types';

export const boxStyle = css`
  position: relative;
  isolation: isolate;
  background: var(--c-nd-50);
  border-radius: var(--double-unit);
  padding: var(--double-unit);
  /**
    * no "overflow: hidden" should be applied to box style otherwise scroll-margin-top
    * would break
    */
  scroll-margin-top: var(--current-stack-height);
  /** cannot use :not(.noPadding) here because of specificy and how Box is often overriden */
  &.noPadding {
    padding: 0;
  }
`;

export const hoverStyle = css`
  outline: 2px solid var(--hover-color, --c-brand-600);
`;

export const selectedStyle = css`
  /**
    * Box is intended to used in ListWithDetailLayout, which doesn't need
    * selected state on mobile by default
    */
  @media ${desktopAndAbove} {
    background: rgba(var(--c-rgb-brand-600), 0.1);
    outline: 2px solid var(--c-brand-600);
  }
`;

export const Root = styled.div`
  ${boxStyle}
`;

export enum LabelPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
const LabelRoot = styled.div<{ position?: LabelPosition }>`
  position: absolute;
  z-index: 1;
  display: block;
  background: var(--label-bg-color, var(--c-brand-600));
  padding: var(--half-unit) var(--unit);

  ${({ position = LabelPosition.RIGHT }) => {
    if (position === LabelPosition.LEFT) {
      return css`
        top: 0;
        left: 0;
        border-radius: calc(var(--double-unit) - 2px) 0 var(--unit) 0;
      `;
    }
    return css`
      right: 0;
      top: 0;
      border-radius: 0 calc(var(--double-unit) - 2px) 0 var(--unit);
    `;
  }}
`;

type LabelProps = { labelProps: LabelType };
const Label = ({ labelProps }: LabelProps) => {
  const { value, color, bgColor, position } = labelProps;
  if (!value) {
    return null;
  }

  return (
    <LabelRoot
      position={position}
      style={
        {
          '--label-bg-color': bgColor,
        } as CSSProperties
      }
    >
      <LabelS color={color} bold>
        {value}
      </LabelS>
    </LabelRoot>
  );
};

type LabelType = {
  value?: ReactNode;
  color?: Color;
  bgColor?: string;
  position?: LabelPosition;
};
export type BoxProps = {
  className?: string;
  labelProps?: LabelType;
  style?: CSSProperties;
  noPadding?: boolean;
};
export const Box = ({
  labelProps,
  children,
  className,
  noPadding,
  ...rest
}: PropsWithChildren<BoxProps>) => {
  return (
    <Root className={classnames(className, { noPadding })} {...rest}>
      {labelProps && <Label labelProps={labelProps} />}
      {children}
    </Root>
  );
};

const BoxLinkComponent = styled(Link)<{ selected?: boolean }>`
  display: block;
  ${boxStyle}
  ${({ selected }) => selected && selectedStyle}
  &:focus,
  &:hover {
    ${hoverStyle}
    color: inherit;
  }
`;

type BoxLinkProps = {
  children: ReactNode;
  selected?: boolean;
  labelProps?: LabelType;
  className?: string;
  autoScroll?: boolean;
} & React.ComponentProps<typeof Link>;
export const BoxLink = ({
  selected = false,
  autoScroll = true,
  labelProps,
  children,
  ...rest
}: BoxLinkProps) => {
  const { ref } = useAutoscroll<HTMLAnchorElement>(autoScroll && selected);
  return (
    <BoxLinkComponent
      ref={autoScroll ? ref : null}
      {...rest}
      selected={selected}
    >
      {labelProps && <Label labelProps={labelProps} />}
      {children}
    </BoxLinkComponent>
  );
};
