/* eslint sort-keys:["warn", "asc", { "minKeys": 3 }] */

import { MessageDescriptor, defineMessages } from 'react-intl';

import {
  GameStatus,
  ReferralState,
  SeasonEligibility,
  ShopItemType,
} from '__generated__/globalTypes';
import {
  AlgoliaCardIndexesName,
  PrintableI18nPlayerSortName,
} from 'contexts/config';

export const CAPTAIN = 'C';

export const glossary = defineMessages({
  accept: {
    id: 'DirectOfferStatus.accept',
    defaultMessage: 'Accept',
  },
  accountEmail: {
    id: 'Settings.updateEmail.newTitle',
    defaultMessage: 'Account Email',
  },
  addATeam: {
    id: 'pages.play.pro.fixture.competition.add.addATeam',
    defaultMessage: 'Add a team',
  },
  addPhoneNumber: {
    id: 'accountSecurityCheck.phoneNumber.add',
    defaultMessage: 'Add phone number',
  },
  all: {
    id: 'Glossary.all',
    defaultMessage: 'All',
  },
  amateur: { id: 'Glossary.Amateur', defaultMessage: 'Amateur' },
  applyFilters: {
    id: 'glossary.applyFilters',
    defaultMessage: 'Apply filters',
  },
  auction: {
    id: 'FiatTransactionsHistoryAuction.title',
    defaultMessage: 'Auction',
  },
  avatar: {
    id: 'Glossary.avatar',
    defaultMessage: 'Avatar',
  },
  back: {
    id: 'DialogOnboarding.Back',
    defaultMessage: 'Back',
  },
  bestGame: {
    id: 'chart.bestGame',
    defaultMessage: 'Best game',
  },
  biweekly: {
    id: 'Glossary.biweekly',
    defaultMessage: 'Biweekly',
  },
  buy: {
    id: 'BuyField.buy',
    defaultMessage: 'Buy now',
  },
  buyLowest: {
    id: 'BuyField.buyLowest',
    defaultMessage: 'Buy lowest price',
  },
  buyWithName: {
    id: 'FootballBasicMarketCard.buyName',
    defaultMessage: 'Buy {name}',
  },
  cancel: {
    id: 'Settings.PhoneNumberVerification.EnterVerificationCode.cancel',
    defaultMessage: 'Cancel',
  },
  canceled: {
    id: 'Glossary.canceled',
    defaultMessage: 'Canceled',
  },
  card: {
    id: 'Glossary.card',
    defaultMessage: 'Card',
  },
  cards: {
    id: 'Club.Data.cards',
    defaultMessage: 'Cards',
  },
  cash: {
    id: 'Glossary.cash',
    defaultMessage: 'Cash',
  },
  challenges: {
    id: 'Glossary.challenges',
    defaultMessage: 'Challenges',
  },
  championshipChase: {
    id: 'championshipChase.title',
    defaultMessage: 'Championship Chase',
  },
  chooseYourSport: {
    id: 'Landing.NewOtherSports.title',
    defaultMessage: 'Choose your sport',
  },
  claim: { id: 'Glossary.Claim', defaultMessage: 'Claim' },
  claimNow: { id: 'Glossary.ClaimNow', defaultMessage: 'Claim Now' },
  claimed: { id: 'Glossary.Claimed', defaultMessage: 'Claimed' },
  close: { id: 'Button.Close', defaultMessage: 'Close' },
  closed: {
    id: 'Lobby.CompetitionList.NoCardEntry.closed',
    defaultMessage: 'Closed',
  },
  closesIn: {
    id: 'Lobby.CompetitionList.NoCardEntry.nextClose',
    defaultMessage: 'Closes in {time}',
  },
  coins: {
    id: 'Glossary.coins',
    defaultMessage: 'Coins',
  },
  comingSoon: {
    id: 'Glossary.comingSoon',
    defaultMessage: 'Coming soon',
  },
  commonEssence: {
    id: 'Glossary.commonEssence',
    defaultMessage: 'Common Essence',
  },
  completed: {
    id: 'Glossary.completed',
    defaultMessage: 'Completed',
  },
  confirm: {
    id: 'ConfirmationDialogContent.confirm',
    defaultMessage: 'Confirm',
  },
  connectWallet: {
    id: 'glossary.connectWallet',
    defaultMessage: 'Connect Wallet',
  },
  continue: {
    id: 'UsedCardsDialog.continue',
    defaultMessage: 'Continue',
  },
  coopLeagues: {
    id: 'Glossary.coopLeagues',
    defaultMessage: 'Co-op Leagues',
  },
  copiedToClipboard: {
    id: 'Glossary.copiedToClipboard',
    defaultMessage: 'Copied to clipboard!',
  },
  copy: { id: 'Glossary.copy', defaultMessage: 'Copy' },
  countdown: {
    id: 'OffseasonHeader.countdown',
    defaultMessage:
      '<segment>{days}<unit>d</unit></segment> : <segment>{hours}<unit>h</unit></segment> : <segment>{minutes}<unit>m</unit></segment> : <segment>{seconds}<unit>s</unit></segment>',
  },
  countdownWithoutDays: {
    id: 'OffseasonHeader.countdownWithoutDays',
    defaultMessage:
      '<segment>{hours}<unit>h</unit></segment> : <segment>{minutes}<unit>m</unit></segment> : <segment>{seconds}<unit>s</unit></segment>',
  },
  countryOfResidence: {
    id: 'Glossary.countryOfResidence',
    defaultMessage: 'Country of residence',
  },
  create: {
    id: 'DeckForm.create',
    defaultMessage: 'Create',
  },
  customLeagues: {
    id: 'Glossary.customLeagues',
    defaultMessage: 'Custom Leagues',
  },
  daily: {
    id: 'Home.ForYouBlock.daily',
    defaultMessage: 'Daily',
  },
  dailyHoops: {
    id: 'Glossary.daylyHoops',
    defaultMessage: 'Daily Hoops',
  },
  dateOfBirth: {
    id: 'Glossary.dateOfBirth',
    defaultMessage: 'Date of birth',
  },
  decline: {
    id: 'Glossary.decline',
    defaultMessage: 'Decline',
  },
  declined: {
    id: 'Glossary.declined',
    defaultMessage: 'Declined',
  },
  delete: {
    id: 'Lobby.Upcoming.Delete',
    defaultMessage: 'Delete',
  },
  deposit: {
    id: 'TransactionsHistoryDeposit.title',
    defaultMessage: 'Deposit',
  },
  depositAction: {
    id: 'DepositEthForm.submit',
    defaultMessage: 'Deposit',
  },
  disabled: {
    id: 'Settings.settingsSection.disabled',
    defaultMessage: 'Disabled',
  },
  discount: {
    id: 'Glossary.discount',
    defaultMessage: 'Discount',
  },
  discover: {
    id: 'glossary.discover',
    defaultMessage: 'Discover',
  },
  dismiss: {
    id: 'Glossary.dismiss',
    defaultMessage: 'Dismiss',
  },
  doItLater: {
    id: 'Glossary.doItLater',
    defaultMessage: 'I’ll do it later',
  },
  done: {
    id: 'Settings.recoveryCodesDialog.button',
    defaultMessage: 'Done',
  },
  download: { id: 'Glossary.download', defaultMessage: 'Download' },
  earlyAccess: {
    id: 'Glossary.earlyAccess',
    defaultMessage: 'Early access',
  },
  edit: {
    id: 'CardCollections.CollectionFulfilledSlot.EditButton',
    defaultMessage: 'Edit',
  },
  enabled: {
    id: 'Settings.settingsSection.enabled',
    defaultMessage: 'Enabled',
  },
  endsInTime: {
    id: 'AuctionState.state.endsIn',
    defaultMessage: 'Ends in {timeRemaining}',
  },
  equipped: { id: 'ClubShop.Item.Equipped', defaultMessage: 'Equipped' },
  essence: {
    id: 'Glossary.essence',
    defaultMessage: 'Essence',
  },
  essenceLabel: {
    id: 'Glossary.essenceLabel',
    defaultMessage:
      'Essence {quantity, plural, one {capsule} other {capsules}}',
  },
  essenceRarityLabel: {
    id: 'Glossary.essenceRarityLabel',
    defaultMessage:
      '{rarity} Essence {quantity, plural, one {capsule} other {capsules}}',
  },
  eth: {
    id: 'Glossary.eth',
    defaultMessage: 'ETH',
  },
  europeanChampionship: {
    id: 'Glossary.europeanChampionship',
    defaultMessage: 'European Championship',
  },
  event: { id: 'Glossary.Event', defaultMessage: 'Event' },
  events: { id: 'Glossary.Events', defaultMessage: 'Events' },
  exclusive: { id: 'Glossary.Exclusive', defaultMessage: 'Exclusive' },
  experiences: { id: 'Glossary.Experiences', defaultMessage: 'Experiences' },
  expiration: {
    id: 'RefereeItemContent.expiration',
    defaultMessage: 'Expiration',
  },
  expired: {
    id: 'Glossary.expired',
    defaultMessage: 'Expired',
  },
  failed: {
    id: 'Glossary.failed',
    defaultMessage: 'Failed',
  },
  fewMoments: { id: 'Glossary.fewMoments', defaultMessage: 'a few moments' },
  filterBy: {
    id: 'ShopItemPicker.FilterBy.label',
    defaultMessage: 'Filter by',
  },
  find: {
    id: 'glossary.find',
    defaultMessage: 'Find',
  },
  finish: {
    id: 'glossary.finish',
    defaultMessage: 'Finish',
  },
  firstName: {
    id: 'Glossary.firstName',
    defaultMessage: 'First name',
  },
  follow: {
    id: 'Follower.add',
    defaultMessage: 'Follow',
  },
  followed: {
    id: 'Follower.followed',
    defaultMessage: 'Followed',
  },
  followers: { id: 'Follows.followers', defaultMessage: 'Followers' },
  following: { id: 'Follows.following', defaultMessage: 'Following' },
  forYou: { id: 'Glossary.forYou', defaultMessage: 'For You' },
  free: { id: 'Glossary.free', defaultMessage: 'Free' },
  games: { id: 'Glossary.games', defaultMessage: 'Games' },
  general: {
    id: 'Glossary.general',
    defaultMessage: 'General',
  },
  getStarted: {
    id: 'Draft.Action.GetStarted',
    defaultMessage: 'Get Started',
  },
  gif: {
    id: 'Glossary.gif',
    defaultMessage: 'GIF',
  },
  giftCard: { id: 'Glossary.Gift Card', defaultMessage: 'Gift Card' },
  global: {
    id: 'glossary.global',
    defaultMessage: 'Global',
  },
  goToMarket: {
    id: 'Inventory.RevealableCards.goToMarket',
    defaultMessage: 'Go to market',
  },
  goToMyCards: {
    id: 'ClubShop.ItemPreviewDialog.Cta.RedirectToMyCards',
    defaultMessage: 'Go to My Cards',
  },
  gotIt: {
    id: 'Glossary.gotIt',
    defaultMessage: 'Got it',
  },
  gw: {
    id: 'Glossary.gw',
    defaultMessage: 'GW',
  },
  howToPlay: {
    id: 'Glossary.howToPlay',
    defaultMessage: 'How to Play',
  },
  inventory: {
    id: 'ClubShop.Tabs.Inventory',
    defaultMessage: 'Inventory',
  },
  jerseys: { id: 'Glossary.Jerseys', defaultMessage: 'Jerseys' },
  join: {
    id: 'Glossary.join',
    defaultMessage: 'Join',
  },
  joinForFree: {
    id: 'Glossary.joinForFree',
    defaultMessage: 'Join for free',
  },
  lastName: {
    id: 'Glossary.lastName',
    defaultMessage: 'Last name',
  },
  later: {
    id: 'Glossary.later',
    defaultMessage: 'Later',
  },
  latestNews: {
    id: 'PlayPage.blogTitle',
    defaultMessage: 'Latest News',
  },
  leaderboards: {
    id: 'Glossary.leaderboards',
    defaultMessage: 'Leaderboards',
  },
  learnMore: {
    id: 'Glossary.learnMore',
    defaultMessage: 'Learn more',
  },
  less: { id: 'Glossary.Less', defaultMessage: 'Less' },
  letsGo: {
    id: 'WelcomeToTheArena.finishOnboardingCta',
    defaultMessage: "Let's go!",
  },
  lineups: {
    id: 'Glossary.lineups',
    defaultMessage: 'Lineups',
  },
  listings: {
    id: 'StartingPriceCell.listings',
    defaultMessage: 'Listings',
  },
  loadMore: {
    id: 'Nav.loadMore',
    defaultMessage: 'Load more',
  },
  loading: {
    id: 'LoadingIndicator.loading',
    defaultMessage: 'Loading',
  },
  locked: {
    id: 'Glossary.locked',
    defaultMessage: 'Locked',
  },
  logOut: {
    id: 'Settings.logOutAllDevices.submit',
    defaultMessage: 'Log Out',
  },
  lowestPrice: {
    id: 'marketplace.commonPlayerHeaderTable.sortByPrice',
    defaultMessage: 'Lowest price',
  },
  manager: {
    id: 'Glossary.manager',
    defaultMessage: 'Manager',
  },
  matchInfo: {
    id: 'Glossary.matchInfo',
    defaultMessage: 'Match info',
  },
  matches: { id: 'Glossary.matches', defaultMessage: 'Matches' },
  max: {
    id: 'BreakdownBlock.max',
    defaultMessage: 'Max',
  },
  merch: {
    id: 'Glossary.merch',
    defaultMessage: 'Merch',
  },
  modify: {
    id: 'Glossary.modify',
    defaultMessage: 'Modify',
  },
  money: {
    id: 'Glossary.money',
    defaultMessage: 'Money',
  },
  more: { id: 'Glossary.More', defaultMessage: 'More' },
  mp: {
    id: 'Challenges.Category.Task.mp',
    defaultMessage: 'MP',
  },
  mvp: {
    id: 'Glossary.mvp',
    defaultMessage: 'MVP',
  },
  mySearches: {
    id: 'Glossary.mySearches',
    defaultMessage: 'My searches',
  },
  name: {
    id: 'Glossary.name',
    defaultMessage: 'Name',
  },
  nationality: {
    id: 'Glossary.nationality',
    defaultMessage: 'Nationality',
  },
  new: { id: 'Glossary.New', defaultMessage: 'New' },
  next: {
    id: 'CreateClub.confirm',
    defaultMessage: 'Next',
  },
  no: {
    id: 'WithdrawCancelDialog.cancelCta',
    defaultMessage: 'No',
  },
  noCardEntry: {
    id: 'Lobby.CompetitionList.NoCardEntry.title',
    defaultMessage: 'No Card Entry',
  },
  noGame: {
    id: 'glossary.noGame',
    defaultMessage: 'No game',
  },
  noResultsFound: {
    id: 'glossary.noResultsFound',
    defaultMessage: 'No results found',
  },
  noTeamForThisWeek: {
    id: 'baseball.lobby.myTeams.description',
    defaultMessage: 'No team registered for this week.',
  },
  noThanks: {
    id: 'Glossary.noThanks',
    defaultMessage: 'No, thanks',
  },
  notMajorError: {
    id: 'Glossary.notMajorError',
    defaultMessage: 'You must be over 18.',
  },
  now: {
    id: 'glossary.now',
    defaultMessage: 'now',
  },
  officiallyLicensed: {
    id: 'LeagueName.officiallyLicensed',
    defaultMessage: 'Officially licensed product of <b>{displayName}</b>',
  },
  officiallyLicensedProduct: {
    id: 'OfficialProduct.subtitle',
    defaultMessage: 'Officially licensed product',
  },
  ok: {
    id: 'AuctionNotification.ok',
    defaultMessage: 'OK',
  },
  open: {
    id: 'Glossary.open',
    defaultMessage: 'Open',
  },
  opensIn: {
    id: 'Lobby.CompetitionList.NoCardEntry.nextOpen',
    defaultMessage: 'Opens in {time}',
  },
  or: {
    id: 'Glossary.or',
    defaultMessage: 'or',
  },
  ordinal: {
    id: 'Glossary.ordinal',
    defaultMessage:
      '{ordinal, selectordinal, one {#st} two {#nd} few {#rd} other {#th}}',
  },
  ordinalStyled: {
    id: 'Glossary.ordinalStyled',
    defaultMessage:
      '{ordinal, selectordinal, one {#<sup>st</sup>} two {#<sup>nd</sup>} few {#<sup>rd</sup>} other {#<sup>th</sup>}}',
  },
  owned: { id: 'Glossary.owned', defaultMessage: 'Owned' },
  payWith: {
    id: 'Glossary.payWith',
    defaultMessage: 'Pay with',
  },
  play: {
    id: 'Glossary.play',
    defaultMessage: 'Play',
  },
  playInPro: {
    id: 'glossary.playInPro',
    defaultMessage: 'Play in Pro!',
  },
  playNow: {
    id: 'Glossary.playNow',
    defaultMessage: 'Play now',
  },
  players: {
    id: 'Glossary.players',
    defaultMessage: 'Players',
  },
  points: {
    id: 'Glossary.points',
    defaultMessage: 'Points',
  },
  poweredBy: {
    id: 'Seo.Player.News.poweredBy',
    defaultMessage: 'Powered by',
  },
  previous: {
    id: 'Glossary.previous',
    defaultMessage: 'Previous',
  },
  primaryBuy: {
    id: 'primaryBuy.buy',
    defaultMessage: 'Buy Now – {amount}',
  },
  pro: { id: 'Glossary.Pro', defaultMessage: 'Pro' },
  productUpdate: {
    id: 'glossary.productUpdate',
    defaultMessage: 'Product Update',
  },
  projection: {
    id: 'GameWeekProjection.projection',
    defaultMessage: 'Projection',
  },
  projectionShort: {
    id: 'Glossary.projectionShort',
    defaultMessage: 'Proj.',
  },
  projections: {
    id: 'GameWeekProjection.projections',
    defaultMessage: 'Projections',
  },
  quietWeek: {
    id: 'Lobby.Teams.Empty.title',
    defaultMessage: 'Quiet week',
  },
  ranking: { id: 'Glossary.ranking', defaultMessage: 'Ranking' },
  ready: { id: 'Glossary.ready', defaultMessage: 'Ready' },
  receive: { id: 'Glossary.receive', defaultMessage: 'Receive' },
  recoveryEmail: {
    id: 'Glossary.recoveryEmail',
    defaultMessage: 'Recovery email',
  },
  referral: {
    id: 'PlayPage.referral',
    defaultMessage: 'Referral',
  },
  register: { id: 'Glossary.Register', defaultMessage: 'Register' },
  reject: {
    id: 'glossary.reject',
    defaultMessage: 'Reject',
  },
  relatedClubs: {
    id: 'RelatedClubs.Title',
    defaultMessage: 'Related clubs',
  },
  relatedPlayers: {
    id: 'RelatedPlayers.Title',
    defaultMessage: 'Related players',
  },
  reload: { id: 'Glossary.reload', defaultMessage: 'Reload' },
  remove: { id: 'Draft.PlayerList.Cta.Remove', defaultMessage: 'Remove' },
  resendVerificationCode: {
    id: 'Glossary.resendVerificationEmail',
    defaultMessage: 'Resend verification email',
  },
  reward: {
    id: 'TransactionsHistoryReward.title',
    defaultMessage: 'Reward',
  },
  rewards: {
    id: 'Glossary.rewards',
    defaultMessage: 'Rewards',
  },
  rewardsPool: {
    id: 'Glossary.rewardsPool',
    defaultMessage: 'Rewards Pool',
  },
  rivalsLeagues: {
    id: 'Glossary.rivalsLeagues',
    defaultMessage: 'Rivals Leagues',
  },
  santa: {
    id: 'Glossary.santa',
    defaultMessage: 'Santa',
  },
  save: {
    id: 'Glossary.save',
    defaultMessage: 'Save',
  },
  savePreferences: {
    id: 'Glossary.savePreferences',
    defaultMessage: 'Save preferences',
  },
  saved: {
    id: 'Glossary.saved',
    defaultMessage: 'Saved',
  },
  savedSearch: {
    id: 'Glossary.savedSearch',
    defaultMessage: 'Saved',
  },
  search: {
    id: 'RefineList.searchPlaceholder',
    defaultMessage: 'Search',
  },
  searchByPlayerClubLeague: {
    id: 'ComposeTeam.BenchFilter.search',
    defaultMessage: 'Search by Player, Club or League',
  },
  seeAll: { id: 'Glossary.SeeAll', defaultMessage: 'See all' },
  seeDetails: { id: 'Glossary.SeeDetails', defaultMessage: 'See details' },
  select: {
    id: 'Glossary.Select',
    defaultMessage: 'Select',
  },
  send: { id: 'Glossary.send', defaultMessage: 'Send' },
  share: { id: 'Glossary.share', defaultMessage: 'Share' },
  showLess: {
    id: 'Glossary.showLess',
    defaultMessage: 'Show less',
  },
  showMore: {
    id: 'Glossary.showMore',
    defaultMessage: 'Show more',
  },
  showPlayerInfo: {
    id: 'Glossary.showPlayerInfo',
    defaultMessage: 'Show player info',
  },
  shuffle: { id: 'Glossary.shuffle', defaultMessage: 'Shuffle' },
  signin: {
    id: 'ConnectionDialog.signin',
    defaultMessage: 'Sign in',
  },
  signup: {
    id: 'ConnectionDialog.signup',
    defaultMessage: 'Sign up',
  },
  skip: {
    id: 'glossary.skip',
    defaultMessage: 'Skip',
  },
  social: {
    id: 'Glossary.social',
    defaultMessage: 'Social',
  },
  squads: {
    id: 'Glossary.squads',
    defaultMessage: 'Squads',
  },
  starUniqueCountdown: {
    id: 'starUnique.countdown',
    defaultMessage:
      '<segment>{days}<unit>{days, plural, one {Day} other {Days}}</unit></segment><segment>{hours}<unit>{hours, plural, one {Hour} other {Hours}}</unit></segment><segment>{minutes}<unit>Min</unit></segment><segment>{seconds}<unit>Sec</unit></segment>',
  },
  start: {
    id: 'Glossary.start',
    defaultMessage: 'Start',
  },
  startCollecting: {
    id: 'Glossary.startCollecting',
    defaultMessage: 'Start collecting',
  },
  startingAt: {
    id: 'BuyField.startingAt',
    defaultMessage: 'Starting at',
  },
  startingSoon: {
    id: 'GameStatus.startingSoon',
    defaultMessage: 'Starting soon',
  },
  stats: {
    id: 'Glossary.stats',
    defaultMessage: 'Stats',
  },
  submit: {
    id: 'Prompt2faDialog.submit',
    defaultMessage: 'Submit',
  },
  termsApply: {
    id: 'ConversionCreditTinyBanner.termsLink',
    defaultMessage: 'Terms apply',
  },
  ticket: { id: 'Glossary.Ticket', defaultMessage: 'Ticket' },
  tickets: { id: 'Glossary.Tickets', defaultMessage: 'Tickets' },
  today: {
    id: 'Glossary.Today',
    defaultMessage: 'Today',
  },
  tomorrow: {
    id: 'Glossary.tomorrow',
    defaultMessage: 'Tomorrow',
  },
  topPlayers: {
    id: 'PlayerRankings.title',
    defaultMessage: 'Top players',
  },
  trade: {
    id: 'USSportGalleryHeader.trade',
    defaultMessage: 'Trade',
  },
  transactions: {
    id: 'Glossary.transactions',
    defaultMessage: 'Transactions',
  },
  transferInProgress: {
    id: 'ItemSold.transferInProgress',
    defaultMessage: 'Transfer in progress',
  },
  twofa: {
    id: 'Settings.disable2FA.dialogTitle',
    defaultMessage: 'Two-factor Authentication',
  },
  unclaimed: { id: 'Glossary.Unclaimed', defaultMessage: 'Unclaimed' },
  undo: {
    id: 'Glossary.actions.undo',
    defaultMessage: 'Undo',
  },
  unfollow: {
    id: 'Follower.unfollow',
    defaultMessage: 'Unfollow',
  },
  unlock: {
    id: 'Glossary.unlock',
    defaultMessage: 'Unlock',
  },
  unlocked: {
    id: 'Glossary.unlocked',
    defaultMessage: 'Unlocked',
  },
  update: {
    id: 'Settings.updateProfile.submit',
    defaultMessage: 'Update',
  },
  upgrade: {
    id: 'Glossary.upgrade',
    defaultMessage: 'Upgrade',
  },
  upgraded: {
    id: 'Glossary.upgraded',
    defaultMessage: 'Upgraded',
  },
  verify: {
    id: 'Glossary.verify',
    defaultMessage: 'Verify',
  },
  viewDetails: {
    id: 'Glossary.ViewLineupDetails',
    defaultMessage: 'View details',
  },
  viewGallery: {
    id: 'Glossary.viewGallery',
    defaultMessage: 'View gallery',
  },
  viewMore: { id: 'Glossary.ViewMore', defaultMessage: 'View More' },
  viewMyItems: {
    id: 'ClubShop.ItemPreviewDialog.Cta.ViewMyItems',
    defaultMessage: 'View My Items',
  },
  viewResults: {
    id: 'Glossary.ViewLineupResults',
    defaultMessage: 'View results',
  },
  viewed: {
    id: 'Glossary.viewed',
    defaultMessage: 'Viewed',
  },
  waitingForMatches: {
    id: 'Glossary.waitingForMatches',
    defaultMessage: 'Waiting for matches',
  },
  week: {
    id: 'Glossary.week',
    defaultMessage: 'Week',
  },
  withdraw: {
    id: 'bankEthAccounting.tabs.withdrawTo',
    defaultMessage: 'Withdraw',
  },
  yes: {
    id: 'WithdrawCancelDialog.confirmCta',
    defaultMessage: 'Yes',
  },
  you: {
    id: 'Glossary.you',
    defaultMessage: 'You',
  },
  zipCode: {
    id: 'ItemPreviewDialog.PostalAddressForm.zipCode',
    defaultMessage: 'Zip Code',
  },
});

export const tradeLabels = defineMessages({
  counterOfferWith: {
    id: 'Glossary.CounterOfferWith',
    defaultMessage: "Counter {nickname}'s trade",
  },
  tradeWith: {
    id: 'Glossary.TradeWith',
    defaultMessage: 'Trade with {nickname}',
  },
  usernameSends: {
    id: 'DirectOffer.usernameSends',
    defaultMessage: '<b>{username}</b> sends',
  },
  youReceive: {
    id: 'DealSummary.receiveTitle',
    defaultMessage: 'You receive',
  },
  youReceiveWithAvatar: {
    id: 'Glossary.youReceiveWithAvatar',
    defaultMessage: '<span>{nickname}</span> sends',
  },
  youSend: {
    id: 'DealSummary.sendTitle',
    defaultMessage: 'You send',
  },
});

export const navLabels = defineMessages({
  about: {
    id: 'navLabels.about',
    defaultMessage: 'About',
  },
  affiliateProgram: {
    id: 'Nav.affiliateProgram',
    defaultMessage: 'Affiliate Program',
  },
  auctions: {
    id: 'navLabels.auctions',
    defaultMessage: 'Auctions',
  },
  blog: {
    id: 'Nav.blog',
    defaultMessage: 'Blog',
  },
  calendar: {
    id: 'Nav.play.calendar',
    defaultMessage: 'Calendar',
  },
  careers: { id: 'Nav.careers', defaultMessage: 'Careers' },
  challengers: {
    id: 'Nav.rivals.challengers',
    defaultMessage: 'Challengers',
  },
  chase: {
    id: 'Nav.play.chase',
    defaultMessage: 'Chase',
  },
  clubHistory: {
    id: 'Nav.clubHistory',
    defaultMessage: 'Club History',
  },
  clubHonors: {
    id: 'Nav.ClubHonors',
    defaultMessage: 'Club Honors',
  },
  clubshop: {
    id: 'Nav.clubshop',
    defaultMessage: 'Club Shop',
  },
  collections: {
    id: 'Nav.collections',
    defaultMessage: 'Collections',
  },
  commonPacks: {
    id: 'Nav.commonPacks',
    defaultMessage: 'Common Packs',
  },
  competitions: {
    id: 'Nav.competitions',
    defaultMessage: 'Competitions',
  },
  contact: {
    id: 'Nav.contact',
    defaultMessage: 'Contact',
  },
  contentCreatorTool: {
    id: 'Nav.contentCreatorTool',
    defaultMessage: 'Content Creator Tool',
  },
  cookiePolicy: {
    id: 'Nav.cookiePolicy',
    defaultMessage: 'Cookie Policy',
  },
  coverage: {
    id: 'Nav.coverage',
    defaultMessage: 'Coverage',
  },
  fixtures: {
    id: 'Seo.Player.Header.Fixtures',
    defaultMessage: 'Fixtures',
  },
  footballFantasy: {
    id: 'Footer.footballFantasy',
    defaultMessage: 'Sorare Football Fantasy',
  },
  footballMarket: {
    id: 'Footer.footballMarket',
    defaultMessage: 'Sorare Football Market',
  },
  friendlies: {
    id: 'Nav.rivals.friendlies',
    defaultMessage: 'Friendlies',
  },
  gallery: {
    id: 'Nav.gallery',
    defaultMessage: 'Gallery',
  },
  gameRules: {
    id: 'Nav.gameRules',
    defaultMessage: 'Game Rules',
  },
  gamingArena: {
    id: 'Nav.gamingArena',
    defaultMessage: 'Play',
  },
  gamingCompetitions: {
    id: 'Nav.gamingCompetitions',
    defaultMessage: 'Competitions',
  },
  hallOfFame: {
    id: 'Nav.hallOfFame',
    defaultMessage: 'Hall of Fame',
  },
  help: { id: 'Nav.help', defaultMessage: 'Help' },
  helpCenter: { id: 'Nav.helpCenter', defaultMessage: 'Help Center' },
  history: {
    id: 'Nav.rivals.history',
    defaultMessage: 'History',
  },
  home: {
    id: 'Nav.Home',
    defaultMessage: 'Home',
  },
  inviteFriends: {
    id: 'Nav.referralProgram',
    defaultMessage: 'Invite Friends',
  },
  leagues: {
    id: 'Nav.leagues',
    defaultMessage: 'Leagues',
  },
  legalNotice: {
    id: 'Nav.legalNotice',
    defaultMessage: 'Legal Notice',
  },
  licensedClubs: {
    id: 'Nav.licensedClubs',
    defaultMessage: 'Licensed Clubs',
  },
  licensedPartners: {
    id: 'Nav.licensedPartners',
    defaultMessage: 'Licensed Partners',
  },
  lineups: {
    id: 'Nav.rivals.lineups',
    defaultMessage: 'Lineups',
  },
  lineupsWithNumber: {
    id: 'Nav.play.LineupsWithNumber',
    defaultMessage: 'Lineups ({nb, number})',
  },
  live: {
    id: 'Nav.live',
    defaultMessage: 'Live',
  },
  liveFeed: {
    id: 'Nav.rivals.liveFeed',
    defaultMessage: 'Live Feed',
  },
  lobby: {
    id: 'Nav.lobby',
    defaultMessage: 'Lobby',
  },
  managerAssistant: {
    id: 'Nav.managerAssistant',
    defaultMessage: 'Manager Assistant',
  },
  market: {
    id: 'Nav.market',
    defaultMessage: 'Market',
  },
  marketplaceTerms: {
    id: 'Nav.marketplaceTerms',
    defaultMessage: 'Marketplace Terms',
  },
  matches: {
    id: 'Nav.rivals.matches',
    defaultMessage: 'Matches',
  },
  matchup: {
    id: 'Nav.rivals.matchup',
    defaultMessage: 'Matchup',
  },
  members: {
    id: 'Nav.members',
    defaultMessage: 'Members',
  },
  missions: {
    id: 'Nav.missions',
    defaultMessage: 'Missions',
  },
  mlbFantasy: {
    id: 'Footer.mlbFantasy',
    defaultMessage: 'Sorare MLB Fantasy',
  },
  mlbMarket: {
    id: 'Footer.mlbMarket',
    defaultMessage: 'Sorare MLB Market',
  },
  mlbSignUp: {
    id: 'Nav.mlbSignUp',
    defaultMessage: 'MLB: Sign up',
  },
  mobileDebugScreen: {
    id: 'Nav.debugScreen',
    defaultMessage: 'Debug Screen',
  },
  myAuctions: {
    id: 'Nav.myAuctions',
    defaultMessage: 'My Auctions',
  },
  myCards: {
    id: 'Nav.myCards',
    defaultMessage: 'My Cards',
  },
  myClub: {
    id: 'Nav.myClub',
    defaultMessage: 'My Club',
  },
  myFollows: {
    id: 'Nav.myFollows',
    defaultMessage: 'Follows',
  },
  myGallery: {
    id: 'Nav.myGallery',
    defaultMessage: 'My Cards',
  },
  myInstantBuys: {
    id: 'Nav.myInstantBuys',
    defaultMessage: 'My Instant Buys',
  },
  myLineups: {
    id: 'Nav.rivals.myLineups',
    defaultMessage: 'My Lineups',
  },
  myLineupsWithNumber: {
    id: 'Nav.rivals.myLineupsWithNumber',
    defaultMessage: 'My Lineups ({nb, number})',
  },
  myListings: {
    id: 'Nav.myListings',
    defaultMessage: 'My Listings',
  },
  myMarketActivity: {
    id: 'Nav.myMarketActivity',
    defaultMessage: 'My Market Activity',
  },
  myMatches: {
    id: 'Nav.myMatches',
    defaultMessage: 'My Matches',
  },
  myOffersReceived: {
    id: 'Nav.myOffersReceived',
    defaultMessage: 'Offers Received',
  },
  myOffersSent: {
    id: 'Nav.myOffersSent',
    defaultMessage: 'Offers Sent',
  },
  myPlayerNotifications: {
    id: 'Nav.myPlayerNotifications',
    defaultMessage: 'Player Notifications',
  },
  myProLineups: {
    id: 'Nav.myProLineups',
    defaultMessage: 'My Pro Lineups',
  },
  myPurchases: {
    id: 'Nav.myPurchases',
    defaultMessage: 'Manager Sales',
  },
  myRivals: {
    id: 'Nav.myRivals',
    defaultMessage: 'My Rivals',
  },
  mySorare: {
    id: 'Nav.mySorare',
    defaultMessage: 'My Sorare',
  },
  mySquad: {
    id: 'Nav.mySquad',
    defaultMessage: 'My Squad',
  },
  myTeam: {
    id: 'Nav.rivals.myTeam',
    defaultMessage: 'My Team',
  },
  myTransactions: {
    id: 'Nav.myTransactions',
    defaultMessage: 'Transactions',
  },
  nbaFantasy: {
    id: 'Footer.nbaFantasy',
    defaultMessage: 'Sorare NBA Fantasy',
  },
  nbaMarket: {
    id: 'Footer.nbaMarket',
    defaultMessage: 'Sorare NBA Market',
  },
  new: {
    id: 'Nav.new',
    defaultMessage: "What's New",
  },
  overview: {
    id: 'Seo.Player.Header.Overview',
    defaultMessage: 'Overview',
  },
  play: {
    id: 'Nav.play',
    defaultMessage: 'Play',
  },
  playWithFriends: {
    id: 'Nav.rivals.matchups',
    defaultMessage: 'Matchups',
  },
  players: {
    id: 'CollectionNavigationSwitch.players',
    defaultMessage: 'Players',
  },
  press: {
    id: 'Nav.press',
    defaultMessage: 'Press',
  },
  privacyPolicy: {
    id: 'Nav.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  privateLeague: {
    id: 'Nav.privateLeague',
    defaultMessage: 'Private League',
  },
  privateLeagues: {
    id: 'Nav.privateLeagues',
    defaultMessage: 'Private Leagues',
  },
  prizePool: {
    id: 'navLabels.prizePool',
    defaultMessage: 'Prize Pool',
  },
  profile: {
    id: 'Nav.profile',
    defaultMessage: 'Profile',
  },
  purchases: {
    id: 'Nav.purchases',
    defaultMessage: 'Manager Sales',
  },
  requests: {
    id: 'Nav.rivals.requests',
    defaultMessage: 'Requests',
  },
  requestsWithNumber: {
    id: 'Nav.rivals.requestsWithNumber',
    defaultMessage: 'Requests ({nb, number})',
  },
  rivalries: {
    id: 'Nav.rivalries',
    defaultMessage: 'Rivalries',
  },
  rivals: {
    id: 'Nav.rivals',
    defaultMessage: 'Rivals',
  },
  schedule: {
    id: 'Nav.play.schedule',
    defaultMessage: 'Schedule',
  },
  scores: {
    id: 'Nav.scores',
    defaultMessage: 'Scores',
  },
  settings: {
    id: 'Nav.settings',
    defaultMessage: 'Settings',
  },
  standings: {
    id: 'Seo.Player.Header.Standings',
    defaultMessage: 'Standings',
  },
  team: {
    id: 'Seo.Player.Header.Squad',
    defaultMessage: 'Players',
  },
  terms: {
    id: 'Nav.terms',
    defaultMessage: 'Terms & Conditions',
  },
  termsAndPrivacy: {
    id: 'Nav.termsAndPrivacy',
    defaultMessage: 'Terms & Privacy',
  },
  transferMarket: {
    id: 'navLabels.market',
    defaultMessage: 'Market',
  },
  trending: {
    id: 'navLabels.trending',
    defaultMessage: 'Trending',
  },
  userClub: {
    id: 'Nav.userClub',
    defaultMessage: "{username}'s club",
  },
  vault: {
    id: 'Nav.vault',
    defaultMessage: 'Vault',
  },
});

export const galleryTabs = defineMessages({
  awards: {
    id: 'Gallery.Data.awards',
    defaultMessage: 'Achievements',
  },
  cardCollections: {
    id: 'Gallery.Data.cardCollections',
    defaultMessage: 'Collections',
  },
  cards: {
    id: 'Gallery.Data.cards',
    defaultMessage: 'Cards',
  },
  clubHonors: {
    id: 'Gallery.Data.clubHonors',
    defaultMessage: 'Honors',
  },
  customDecks: {
    id: 'Gallery.Data.customDecks',
    defaultMessage: 'Squads',
  },
  network: {
    id: 'Gallery.Data.network',
    defaultMessage: 'Managers',
  },
  overview: {
    id: 'Gallery.Data.overview',
    defaultMessage: 'Overview',
  },
});

export const userAttributes = defineMessages({
  clubName: {
    id: 'Global.user.clubName',
    defaultMessage: 'Club name',
  },
  currency: {
    id: 'Global.user.currency',
    defaultMessage: 'Conversion',
  },
  email: {
    id: 'Global.user.email',
    defaultMessage: 'Email',
  },
  fiatCurrency: {
    id: 'Global.user.fiatCurrency',
    defaultMessage: 'Currency',
  },
  nickname: {
    id: 'Users.nickname',
    defaultMessage: 'Username',
  },
  otpDisableAttempts: {
    id: 'Global.user.otpDisableAttempt',
    defaultMessage: 'Six-digit code OR recovery code',
  },
  password: {
    id: 'Users.password',
    defaultMessage: 'Password',
  },
  phoneNumber: {
    id: 'Global.user.phoneNumber',
    defaultMessage: 'Phone number',
  },
  status: {
    id: 'Global.user.status',
    defaultMessage: 'Status',
  },
});

export const fantasy = defineMessages({
  addTeam: {
    id: 'AddTeam.newTeam',
    defaultMessage: 'Add Team',
  },
  addToList: {
    id: 'AddCardToDeck.addToList',
    defaultMessage: 'Add to list',
  },
  allAroundScore: {
    id: 'DetailedScoresV4.allAroundScore',
    defaultMessage: 'All around score',
  },
  alreadyJoined: {
    id: 'LeaguePicker.alreadyJoined',
    defaultMessage: 'Already joined',
  },
  away: {
    id: 'Fantasy.away',
    defaultMessage: 'Away',
  },
  bonus: {
    id: 'Fantasy.bonus',
    defaultMessage: 'Bonus',
  },
  bonuses: {
    id: 'Fantasy.bonuses',
    defaultMessage: 'Bonuses',
  },
  boost: {
    id: 'CardPage.Level.Boosts',
    defaultMessage: 'Boost',
  },
  boxOdds: {
    id: 'BoxOddsDialog.boxOdds',
    defaultMessage: 'Box Odds',
  },
  boxes: {
    id: 'Fantasy.boxes',
    defaultMessage: 'Boxes',
  },
  captain: {
    id: 'Fantasy.captain',
    defaultMessage: 'Captain',
  },
  captainDescription: {
    id: 'composeTeam.Field.captain.description',
    defaultMessage:
      'The player you select as captain will get a {bonus, number, percent} bonus to their score.',
  },
  cardBonus: {
    id: 'Rules.getCardBonusRule.title',
    defaultMessage: 'Card Bonus',
  },
  challenge: {
    id: 'rivals.Friend.Status.challenge',
    defaultMessage: 'Challenge',
  },
  composeLineup: {
    id: 'Draft.ComposeOnboarding.title',
    defaultMessage: 'Compose your lineup',
  },
  computed: {
    id: 'so5.waiting',
    defaultMessage: 'Waiting',
  },
  confirmDelete: {
    id: 'Lobby.Upcoming.ConfirmDelete',
    defaultMessage: 'Are you sure you want to delete your team?',
  },
  confirmYourLineup: {
    id: 'Fantasy.confirmLineup',
    defaultMessage: 'Confirm your lineup',
  },
  credits: {
    id: 'Settings.conversionCredit.title',
    defaultMessage: 'Credits',
  },
  cumulativeLeaderboard: {
    id: 'Fantasy.cumulativeLeaderboard',
    defaultMessage: 'Cumulative leaderboard',
  },
  decisiveScore: {
    id: 'DetailedScoresV4.decisiveScore',
    defaultMessage: 'Decisive score',
  },
  defeat: {
    id: 'Fantasy.defeat',
    defaultMessage: 'Defeat',
  },
  discoverCompetitions: {
    id: 'play.pro.fixture.enter.discoverCompetitions',
    defaultMessage: 'Discover Competitions',
  },
  discoverNewCompetitions: {
    id: 'play.pro.fixture.enter.discoverNewCompetitions',
    defaultMessage: 'Discover new competitions',
  },
  division: {
    id: 'Leaderboard.DivisionSwitcher.divDivision',
    defaultMessage: 'Div. {division}',
  },
  draft: { id: 'Fantasy.Draft', defaultMessage: 'Draft' },
  draw: {
    id: 'GameOutcome.draw',
    defaultMessage: 'Draw',
  },
  eligibleCards: {
    id: 'league.SeasonalCard.eligibleCards',
    defaultMessage: 'Eligible cards',
  },
  ended: {
    id: 'TimeLeft.ended',
    defaultMessage: 'Ended',
  },
  entered: {
    id: 'campaigns.NextAction.entered',
    defaultMessage: 'Entered',
  },
  essenceShort: {
    id: 'Fantasy.essenceShort',
    defaultMessage: 'Essence',
  },
  essencesShort: {
    id: 'Fantasy.essencesShort',
    defaultMessage: 'Essences',
  },
  extraDraft: { id: 'Fantasy.ExtraDraft', defaultMessage: 'Extra Draft' },
  final: { id: 'GameInfos.final', defaultMessage: 'Final' },
  freeToPlay: {
    id: 'Fantasy.FreeToPlay',
    defaultMessage: 'Free-to-play',
  },
  fullTime: { id: 'GameInfos.fulltime', defaultMessage: 'FT' },
  game: {
    id: 'CompetitionDetails.LineupStats.Game',
    defaultMessage: 'Game',
  },
  gameCenter: {
    id: 'fantasy.gameCenter',
    defaultMessage: 'Game Center',
  },
  gameMode: {
    id: 'Fantasy.gameMode',
    defaultMessage: 'Game mode',
  },
  gameWeek: {
    id: 'Fantasy.gameWeek',
    defaultMessage: 'Game Week {gameWeek}',
  },
  gameplay: { id: 'Fantasy.Gameplay', defaultMessage: 'Gameplay' },
  gw: {
    id: 'Fantasy.gameweekShort',
    defaultMessage: 'GW {week}',
  },
  hideTeam: {
    id: 'lineup.LineupsButtons.hideTeam',
    defaultMessage: 'Hide Team',
  },
  home: {
    id: 'Fantasy.home',
    defaultMessage: 'Home',
  },
  inArena: {
    id: 'Fantasy.inArena',
    defaultMessage: 'In Arena',
  },
  lastScores: {
    id: 'PlayerLastScores.title',
    defaultMessage: 'Last scores',
  },
  leaderboard: {
    id: 'Fantasy.leaderboard',
    defaultMessage: 'Leaderboard',
  },
  lineups: {
    id: 'Fantasy.Lineups',
    defaultMessage: 'Lineups',
  },
  lineupsCount: {
    id: 'Fantasy.LineupsCount',
    defaultMessage: '{lineupsCount, plural, one {1 Lineup} other {# Lineups}}',
  },
  lineupsRelativeCount: {
    id: 'Fantasy.LineupsRelativeCount',
    defaultMessage:
      '{lineupsCount}/{teamsCap, plural, one {1 Lineup} other {# Lineups}}',
  },
  listedCard: {
    id: 'leaderboard.card.listedCard',
    defaultMessage: 'Listed on Market',
  },
  live: {
    id: 'so5.live',
    defaultMessage: 'Live',
  },
  loss: {
    id: 'GameOutcome.loss',
    defaultMessage: 'Loss',
  },
  matchCenter: {
    id: 'fantasy.matchCenter',
    defaultMessage: 'Match Center',
  },
  nameLeaderboard: {
    id: 'fantasy.nameLeaderboard',
    defaultMessage: '{displayName} Leaderboard',
  },
  notEntered: {
    id: 'campaigns.NextAction.notEntered',
    defaultMessage: 'Not Entered',
  },
  numberOfmatches: {
    id: 'fantasy.numberOfMatches',
    defaultMessage: '{nbMatches, plural, one {# Match} other {# Matches}}',
  },
  objectives: {
    id: 'WarmupObjectives.title',
    defaultMessage: 'Objectives',
  },
  ordinalPoints: {
    id: 'Fantasy.ordinalPointsWithoutFormat',
    defaultMessage: '{score}<sup>pts</sup>',
  },
  participants: {
    id: 'Leaderboard.LineupsCount',
    defaultMessage:
      '{lineupsCount, plural, one {# participant} other {# participants}}',
  },
  past: {
    id: 'so5.past',
    defaultMessage: 'Past',
  },
  pastCompetitions: {
    id: 'Fantasy.pastCompetitions',
    defaultMessage: 'Past Competitions',
  },
  percentagePlayed: {
    id: 'Seo.Player.Infos.percentagePlayed',
    defaultMessage: '{percent, number, percent} played',
  },
  pickName: {
    id: 'CardSwap.CardSelection.title',
    defaultMessage: 'Pick {name}',
  },
  playerPerformance: {
    id: 'LastGamesPerformanceDialog.title',
    defaultMessage: 'Player performance',
  },
  playerScore: {
    id: 'Fantasy.playerScore',
    defaultMessage: 'Player Score',
  },
  playersPermittedFrom: {
    id: 'Fantasy.playersPermittedFrom',
    defaultMessage: 'Players permitted from',
  },
  podiumRank: {
    id: 'Lobby.CompetitionDetails.Rewards.Podium.rank',
    defaultMessage:
      '{rank, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} Place',
  },
  points: {
    id: 'Lobby.CompetitionDetails.Leaderboard.Score',
    defaultMessage: '{score, number, ::.00 rounding-mode-down} pts',
  },
  pointsCap: {
    id: 'Fantasy.pointsCap',
    defaultMessage: '{value, number} points cap',
  },
  pointsWithStyledOneDecimalScore: {
    id: 'Fantasy.pointsWithStyledOneDecimalScore',
    defaultMessage: '<span>{score, number, ::.0 rounding-mode-down}</span> pts',
  },
  pointsWithStyledScore: {
    id: 'Fantasy.points',
    defaultMessage:
      '<span>{score, number, ::.00 rounding-mode-down}</span> pts',
  },
  pointsWithStyledScoreWithoutFormat: {
    id: 'Fantasy.WithStyledScoreWithoutFormat',
    defaultMessage: '<span>{score}</span> pts',
  },
  pointsWithoutFormat: {
    id: 'Fantasy.pointsWithoutFormat',
    defaultMessage: '{score} pts',
  },
  prizePool: {
    id: 'CompetitionDetails.TabsItem.rewards',
    defaultMessage: 'Prize pool',
  },
  prizes: {
    id: 'CompetitionDetails.Prizes',
    defaultMessage: 'Prizes',
  },
  proHistory: {
    id: 'Fantasy.proHistory',
    defaultMessage: 'Pro History',
  },
  projectedRewards: {
    id: 'CompetitionDetails.Rewards.projectedRewards',
    defaultMessage: 'Projected Rewards',
  },
  promotion: {
    id: 'LeaderboardOverview.promotion',
    defaultMessage: 'Promotion',
  },
  rank: {
    id: 'Fantasy.rank',
    defaultMessage: 'Rank',
  },
  ranked: {
    id: 'Fantasy.ranked',
    defaultMessage: 'Ranked',
  },
  reachTargetPoints: {
    id: 'composeTeam.Thresholds.target',
    defaultMessage: 'Reach {target} points',
  },
  redraft: { id: 'Fantasy.Redraft', defaultMessage: 'Redraft' },
  referralReward: {
    id: 'ClaimReferralReward.title',
    defaultMessage: 'Referral Reward',
  },
  registerLineup: {
    id: 'Fantasy.RegisterLineup',
    defaultMessage: 'Register Lineup',
  },
  relegation: {
    id: 'LeaderboardOverview.relegation',
    defaultMessage: 'Relegation',
  },
  removeCard: {
    id: 'leaderboard.card.removeCard',
    defaultMessage: 'Playing this card would remove it from the Market',
  },
  revealCards: {
    id: 'Fantasy.revealCards',
    defaultMessage: 'Reveal Cards',
  },
  rewardsPerRank: {
    id: 'LazyRewards.rewardsPerRank',
    defaultMessage: 'Rewards per rank',
  },
  rewardsPerTarget: {
    id: 'LazyRewards.rewardsPerTarget',
    defaultMessage: 'Rewards per target',
  },
  rewardsPerThreshold: {
    id: 'LazyRewards.rewardsPerThreshold',
    defaultMessage: 'Rewards per threshold',
  },
  rules: {
    id: 'Fantasy.rules',
    defaultMessage: 'Rules',
  },
  safeZone: {
    id: 'Fantasy.safeZone',
    defaultMessage: 'Safe zone',
  },
  scarcities: {
    id: 'BenchFilter.scarcities',
    defaultMessage: 'Scarcities',
  },
  score: {
    id: 'Fantasy.score',
    defaultMessage: 'Score',
  },
  scoutPopularPlayer: {
    id: 'BasicBench.scoutPlayer',
    defaultMessage: 'Scout player',
  },
  scoutPopularPosition: {
    id: 'BasicBench.scoutPosition',
    defaultMessage: 'Scout {position}',
  },
  selectYourPlayer: {
    id: 'composeTeam.Field.SelectYourPlayer',
    defaultMessage: 'Select your player',
  },
  selectYourPosition: {
    id: 'composeTeam.Field.SelectYourPosition',
    defaultMessage: 'Select your {position}',
  },
  setLineup: {
    id: 'lineup.LineupsButtons.setLineups',
    defaultMessage: 'Set Lineup',
  },
  shortCumulativeLeaderboard: {
    id: 'Fantasy.shortCumulativeLeaderboard',
    defaultMessage: 'Cumulative',
  },
  swap: { id: 'Fantasy.Swap', defaultMessage: 'Swap' },
  target: {
    id: 'Fantasy.target',
    defaultMessage: 'Target',
  },
  targetNumber: {
    id: 'ThresholdRewards.target',
    defaultMessage: 'Target {targetNb}',
  },
  targetPoints: {
    id: 'fantasy.targetPoints',
    defaultMessage: '{target, number}+ points',
  },
  team: {
    id: 'Fantasy.team',
    defaultMessage: 'Team',
  },
  teamVersus: {
    id: 'Fantasy.teamVersus',
    defaultMessage: '{homeTeam} vs {awayTeam}',
  },
  total: {
    id: 'Fantasy.total',
    defaultMessage: 'Total',
  },
  trainingTeams: {
    id: 'Fantasy.trainingTeams',
    defaultMessage: 'Training Teams',
  },
  undoNumber: {
    id: 'Fantasy.undoNumber',
    defaultMessage: 'Undo <span>({value, number})</span>',
  },
  upcoming: {
    id: 'so5.upcoming',
    defaultMessage: 'Upcoming',
  },
  victory: {
    id: 'Fantasy.victory',
    defaultMessage: 'Victory',
  },
  viewLeaderboard: {
    id: 'rivals.Arena.RankProgression.viewLeaderboard',
    defaultMessage: 'View Leaderboard',
  },
  viewLineup: {
    id: 'lineup.LineupsButtons.viewLineup',
    defaultMessage: 'View Lineup',
  },
  viewPrizePool: {
    id: 'SeasonLeaderboardHeader.viewPrizePool',
    defaultMessage: 'View Prize Pool',
  },
  viewRewardsPool: {
    id: 'Fantasy.viewRewardsPool',
    defaultMessage: 'View Rewards Pool',
  },
  viewSchedule: {
    id: 'Fantasy.viewSchedule',
    defaultMessage: 'View Schedule',
  },
  vs: {
    id: 'Fantasy.vs',
    defaultMessage: 'vs',
  },
  win: {
    id: 'GameOutcome.win',
    defaultMessage: 'Win',
  },
  winningBy: {
    id: 'Rivals.ScoreDifference.WinningBy',
    defaultMessage: '<span>Winning {winningGames}</span> of {totalGames}',
  },
  wonBy: {
    id: 'Rivals.ScoreDifference.WonBy',
    defaultMessage: '<span>Won {winningGames}</span> of {totalGames}',
  },
  yourTeam: {
    id: 'Fantasy.yourTeam',
    defaultMessage: 'Your team',
  },
});

export const cardAttributes = defineMessages({
  L10: {
    id: 'CardSuggestion.averageScore.l10Short',
    defaultMessage: 'Last 10 Games',
  },
  L10Score: {
    id: 'AverageScore.lastTenPlayedSo5AverageScore',
    defaultMessage: 'Average score over the last 10 played games',
  },
  L15: {
    id: 'CardSuggestion.averageScore.l15Short',
    defaultMessage: 'Last 15 Games',
  },
  L15Score: {
    id: 'AverageScore.lastFifteenSo5AverageScore',
    defaultMessage: 'Average score over the last 15 games',
  },
  L5Score: {
    id: 'AverageScore.lastFiveAverageScore',
    defaultMessage: 'Average score over the last 5 games',
  },
  age: { id: 'Cards.age', defaultMessage: 'Age' },
  averageScore: { id: 'Cards.averageScore', defaultMessage: 'Average score' },
  blockchainID: { id: 'Cards.blockchainID', defaultMessage: 'Blockchain ID' },
  bonus: { id: 'Cards.bonus', defaultMessage: 'Bonus' },
  cardTeam: { id: 'RefineList.cardTeam', defaultMessage: 'Card Team' },
  club: { id: 'Cards.club', defaultMessage: 'Club' },
  collectibles: {
    id: 'glossary.title',
    defaultMessage: 'Collectibles',
  },
  country: { id: 'Cards.country', defaultMessage: 'Country' },
  eligibility: {
    id: 'Glossary.CardAttributes.eligibility',
    defaultMessage: 'Eligibility',
  },
  height: { id: 'Glossary.CardAttributes.height', defaultMessage: 'Height' },
  jerseyNumber: {
    id: 'Glossary.CardAttributes.jerseyNumber',
    defaultMessage: 'Jersey Number',
  },
  last10: {
    id: 'Nba.Seo.Players.Slug.Overview.last10',
    defaultMessage: 'Last 10',
  },
  last15: {
    id: 'Glossary.CardAttributes.last15',
    defaultMessage: 'Last 15',
  },
  last5: {
    id: 'Glossary.CardAttributes.last5',
    defaultMessage: 'Last 5',
  },
  level: { id: 'Cards.level', defaultMessage: 'Level' },
  player: { id: 'RefineList.player', defaultMessage: 'Player' },
  position: { id: 'RefineList.position', defaultMessage: 'Position' },
  scarcity: { id: 'Cards.scarcity', defaultMessage: 'Scarcity' },
  season: { id: 'Cards.season', defaultMessage: 'Season' },
  seasonAverageScore: {
    id: 'CardProperties.averageScore.season',
    defaultMessage: 'Season average score',
  },
  serialNumber: { id: 'Cards.serialNumber', defaultMessage: 'Serial Number' },
  serie: { id: 'Cards.serial', defaultMessage: 'Serial' },
  shortLevel: { id: 'Cards.shortLevel', defaultMessage: 'lvl' },
  team: { id: 'RefineList.team', defaultMessage: 'Team' },
  upcomingGame: {
    id: 'Cards.upcomingGame',
    defaultMessage: 'This player has an upcoming game.',
  },
  xpTitle: {
    id: 'Cards.xpTitle',
    defaultMessage: 'Total XP',
  },
  xpValue: {
    id: 'Cards.xpValue',
    defaultMessage: '{xp} XP',
  },
});

export const draftLabels = defineMessages({
  redraft: {
    id: 'Draft.Action.Redraft',
    defaultMessage: 'Redraft Squad',
  },
  selectYourPlayers: {
    id: 'Draft.Title.SelectPlayer',
    defaultMessage: 'Select your players',
  },
  substitute: {
    id: 'Draft.Action.selectPlayerToSubIn',
    defaultMessage: 'Select a player to sub in',
  },
});

export const filters = defineMessages({
  activeClub: {
    id: 'RefineList.activeClub',
    defaultMessage: 'Current Club',
  },
  activeNationalTeam: {
    id: 'RefineList.activeNationalTeam',
    defaultMessage: 'Current National Team',
  },
  activeTeam: {
    id: 'RefineList.activeTeam',
    defaultMessage: 'Current Team',
  },
  appearances: {
    id: 'RangeSlider.appearances',
    defaultMessage: 'Games Played',
  },
  averageScore: {
    id: 'RangeSlider.averageScore',
    defaultMessage: 'Average Score',
  },
  birthplace: {
    id: 'RefineList.birthplace',
    defaultMessage: 'Birthplace',
  },
  bundledSale: {
    id: 'RefineList.bundledSale',
    defaultMessage: 'Sale type',
  },
  cardEdition: {
    id: 'RefineList.cardEdition',
    defaultMessage: 'Card edition',
  },
  cardLevel: {
    id: 'RangeSlider',
    defaultMessage: 'Card Level',
  },
  clearAll: {
    id: 'ClearAllFilters.cta',
    defaultMessage: 'Clear all filters',
  },
  collectibleFilterTitle: {
    id: 'CollectibleFilter.collectible',
    defaultMessage: 'Collectible',
  },
  competitionEligibilityFilterTitle: {
    id: 'LeagueFilter.competitionEligibility',
    defaultMessage: 'League & Competition',
  },
  customDeck: {
    id: 'RefineList.customDeck',
    defaultMessage: 'Squad',
  },
  customList: {
    id: 'RefineList.customList',
    defaultMessage: 'List',
  },
  dailyFilterLabel: {
    id: 'DailyFilter.label',
    defaultMessage: 'Playing Game Day',
  },
  dailyFilterShortLabel: {
    id: 'DailyFilter.shortLabel',
    defaultMessage: 'Schedule',
  },
  favoriteFilterLabel: {
    id: 'FavoriteFilter.label',
    defaultMessage: 'Favorites',
  },
  filters: {
    id: 'Activity.filterDialogTitle',
    defaultMessage: 'Filters',
  },
  gameweekFilterLabel: {
    id: 'gameweekFilter.label',
    defaultMessage: 'Playing Game Week',
  },
  jerseySerialLabel: {
    id: 'JerseySerialFilter.label',
    defaultMessage: 'Matching player jersey number',
  },
  lastFifteenAppearances: {
    id: 'RangeSlider.lastFifteenAppearences',
    defaultMessage: 'Last 15 Appearances',
  },
  lastFifteenAverageScore: {
    id: 'RangeSlider.lastFifteenAverageScore',
    defaultMessage: 'Last 15 Average Score',
  },
  lastFiveAppearances: {
    id: 'RangeSlider.lastFiveAppearances',
    defaultMessage: 'Last 5 Appearances',
  },
  lastFiveAverageScore: {
    id: 'RangeSlider.lastFiveAverageScore',
    defaultMessage: 'Last 5 Average Score',
  },
  latestSeasonLabel: {
    id: 'RefineLatestSeason.label',
    defaultMessage: 'Latest Season',
  },
  legendFilterLabel: {
    id: 'CollectibleFilter.legendFilterLabel',
    defaultMessage: 'Show Legend Cards',
  },
  mlbEligibleLeaderboards: {
    id: 'RefineList.eligible_leaderboards',
    defaultMessage: 'Eligible Leaderboards',
  },
  mlbLastFifteenAverageScore: {
    id: 'RefineLastFifteenAverageScore.last_fifteen_average_score',
    defaultMessage: 'Last 15 average score',
  },
  mlbSeasonAverageScore: {
    id: 'RefineSeasonAverageScore.season_average_score',
    defaultMessage: 'Season average score',
  },
  myAuctionsFilterLabel: {
    id: 'RefineMyAuctions.label',
    defaultMessage: 'My auctions',
  },
  nationality: {
    id: 'RefineList.nationality',
    defaultMessage: 'Nationality',
  },
  nbaShortTenGameAverageScore: {
    id: 'RefineTenGameAverageScore.short.ten_game_average_score',
    defaultMessage: 'L10 Avg.',
  },
  nbaTenGameAverageScore: {
    id: 'RefineTenGameAverageScore.ten_game_average_score',
    defaultMessage: 'Ten Game Average',
  },
  noCardsFound: {
    id: 'EmptyGallery.noCardFound',
    defaultMessage: 'No Cards found',
  },
  noCardsOfTopicFound: {
    id: 'EmptyGallery.noCardsOfTopicFound',
    defaultMessage: 'No Cards of {topic} found',
  },
  nonPlayableCardsFilterLabel: {
    id: 'CollectibleFilter.showCustomSeriesCards',
    defaultMessage: 'Show Custom Series Cards',
  },
  notInLineupFilterLabel: {
    id: 'NotInLineupFilter.label',
    defaultMessage: 'Not in lineup',
  },
  onSaleFilterLabel: {
    id: 'RefineOnSale.label',
    defaultMessage: 'Cards on sale',
  },
  playingStatusFilterLabel: {
    id: 'PlayingStatusFilter.label',
    defaultMessage: 'Playing status',
  },
  price: {
    id: 'RangeSlider.price',
    defaultMessage: 'Price',
  },
  probablePitchersFilterLabel: {
    id: 'probablePitchersFilter.label',
    defaultMessage: 'Probable Pitchers',
  },
  promotedCardsFilterTitle: {
    id: 'PromotedCards.title',
    defaultMessage: 'Special Event',
  },
  promotionalEvent: {
    id: 'RefineList.promotionalEvent',
    defaultMessage: 'Promotional event',
  },
  saleType: {
    id: 'RefineList.saleType',
    defaultMessage: 'Sale type',
  },
  settlementWallets: {
    id: 'RefineList.settlementWallets',
    defaultMessage: 'Purchase options',
  },
  showDetailsLabel: {
    id: 'ShowDetails.label',
    defaultMessage: 'Show details',
  },
  showTrainingLineups: {
    id: 'ShowTrainingLineups.label',
    defaultMessage: 'Show training lineups',
  },
  sortBy: {
    id: 'FiltersManagerDialog.sortBy',
    defaultMessage: 'Sort by',
  },
  startedOnlyFilterLabel: {
    id: 'StartedOnlyFilter.label',
    defaultMessage: 'Not empty',
  },
  tournament: {
    id: 'RefineList.tournament',
    defaultMessage: 'Tournament',
  },
});

/* eslint-disable sort-keys */
export const sorts = defineMessages<
  AlgoliaCardIndexesName | PrintableI18nPlayerSortName
>({
  'Best Value': {
    id: 'SortCard.bestValue',
    defaultMessage: 'Most Relevant',
  },
  'Cards Highest Average Score': {
    id: 'SortCard.highestAverageScore',
    defaultMessage: 'Highest Average Score',
  },
  'Cards Highest Price': {
    id: 'SortCard.highestPrice',
    defaultMessage: 'Highest Price',
  },
  'Cards Lowest Price': {
    id: 'SortCard.lowestPrice',
    defaultMessage: 'Lowest Price',
  },
  'Cards New': {
    id: 'SortCards.new',
    defaultMessage: 'New',
  },
  'Cards On Sale Best Value': {
    id: 'SortCard.bestValue',
    defaultMessage: 'Most Relevant',
  },
  'Cards On Sale Ending Soon': {
    id: 'SortCard.endingSoon',
    defaultMessage: 'Ending Soon',
  },
  'Cards On Sale Highest Average Score': {
    id: 'SortCard.highestAverageScore',
    defaultMessage: 'Highest Average Score',
  },
  'Cards On Sale Highest Price': {
    id: 'SortCard.highestPrice',
    defaultMessage: 'Highest Price',
  },
  'Cards On Sale Lowest Price': {
    id: 'SortCard.lowestPrice',
    defaultMessage: 'Lowest Price',
  },
  'Cards On Sale Newly Listed': {
    id: 'SortCard.newlyListed',
    defaultMessage: 'Newly listed',
  },
  'Cards On Sale Popular Player': {
    id: 'SortCard.popularPlayer',
    defaultMessage: 'Popular Player',
  },
  'Cards Player Name': {
    id: 'SortCard.playerName',
    defaultMessage: 'Player Name',
  },
  'Ending Soon': {
    id: 'SortCard.endingSoon',
    defaultMessage: 'Ending Soon',
  },
  'Highest Average Score': {
    id: 'SortCard.highestAverageScore',
    defaultMessage: 'Highest Average Score',
  },
  'Highest Price': {
    id: 'SortCard.highestPrice',
    defaultMessage: 'Highest Price',
  },
  'Lowest Price': {
    id: 'SortCard.lowestPrice',
    defaultMessage: 'Lowest Price',
  },
  'Newly Listed': {
    id: 'SortCard.newlyListed',
    defaultMessage: 'Newly listed',
  },
  'Popular Player': {
    id: 'SortCard.popularPlayer',
    defaultMessage: 'Popular Player',
  },
  'DESC-L10': {
    id: 'SortCard.last_ten_played_so5_average_score-DESC',
    defaultMessage: 'Highest Average Score',
  },
  'ASC-player_name': {
    id: 'SortCard.display_name-ASC',
    defaultMessage: 'Player Name (A-Z)',
  },
  'DESC-player_name': {
    id: 'SortCard.display_name-DESC',
    defaultMessage: 'Player Name (Z-A)',
  },
  'ASC-name_for_sorting': {
    id: 'SortCard.display_name-ASC',
    defaultMessage: 'Player Name (A-Z)',
  },
  'DESC-name_for_sorting': {
    id: 'SortCard.display_name-DESC',
    defaultMessage: 'Player Name (Z-A)',
  },
});
/* eslint-enable sort-keys */

export const playerUnavailability = defineMessages({
  active: {
    id: 'Status.player.active',
    defaultMessage: 'Active',
  },
  expectedReturnDate: {
    id: 'Card.unavailability.expectedReturnDate',
    defaultMessage: 'Expected return date: {date, date, long}',
  },
  gamesUnavailableCount: {
    id: 'Card.suspension.gamesUnavailable',
    defaultMessage:
      '({gamesCount, plural, one {1 {competition} game} other {# {competition} games}})',
  },
  inactive: {
    id: 'Status.player.inactive',
    defaultMessage: 'Inactive',
  },
  injuryTitle: {
    id: 'Draft.Card.injured',
    defaultMessage: 'Potential injury',
  },
  suspendedTitle: {
    id: 'Draft.Card.suspended',
    defaultMessage: 'Suspended',
  },
  unavailableSince: {
    id: 'Card.unavailabilty.unavailableSinceDate',
    defaultMessage: '(since {unavailableSince, date, long})',
  },
  unknownReturnDate: {
    id: 'Card.unavailability.unknownReturnDate',
    defaultMessage: 'Unknown return date',
  },
});

export const playerGameStatusLabels = defineMessages({
  did_not_play: {
    id: 'So5Stat.did_not_play',
    defaultMessage: 'Did not play',
  },
  did_not_play_short: {
    id: 'So5Stat.did_not_play_short',
    defaultMessage: 'DNP',
  },
  low_coverage: {
    id: 'So5Stat.low_coverage',
    defaultMessage: 'Low Coverage',
  },
  low_coverage_detail: {
    id: 'So5Stat.low_coverage_detail',
    defaultMessage:
      'Only Decisive Actions (eg goals, assists) will be scored for this game.',
  },
  no_game: {
    id: 'So5Stat.no_game',
    defaultMessage: 'No game (NG)',
  },
  no_game_short: {
    id: 'So5Stat.no_game_short',
    defaultMessage: 'NG',
  },
  not_covered: {
    id: 'So5Stat.not_covered',
    defaultMessage: 'Not Covered',
  },
  pending: {
    id: 'PlayerScore.pending',
    defaultMessage: 'Match not yet started',
  },
  reviewing: {
    id: 'PlayerGame.waitingForReview',
    defaultMessage: 'Score waiting for review',
  },
  uncertain_coverage: {
    id: 'So5Stat.uncertain_coverage',
    defaultMessage: 'Delayed Coverage',
  },
  uncertain_coverage_detail: {
    id: 'So5Stat.uncertain_coverage_detail',
    defaultMessage:
      'Full player data (All-Around scores + Decisive Scores) for this game will not be provided in real-time. It should update within 48 hours after full-time. However, if full player stats are not provided by our data partner before we finalise scoring at the end of the Game Week (targeted at 8pm CET at the latest), only decisive stats will be scored.',
  },
});

export const playerDetails = defineMessages({
  birthDateAndAge: {
    id: 'Seo.Player.Info.birthDateAndAge',
    defaultMessage: 'Date of birth (Age)',
  },
  drawn: {
    id: 'Seo.Club.Overview.drawn',
    defaultMessage: 'D',
  },
  gamesCount: {
    id: 'Lineup.player.gamesCount',
    defaultMessage: '{count, plural, one {# game} other {# games}}',
  },
  gamesLeftCount: {
    id: 'Lineup.player.gamesLeftCount',
    defaultMessage: '{count, plural, one {# game left} other {# games left}}',
  },
  goalsAgainst: {
    id: 'Seo.Club.Overview.goalsAgainst',
    defaultMessage: 'GA',
  },
  goalsDiff: {
    id: 'Seo.Club.Overview.goalsDiff',
    defaultMessage: 'GF',
  },
  goalsFor: {
    id: 'Seo.Club.Overview.goalsFor',
    defaultMessage: 'GF',
  },
  injuryReport: {
    id: 'InfoModal.injuryReport',
    defaultMessage: 'Injury report',
  },
  leagueRanking: {
    id: 'PlayerDetails.leagueRanking',
    defaultMessage: 'League ranking',
  },
  lost: {
    id: 'Seo.Club.Overview.lost',
    defaultMessage: 'L',
  },
  noGames: {
    id: 'CompetitionDetails.LineupStats.NoGames',
    defaultMessage: 'No games',
  },
  performance: {
    id: 'InfoModal.performance',
    defaultMessage: 'Performance',
  },
  played: {
    id: 'Seo.Club.Overview.played',
    defaultMessage: 'PL',
  },
  player_status: {
    id: 'InfoModal.playerStatus',
    defaultMessage: 'Player status',
  },
  points: {
    id: 'Seo.Club.Overview.points',
    defaultMessage: 'PTS',
  },
  upcomingMatches: {
    id: 'PlayerDetails.upcomingMatches',
    defaultMessage: 'Upcoming matches',
  },
  upcoming_games: {
    id: 'InfoModal.upcomingGames',
    defaultMessage: 'Upcoming games',
  },
  won: {
    id: 'Seo.Club.Overview.won',
    defaultMessage: 'W',
  },
});

export const mobileApp = defineMessages({
  disabledSale: {
    id: 'mobileApp.disabledSale',
    defaultMessage: 'Sell (disabled in app)',
  },
  disabledTrade: {
    id: 'mobileApp.disabledTrade',
    defaultMessage: 'Trade (disabled in app)',
  },
  openWebMarketplace: {
    id: 'mobileApp.openWebMarketplace',
    defaultMessage: 'Visit Market',
  },
  startTrading: {
    id: 'Offers.cannotTrade.mobileApp',
    defaultMessage: 'To start trading, visit Sorare on web',
  },
  unavailable: {
    id: 'mobileApp.unavailable',
    defaultMessage: 'Unavailable',
  },
});

export const wallet = defineMessages({
  addFunds: {
    id: 'bankEthAccounting.tabs.addFunds',
    defaultMessage: 'Add funds',
  },
  sixDigitsCode: {
    id: 'Global.user.sixDigitCode',
    defaultMessage: 'Six-digit code (Two-factor Authentication)',
  },
  walletIsLockedTitle: {
    id: 'NewWalletDrawer.walletIsLockedTitle',
    defaultMessage: 'Unlock your wallet',
  },
});

export const draft = defineMessages({
  autoComplete: {
    id: 'Draft.Picker.AutoComplete',
    defaultMessage: 'Auto Complete',
  },
  autofill: {
    id: 'Draft.Picker.Autofill',
    defaultMessage: 'Auto fill',
  },
  autofillPrompt: {
    id: 'Draft.Picker.AutofillPrompt',
    defaultMessage: 'Need help drafting?',
  },
  clear: {
    id: 'Draft.Picker.Clear',
    defaultMessage: 'Clear Team',
  },
  confirm: {
    id: 'Draft.Picker.Confirm',
    defaultMessage: 'Confirm draft',
  },
  footballL15Title: {
    id: 'football.lastFifteenAverageScore.score',
    defaultMessage: 'Last 15 game average score: {score}',
  },
  nbaTenGameAverageScoreTitle: {
    id: 'nba.tenGameAverage.score',
    defaultMessage: 'Ten game average score: {score}',
  },
  submit: {
    id: 'Draft.Picker.Submit',
    defaultMessage: 'Submit',
  },
});

export const payment = defineMessages({
  applePay: {
    id: 'Glossary.applePay',
    defaultMessage: 'Apple Pay',
  },
  balance: {
    id: 'PaymentBox.balance',
    defaultMessage: 'Balance',
  },
  confirmAndPay: {
    id: 'Glossary.paymentBox.confirmAndPay',
    defaultMessage: 'Confirm and Pay now',
  },
  conversionCreditDefinition: {
    id: 'ConversionCredits.tooltipV2',
    defaultMessage:
      'Credits apply to a % of a card purchase, up to the specified amount. Unused credits can be used in future card purchases. Credits with the same % value can be combined, resetting the expiration date.',
  },
  googlePay: {
    id: 'Glossary.googlePay',
    defaultMessage: 'Google Pay',
  },
  linkCo: {
    id: 'Glossary.LinkCo',
    defaultMessage: 'Link',
  },
  payment: {
    id: 'Glossary.payment',
    defaultMessage: 'Payment',
  },
  paymentBoxTitle: {
    id: 'Glossary.paymentBox.title',
    defaultMessage: 'Review & Pay',
  },
  peggingDescription: {
    id: 'Settings.receivedPaymentCurrencies.pegging.description',
    defaultMessage:
      'The price of the cards you list for sale will be tied to ETH.',
  },
  peggingTitle: {
    id: 'Settings.receivedPaymentCurrencies.pegging.title',
    defaultMessage: 'Peg card prices to ETH',
  },
  seller: {
    id: 'Glossary.seller',
    defaultMessage: 'Seller',
  },
  sorareCashWallet: {
    id: 'Glossary.sorareCashWallet',
    defaultMessage: 'Cash Wallet',
  },
  sorareEthWallet: {
    id: 'Glossary.sorareEthWallet',
    defaultMessage: 'ETH Wallet',
  },
  sorareWallet: {
    id: 'Glossary.sorareWallet',
    defaultMessage: 'Sorare Wallet',
  },
  wallet: {
    id: 'Glossary.wallet',
    defaultMessage: 'Wallet',
  },
});

export const transferMarket = defineMessages({
  bundle: {
    id: 'Bundle.bundle',
    defaultMessage: 'Bundle',
  },
  bundles: {
    id: 'TransferMarket.bundles',
    defaultMessage: 'Bundles',
  },
  buyMore: {
    id: 'buyField.buyMore',
    defaultMessage: 'Buy more',
  },
  buySellAndTradeCards: {
    id: 'TransferMarket.buySellAndTradeCards',
    defaultMessage: 'Buy, sell and trade cards',
  },
  favorites: {
    id: 'TransferMarket.favorites',
    defaultMessage: 'My Favorites',
  },
  instantBuy: {
    id: 'PrimaryBuyDetails.instantBuy',
    defaultMessage: 'Instant Buy',
  },
  new: {
    id: 'TransferMarket.primarySales',
    defaultMessage: 'New Card Auctions',
  },
  orderSummary: {
    id: 'buyField.tokenSummary.title',
    defaultMessage: 'Order Summary',
  },
  publicOffer: {
    id: 'PriceHistory.publicOffer',
    defaultMessage: 'Public offer',
  },
  singleCards: {
    id: 'TransferMarket.singleCards',
    defaultMessage: 'Single cards',
  },
  starterPacks: {
    id: 'TransferMarket.starterPacks',
    defaultMessage: 'Starter Packs',
  },
  transfer: {
    id: 'TransferMarket.userSales',
    defaultMessage: 'Manager Sales',
  },
  transferDescription: {
    id: 'TransferMarket.userSalesDescription',
    defaultMessage: 'Buy, sell, and trade cards with other Managers.',
  },
});

export const fiatWallet = defineMessages({
  activateCashWallet: {
    id: 'FiatWallet.activateCashWallet',
    defaultMessage: 'Activate my Cash Wallet',
  },
  activateCashWalletAndAddAnId: {
    id: 'FiatWallet.activateCashWalletAndAddAnId',
    defaultMessage: 'Activate Cash Wallet and add an ID',
  },
  addCash: { id: 'FiatWallet.addCash', defaultMessage: 'Add cash' },
  addMyId: {
    id: 'FiatWallet.addMyId',
    defaultMessage: 'Add my ID',
  },
});

export const rivals = defineMessages({
  arena: {
    id: 'rivals.mode.arena',
    defaultMessage: 'Arena',
  },
  arenaTicket: {
    id: 'rivals.arenaTicket',
    defaultMessage: 'Arena Ticket',
  },
  arenaTickets: {
    id: 'rivals.arenaTickets',
    defaultMessage: 'Arena Tickets',
  },
  casual: {
    id: 'rivals.mode.casual',
    defaultMessage: 'Casual',
  },
  challenge: {
    id: 'rivals.challenge',
    defaultMessage: 'Challenge',
  },
  challengeFriends: {
    id: 'rivals.upcoming.MyLineups.cta.challengeFriends',
    defaultMessage: 'Challenge Rivals',
  },
  challengedYou: {
    id: 'rivals.MatchupStatus.challengedYou',
    defaultMessage: 'Challenged You',
  },
  currentSeason: {
    id: 'rivals.seasons.current',
    defaultMessage: 'Current season',
  },
  division: {
    id: 'rivals.division',
    defaultMessage: 'Division',
  },
  divisionNumber: {
    id: 'rivals.divisionNumber',
    defaultMessage: 'Division {number}',
  },
  editLineup: {
    id: 'rivals.lineup.edit',
    defaultMessage: 'Edit Lineup',
  },
  editRole: {
    id: 'rivals.lineup.editRole',
    defaultMessage: 'Edit Role',
  },
  enterTheArena: {
    id: 'rivals.enterTheArena',
    defaultMessage: 'Enter the Arena',
  },
  featured: {
    id: 'Rivals.featuredGame',
    defaultMessage: 'Featured Game',
  },
  featuredGames: {
    id: 'Rivals.featuredGames',
    defaultMessage: 'Featured Games',
  },
  freeForAll: {
    id: 'Rivals.freeForAll',
    defaultMessage: 'Free for All',
  },
  friendly: {
    id: 'rivals.mode.friendly',
    defaultMessage: 'Friendly',
  },
  games: {
    id: 'rivals.games',
    defaultMessage: '{count} {count, plural, one {Game} other {Games}}',
  },
  inviteFriendsCta: {
    id: 'rivals.InviteFriends.cta',
    defaultMessage:
      '{nb, plural, =0 {Send challenge} one {# Invite} other {# Invites}}',
  },
  inviteFriendsSubtitle: {
    id: 'rivals.InviteFriends.message',
    defaultMessage:
      "Got football 'expert' friends? Get {nb, plural, one {# Arena ticket} other {# Arena tickets}} for each invited Rival.",
  },
  inviteFriendsSubtitleLocked: {
    id: 'rivals,InviteFriends.lockedMessage',
    defaultMessage: "Got football 'expert' friends?",
  },
  inviteFriendsTitle: {
    id: 'rivals.InviteFriends.title',
    defaultMessage: 'Who deserves to be a rival?',
  },
  joined: {
    id: 'rivals.joined',
    defaultMessage: 'Joined',
  },
  leaderboardPosition: {
    id: 'SeasonLeaderboard.tableHeader.position',
    defaultMessage: 'Position',
  },
  leaderboardPositionAbbreviated: {
    id: 'SeasonLeaderboard.tableHeader.abbreviatedPosition',
    defaultMessage: 'Pos',
  },
  lineupNotSet: {
    id: 'rivals.linetupNotSet',
    defaultMessage: 'Lineup not set',
  },
  lineupSet: {
    id: 'rivals.lineupSet',
    defaultMessage: 'Lineup Set',
  },
  losingBy: {
    id: 'Rivals.losingBy',
    defaultMessage: 'Losing by {score}',
  },
  loss: {
    id: 'Rivals.repStake.loss',
    defaultMessage: 'Loss',
  },
  lostBy: {
    id: 'Rivals.lostBy',
    defaultMessage: 'Lost by {score}',
  },
  matchedUp: {
    id: 'rivals.MatchupStatus.matchup',
    defaultMessage: 'Matched Up',
  },
  matchupsCount: {
    id: 'rivals.matchupsCount',
    defaultMessage: '{count, plural, one {1 Matchup} other {# Matchups}}',
  },
  notStarting: {
    id: 'StartingStatus.notStarting2',
    defaultMessage: 'Not Starting',
  },
  onBench: {
    id: 'StartingStatus.onBench',
    defaultMessage: 'On Bench',
  },
  onField: {
    id: 'StartingStatus.onField',
    defaultMessage: 'On Field',
  },
  opponentDeclined: {
    id: 'rivals.MatchupStatus.opponentDeclined',
    defaultMessage: 'Declined',
  },
  pastSeason: {
    id: 'rivals.seasons.past',
    defaultMessage: 'Past season',
  },
  record: {
    id: 'Rivals.Record',
    defaultMessage: '({win}-{loss})',
  },
  rep: {
    id: 'Rivals.rep',
    defaultMessage: 'Rep',
  },
  repBoost: {
    id: 'Rivals.repBoost',
    defaultMessage: 'Rep Boost',
  },
  repBoostMultiplier: {
    id: 'Rivals.repBoostMultiplier',
    defaultMessage: '{pointsDeltaWinMultiplier}x Boost',
  },
  repBoostWithPoints: {
    id: 'Rivals.repBoostWithPoints',
    defaultMessage: 'Rep Boost ({points})',
  },
  repStake: {
    id: 'Rivals.repStake',
    defaultMessage: '<b>{repPoint}</b> Rep',
  },
  rewardLabel: {
    id: 'SeasonLeaderboardHeader.reward',
    defaultMessage: 'Reward:',
  },
  rival: {
    id: 'rivals.rival',
    defaultMessage: 'Rival',
  },
  searchingForRival: {
    id: 'rivals.arena.searchingForRival',
    defaultMessage: 'Rival announced at kick-off',
  },
  selectPlayer: {
    id: 'rivals.draft.CaptainSelection.cta',
    defaultMessage: 'Select player',
  },
  sendNote: {
    id: 'Rivals.sendNote',
    defaultMessage: 'Send Note',
  },
  setLineup: {
    id: 'rivals.lineup.set',
    defaultMessage: 'Set My Lineup',
  },
  squadCaptain: {
    id: 'rivals.squadCaptain',
    defaultMessage: 'Captain',
  },
  squadVisibility: {
    id: 'VisibilitySettings.visibility',
    defaultMessage: 'Visibility',
  },
  squads: {
    id: 'rivals.squads',
    defaultMessage: 'Squads',
  },
  starting: {
    id: 'StartingStatus.starting',
    defaultMessage: 'Starting',
  },
  tactic: {
    id: 'rivals.tactic',
    defaultMessage: 'Tactic',
  },
  tactics: {
    id: 'rivals.tactics',
    defaultMessage: 'Tactics',
  },
  tied: {
    id: 'Rivals.tied',
    defaultMessage: 'Tied',
  },
  today: {
    id: 'rivals.today',
    defaultMessage: 'Today',
  },
  win: {
    id: 'Rivals.repStake.win',
    defaultMessage: 'Win',
  },
  winningBy: {
    id: 'Rivals.winningBy',
    defaultMessage: 'Winning by {score}',
  },
  wonBy: {
    id: 'Rivals.wonBy',
    defaultMessage: 'Won by {score}',
  },
  youDeclined: {
    id: 'rivals.MatchupStatus.youDeclined',
    defaultMessage: 'You Declined',
  },
  youRequested: {
    id: 'rivals.MatchupStatus.youRequested',
    defaultMessage: 'You Requested',
  },
});

export const gameStatusMessages: {
  [key in GameStatus | string]: MessageDescriptor;
} = defineMessages({
  canceled: { id: 'GameStatus.canceled', defaultMessage: 'Canceled' },
  cancelled: { id: 'GameStatus.cancelled', defaultMessage: 'Canceled' },
  delayed: { id: 'GameStatus.delayed', defaultMessage: 'Delayed' },
  live: { id: 'Game.status.live', defaultMessage: 'Live' },
  played: { id: 'GameStatus.final', defaultMessage: 'Full Time' },
  playing: { id: 'GameStatus.playing', defaultMessage: 'Playing' },
  postponed: { id: 'GameStatus.postponed', defaultMessage: 'Postponed' },
  scheduled: { id: 'GameStatus.scheduled', defaultMessage: 'Scheduled' },
  suspended: { id: 'GameStatus.suspended', defaultMessage: 'Suspended' },
});

export const shopCategories: { [key in ShopItemType]?: MessageDescriptor } =
  defineMessages({
    [ShopItemType.BANNER]: {
      id: 'ShopItemDialog.Title.Banner',
      defaultMessage: 'My Banners',
    },
    [ShopItemType.SHIELD]: {
      id: 'ShopItemDialog.Title.Shield',
      defaultMessage: 'My Badges',
    },
    [ShopItemType.BACKGROUND]: {
      id: 'ShopItemDialog.Title.Background',
      defaultMessage: 'My stadiums',
    },
    [ShopItemType.CLUB_BADGE]: {
      id: 'ShopItemDialog.Title.ClubBadge',
      defaultMessage: 'My Club Badges',
    },
    [ShopItemType.ACHIEVEMENT_BADGE]: {
      id: 'ShopItemDialog.Title.AchievementBadge',
      defaultMessage: 'My Achievement Badges',
    },
    [ShopItemType.EXTRA_SWAP]: {
      id: 'ShopItemDialog.Title.ExtraSwap',
      defaultMessage: 'My Extra Swap',
    },
    [ShopItemType.EXTRA_TEAMS_CAP]: {
      id: 'ShopItemDialog.Title.ExtraTeamsCap',
      defaultMessage: 'My Extra Teams',
    },
    [ShopItemType.LOGO]: {
      id: 'ShopItemDialog.Title.Logo',
      defaultMessage: 'My Logos',
    },
    [ShopItemType.LEVEL_UP]: {
      id: 'ShopItemDialog.Title.LevelUp',
      defaultMessage: 'My level ups',
    },
    [ShopItemType.JERSEY]: {
      id: 'ShopItemDialog.Title.Jersey',
      defaultMessage: 'My Jerseys',
    },
    [ShopItemType.DELIVERABLE_WITH_NO_VARIANT]: {
      id: 'ShopItemDialog.Title.DeliverableWithNoVariant',
      defaultMessage: 'My Caps',
    },
  });

export const shortTimeLeftMessages = defineMessages({
  daysLeft: {
    id: 'ShortTimeLeft.daysLeft',
    defaultMessage: '{days}d',
  },
  hoursLeft: {
    id: 'ShortTimeLeft.hoursLeft',
    defaultMessage: '{hours}h',
  },
  minutesLeft: {
    id: 'ShortTimeLeft.minutesLeft',
    defaultMessage: '{minutes}m',
  },
  monthsLeft: {
    id: 'ShortTimeLeft.monthsLeft',
    defaultMessage: '{months}m',
  },
  secondsLeft: {
    id: 'ShortTimeLeft.secondsLeft',
    defaultMessage: '{seconds}s',
  },
});

export const seasonEligibilityMessages: {
  [key in SeasonEligibility]: MessageDescriptor;
} = defineMessages({
  [SeasonEligibility.IN_SEASON]: {
    id: 'seasonEligibility.inSeason',
    defaultMessage: 'In Season',
  },
  [SeasonEligibility.CLASSIC]: {
    id: 'seasonEligibility.classic',
    defaultMessage: 'Classic',
  },
});

export const referralStateMessages = defineMessages<ReferralState>({
  [ReferralState.ALL]: {
    id: 'MyReferees.all',
    defaultMessage: 'All states',
  },
  [ReferralState.COMPLETED]: {
    id: 'MyReferees.completed',
    defaultMessage: 'Successful',
  },
  [ReferralState.EXPIRED]: {
    id: 'MyReferees.expired',
    defaultMessage: 'Expired',
  },
  [ReferralState.IN_PROGRESS]: {
    id: 'MyReferees.inProgress',
    defaultMessage: 'In progress',
  },
  [ReferralState.PENDING_REVIEW]: {
    id: 'MyReferees.pendingReview',
    defaultMessage: 'Pending review',
  },
  [ReferralState.PENDING_REFEREE_CONFIRMATION]: {
    id: 'MyReferees.pendingRefereeConfirmation',
    defaultMessage: 'Pending confirmation',
  },
  [ReferralState.BLOCKED]: {
    id: 'MyReferees.blocked',
    defaultMessage: 'Suspended',
  },
});

export const earlyAccessLaunchMessages = defineMessages({
  challenger: {
    id: 'earlyAccessLaunch.challenger',
    defaultMessage: 'Challenger Early Access',
  },
  champion: {
    id: 'earlyAccessLaunch.champion',
    defaultMessage: 'Champion Early Access',
  },
  contender: {
    id: 'earlyAccessLaunch.contender',
    defaultMessage: 'Contender Early Access',
  },
  launched: {
    id: 'forYou.earlyAccess.launched',
    defaultMessage: 'Cards launched!',
  },
  newSeason: { id: 'forYou.earlyAccess.title', defaultMessage: 'New season' },
  scoutNewCards: {
    id: 'earlyAccessLaunch.scoutNewCards',
    defaultMessage: 'Scout new cards',
  },
});

export const inventoryMessages = defineMessages({
  allTeams: {
    id: 'Inventory.RevealableCards.allTeams',
    defaultMessage: 'All teams',
  },
  noRevealableCards: {
    id: 'Inventory.RevealableCards.noRevealableCards',
    defaultMessage: 'No cards to reveal',
  },
  noRevealableMatchingCards: {
    id: 'Inventory.RevealableCards.noRevealableMatchingCards',
    defaultMessage: 'No revealable cards matching "{query}"',
  },
  revealAll: {
    id: 'Inventory.RevealableCards.search.reveal_all',
    defaultMessage: 'Reveal all cards',
  },
  revealAllConfirmTitle: {
    id: 'Inventory.RevealableCards.revealAll.confirm.title',
    defaultMessage: 'Are you sure?',
  },
  searchPlaceholder: {
    id: 'Inventory.RevealableCards.searchPlaceholder',
    defaultMessage: 'Search by player, team, position...',
  },
});

export const invoiceTableMessages = defineMessages({
  fees: { id: 'Withdrawal.fees', defaultMessage: 'Fees' },
  subtotal: { id: 'SummaryTable.subtotal', defaultMessage: 'Subtotal' },
});

export const commonMarketplace = defineMessages({
  add: {
    id: 'SwapHeader.switch.add',
    defaultMessage: 'Add',
  },
  addNotAvailable: {
    id: 'SwapHeader.switch.addNotAvailable',
    defaultMessage: 'Not available',
  },
  drop: {
    id: 'SwapHeader.switch.drop',
    defaultMessage: 'Drop',
  },
  dropNotAvailable: {
    id: 'SwapHeader.switch.dropNotAvailable',
    defaultMessage: 'Not available',
  },
});

export const scoutingCenter = defineMessages({
  advancedView: {
    id: 'toggleStatsManager.cta',
    defaultMessage: 'Advanced view',
  },
  findTheBestPlayers: {
    id: 'ForYou.ScoutingCenter.title',
    defaultMessage: 'Find the best players',
  },
  scout: {
    id: 'ForYou.ScoutingCenter.CTA',
    defaultMessage: 'Scout',
  },
  scoutingCenter: {
    id: 'ForYou.ScoutingCenter.label',
    defaultMessage: 'Scouting Center',
  },
});
