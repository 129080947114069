import { TypedDocumentNode, gql } from '@apollo/client';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FormattedNumber } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { LabelL } from '@sorare/core/src/atoms/typography';
import { Ellipsis } from '@sorare/core/src/atoms/typography/Ellipsis';
import { Box } from '@sorare/core/src/atoms/ui/Box';
import { Img } from '@sorare/core/src/atoms/ui/Img';
import { RivalsLightning } from '@sorare/core/src/components/rivals/RepScore/RivalsLightning';
import { FOOTBALL_PLAY_RIVALS_SQUAD_LEADERBOARD_SEASONSLUG_SQUADSLUG_SELECTEDSQUADSLUG } from '@sorare/core/src/constants/__generated__/routes';

import { DumbStatus } from '@sorare/gameplay/src/components/managerTeam/Status/DumbStatus';

import { MySquad_mySquadOverview } from './__generated__/index.graphql';

const Content = styled(Vertical)`
  flex: 1;
`;

type Props = {
  rivals: MySquad_mySquadOverview;
  inline?: boolean;
};

export const MySquad = ({ rivals, inline }: Props) => {
  const { seasonSquad, slug: currentSeasonSlug = '' } =
    rivals?.currentSeason || {};
  const { squad, leaderboard } = seasonSquad || {};

  if (!(squad && leaderboard)) {
    return null;
  }
  const myRanking = leaderboard.rankings.find(
    ({ squad: s }) => s.slug === squad.slug
  );

  return (
    <Link
      to={generatePath(
        FOOTBALL_PLAY_RIVALS_SQUAD_LEADERBOARD_SEASONSLUG_SQUADSLUG_SELECTEDSQUADSLUG,
        {
          seasonSlug: currentSeasonSlug,
          squadSlug: squad.slug,
          selectedSquadSlug: squad.slug,
        }
      )}
    >
      {inline ? (
        <Horizontal>
          <Img width="40" height="40" src={squad.logo?.pictureUrl} alt="" />
          <LabelL bold as="p">
            <Horizontal gap={1}>
              <Ellipsis>{squad.displayName}</Ellipsis>
              <FontAwesomeIcon icon={faChevronRight} />
            </Horizontal>
          </LabelL>
        </Horizontal>
      ) : (
        <Box>
          <Horizontal>
            <Horizontal gap={0}>
              <Img
                width="40"
                height="40"
                src={leaderboard.squadLeaderboardConfig.divisionConfig.badgeUrl}
                alt=""
              />
              <Img width="40" height="40" src={squad.logo?.pictureUrl} alt="" />
            </Horizontal>
            <Content gap={0}>
              <LabelL bold>
                <Ellipsis>{squad.displayName}</Ellipsis>
              </LabelL>
              <SBHorizontal>
                <Horizontal gap={0.5}>
                  <LabelL bold>{myRanking?.ranking || '-'}</LabelL>
                  <LabelL color="var(--c-nd-600)">
                    /{leaderboard.rankings.length}
                  </LabelL>
                </Horizontal>
                {myRanking && (
                  <Horizontal>
                    <DumbStatus divisionChange={myRanking.divisionChange} />
                    <Horizontal gap={0}>
                      <LabelL bold>
                        <FormattedNumber value={myRanking.points} />
                      </LabelL>
                      <RivalsLightning fill="var(--c-rivals-reputation)" />
                    </Horizontal>
                  </Horizontal>
                )}
              </SBHorizontal>
            </Content>
          </Horizontal>
        </Box>
      )}
    </Link>
  );
};

MySquad.fragments = {
  footballRivalsRoot: gql`
    fragment MySquad_mySquadOverview on FootballRivalsRoot {
      id
      currentSeason {
        slug
        seasonSquad(userSlug: $user) {
          id
          squad {
            slug
            displayName
            logo {
              id
              pictureUrl
            }
          }
          leaderboard {
            id
            squadLeaderboardConfig {
              divisionConfig {
                id
                badgeUrl
              }
            }
            rankings {
              id
              squad {
                slug
              }
              ranking
              divisionChange
              points
            }
          }
        }
      }
    }
  ` as TypedDocumentNode<MySquad_mySquadOverview>,
};
