import { useIntl } from 'react-intl';

import commonEssence from 'assets/rewards/common-essence.png';
import { glossary } from 'lib/glossary';

type Props = { width?: number; height?: number };
export const CommonEssenceIcon = ({ width, height }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <img
      width={width}
      height={height}
      src={commonEssence}
      alt={formatMessage(glossary.commonEssence)}
    />
  );
};
