import { TypedDocumentNode, gql } from '@apollo/client';
import { Suspense } from 'react';

import { Rarity } from '__generated__/globalTypes';
import { lazy } from 'lib/retry';
import { shardsIconUrl } from 'lib/rewards';

import { CardShardsReward_cardShardsReward } from './__generated__/index.graphql';

const BuyConfirmationEssence = lazy(
  async () => import('@sorare/trois/src/components/BuyConfirmationEssence')
);

const MAP = {
  [Rarity.common]: 'common',
  [Rarity.custom_series]: 'limited',
  [Rarity.limited]: 'limited',
  [Rarity.rare]: 'rare',
  [Rarity.super_rare]: 'super_rare',
  [Rarity.unique]: 'unique',
} as const;

type Props = {
  reward: CardShardsReward_cardShardsReward;
};

export const CardShardsReward = ({ reward: { rarity, quantity } }: Props) => {
  return (
    <Suspense>
      <BuyConfirmationEssence
        rarity={MAP[rarity]}
        quantity={quantity}
        fallback={
          <img
            src={shardsIconUrl(rarity)}
            alt=""
            style={{
              maxWidth: '100px',
            }}
          />
        }
      />
    </Suspense>
  );
};

CardShardsReward.fragments = {
  cardShardsReward: gql`
    fragment CardShardsReward_cardShardsReward on CardShardsReward {
      id
      rarity
      quantity
    }
  ` as TypedDocumentNode<CardShardsReward_cardShardsReward>,
};
