import { TypedDocumentNode, gql } from '@apollo/client';

import { PostalAddressForm } from 'components/settings/PostalAddressForm';
import { ActiveUserAvatar } from 'components/user/ActiveUserAvatar';
import { MyClubLink } from 'components/user/MyClubLink';
import { UserName } from 'components/user/UserName';
import { currentUserConversionCredits } from 'hooks/useConversionCredits';
import { monetaryAmountFragment } from 'lib/monetaryAmount';

import {
  CurrentUseProvider_ethereumAccounts,
  CurrentUseProvider_fiatAccounts,
  CurrentUseProvider_sportProfile,
  CurrentUserProvider_coinBalances,
  CurrentUserProvider_completablePromotionalEventCompletion,
  CurrentUserProvider_currentSportUnreadNotificationsCount,
  CurrentUserProvider_currentUser,
  CurrentUserProvider_inventoryBalances,
  CurrentUserProvider_managerProgression,
  CurrentUserProvider_userSportProfile,
  CurrentUserProvider_walletRecovery,
  CurrentUserQuery,
  CurrentUserQueryVariables,
  commonCardMarketplace_currentUser,
  onCurrentUserWasUpdated,
  onCurrentUserWasUpdatedVariables,
  onDeviceWasUpdated,
  onDeviceWasUpdatedVariables,
} from './__generated__/queries.graphql';

const userSportProfileFragment = gql`
  fragment CurrentUserProvider_userSportProfile on UserSportProfileInterface {
    id
    onboarded
    clubName
    shieldUrl
    clubBadge {
      id
      pictureUrl
      name
    }
    achievementBadges {
      id
    }
  }
` as TypedDocumentNode<CurrentUserProvider_userSportProfile>;
const sportProfile = gql`
  fragment CurrentUseProvider_sportProfile on CurrentUser {
    slug
    cardCounts(sport: $sport) {
      total
      limited
      rare
      super_rare: superRare
      superRare
      unique
      common
      custom_series: customSeries
      customSeries
    }
    footballUserProfile {
      id
      ...CurrentUserProvider_userSportProfile
    }
    baseballUserProfile {
      id
      ...CurrentUserProvider_userSportProfile
    }
    nbaUserProfile {
      id
      ...CurrentUserProvider_userSportProfile
    }
  }
  ${userSportProfileFragment}
` as TypedDocumentNode<CurrentUseProvider_sportProfile>;

export const walletRecovery = gql`
  fragment CurrentUserProvider_walletRecovery on CurrentUser {
    slug
    wallet {
      id
      ethereumAddress
      status
      holdsValue
      recoveryOptions {
        id
        destination
        method
        status
      }
    }
  }
` as TypedDocumentNode<CurrentUserProvider_walletRecovery>;

export const ethereumAccounts = gql`
  fragment CurrentUseProvider_ethereumAccounts on CurrentUser {
    slug
    myAccounts {
      id
      account {
        id
        sorareManaged
        accountable {
          ... on Node {
            id
          }
          ... on EthereumAccount {
            id
            address
          }
          ... on StarkwareAccount {
            id
          }
        }
      }
    }
  }
` as TypedDocumentNode<CurrentUseProvider_ethereumAccounts>;
export const fiatAccounts = gql`
  fragment CurrentUseProvider_fiatAccounts on CurrentUser {
    slug
    myAccounts {
      id
      accountable {
        ... on Node {
          id
        }
        ... on PrivateFiatWalletAccount {
          id
          state
          firstName
          lastName
          nationalityCode
          dob
          countryOfResidenceCode
          availableBalance
          totalBalance
          kycStatus
          kycRefusedReason
          depositBankAccount {
            ... on BankAccountInterface {
              id
            }
            ... on IbanBankAccount {
              id
              ownerName
              bic
              iban
            }
          }
          publicInfo {
            id
            currency
          }
        }
      }
    }
  }
` as TypedDocumentNode<CurrentUseProvider_fiatAccounts>;

const managerProgression = gql`
  fragment CurrentUserProvider_managerProgression on CurrentUser {
    slug
    footballChallengesManagerProgression: managerProgression(sport: FOOTBALL) {
      id
      levelReached
      points
    }
  }
` as TypedDocumentNode<CurrentUserProvider_managerProgression>;

export const coinBalancesFragment = gql`
  fragment CurrentUserProvider_coinBalances on CurrentUser {
    slug
    coinBalance(sport: $sport)
  }
` as TypedDocumentNode<CurrentUserProvider_coinBalances>;

const completablePromotionalEventCompletion = gql`
  fragment CurrentUserProvider_completablePromotionalEventCompletion on CurrentUser {
    slug
    completablePromotionalEvent {
      slug
      promotionalEventCompletion {
        id
        aasmState
        actualAmounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
      }
    }
  }
  ${monetaryAmountFragment}
` as TypedDocumentNode<CurrentUserProvider_completablePromotionalEventCompletion>;

export const inventoryBalancesFragment = gql`
  fragment CurrentUserProvider_inventoryBalances on CurrentUser {
    slug
    anyUserSportProfile(sport: $sport) {
      id
      blueprintCardsCount
    }
    unopenedBoxes: unopenedProbabilisticBundles(sport: $sport) {
      totalCount
    }
    raffleTicketsCount: availableRaffleTicketsCount(sport: $sport)
    shardsChests: cardShardsChests(sport: $sport) {
      id
      rarity
      cardShardsCount
      newThresholdUnlocked
    }
  }
` as TypedDocumentNode<CurrentUserProvider_inventoryBalances>;

export const currentSportUnreadNotificationsCountFragment = gql`
  fragment CurrentUserProvider_currentSportUnreadNotificationsCount on CurrentUser {
    slug
    # this is required for Notifications refetch to work
    allUnreadNotificationsCount: unreadNotificationsCount(sports: [$sport])
    currentSportUnreadNotificationsCount: unreadNotificationsCount(
      sports: [$sport]
      categories: $notificationCategories
    )
  }
` as TypedDocumentNode<CurrentUserProvider_currentSportUnreadNotificationsCount>;

export const commonCardMarketplaceFragment = gql`
  fragment commonCardMarketplace_currentUser on CurrentUser {
    slug
    commonCardPoints(sport: $sport)
    cardCounts(sport: $sport) {
      common
    }
  }
` as TypedDocumentNode<commonCardMarketplace_currentUser>;

export const currentUser = gql`
  fragment CurrentUserProvider_currentUser on CurrentUser {
    id
    slug
    suspended
    forcedIntoOnboarding
    footballLast30DaysLineupsCount
    currentFootballRivalsManager {
      id
      sawKickoffWelcomeToKickoff
      favoriteTeam {
        slug
      }
    }
    createdAt
    email
    nickname
    active
    fromPath
    blockedUntil
    confirmedDevice
    # this is required for Notifications refetch to work
    allUnreadNotificationsCount: unreadNotificationsCount(sports: [$sport])
    referrer {
      slug
      suspended
      nickname
      createdAt
      profile {
        id
        verified
      }
      ...ActiveUserAvatar_user
      ...UserName_publicUserInfoInterface
    }
    unclaimedReferralRewardsCount
    referralRewardsCount
    referralUrl
    sorarePrivateKey {
      iv
      salt
      encryptedPrivateKey
    }
    sorarePrivateKeyRecovery
    sorareAddress
    sorareAddressBalance
    starkKey
    depositedEth
    currentDevice {
      id
    }
    availableBalance
    availableBalanceForWithdrawal
    totalBalance
    otpRequiredForLogin
    featureFlagCustomAttributes
    phoneNumber
    phoneNumberVerificationRequested
    phoneNumberVerified
    confirmed
    profile {
      id
      verified
      status
      discordUsername
      twitterUsername
      enabledWallets
    }
    unverifiedPhoneNumber
    userSettings {
      id
      disableAllEmails
      locale
      currency
      fiatCurrency
      lifecycle
      hideBalance
      hidePermanentTutorials
      tcuStatus
      rewardCurrency
      discoverable
      ...PostalAddressForm_userSettings
    }
    rampSupported
    moonpaySupported
    apiKey
    mustAcceptTcus
    followingCount
    followersCount
    connectedOauths {
      id
      email
      provider
    }
    mangopayUser {
      id
      blocked
      requiredDocuments
      additionalKycRequests {
        id
        requestType
        aasmState
      }
    }
    noCardRouteEnabled
    so5NoCardRouteOpened
    hideTutorials
    warningFlags {
      id
      reason
      relatedUsers {
        slug
        nickname
        ...MyClubLink_publicUserInfoInterface
      }
      until
    }
    completablePromotionalEvent {
      slug
      startDate
      endDate
      name
      description
      blogUrl
      pictureUrl
      eligibilityRules {
        id
        minimumDepositAmount
      }
      rewardsProbabilisticBundleConfig {
        id
        slots {
          probableConfigs {
            conversionCredit {
              id
              maxDiscount {
                ...MonetaryAmountFragment_monetaryAmount
              }
              percentageDiscount
            }
          }
        }
      }
      completionRules
      promotionalEventCompletion {
        id
        aasmState
        expiresAt
        actualAmounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
        targetAmount {
          ...MonetaryAmountFragment_monetaryAmount
        }
      }
      ... on DepositPromotionalEvent {
        slug
        completedParticipants
      }
    }
    betaTesterFootballCommonCardsGame
    xpBalances: inGameCurrencyBalances(
      sport: $sport
      inGameCurrencies: [LIMITED_XP, RARE_XP, SUPER_RARE_XP, UNIQUE_XP]
    ) {
      amount
      currency
      capAlmostReached
    }
    ...CurrentUseProvider_sportProfile
    ...CurrentUserProvider_walletRecovery
    ...CurrentUseProvider_ethereumAccounts
    ...CurrentUseProvider_fiatAccounts
    ...ActiveUserAvatar_user
    ...CurrentUserProvider_managerProgression
    ...CurrentUserProvider_coinBalances
    ...CurrentUserProvider_inventoryBalances
    ...commonCardMarketplace_currentUser
    ...CurrentUserProvider_completablePromotionalEventCompletion
  }
  ${walletRecovery}
  ${sportProfile}
  ${ethereumAccounts}
  ${fiatAccounts}
  ${ActiveUserAvatar.fragments.user}
  ${PostalAddressForm.fragments.userSettings}
  ${UserName.fragments.user}
  ${managerProgression}
  ${coinBalancesFragment}
  ${inventoryBalancesFragment}
  ${commonCardMarketplaceFragment}
  ${MyClubLink.fragments.user}
  ${monetaryAmountFragment}
  ${completablePromotionalEventCompletion}
  ${monetaryAmountFragment}
` as TypedDocumentNode<CurrentUserProvider_currentUser>;

export const CURRENT_USER_QUERY = gql`
  query CurrentUserQuery($sport: Sport!) {
    currentUser {
      slug
      ...CurrentUserProvider_currentUser
    }
  }
  ${currentUser}
` as TypedDocumentNode<CurrentUserQuery, CurrentUserQueryVariables>;

export const onDeviceSubscription = gql`
  subscription onDeviceWasUpdated {
    deviceWasUpdated {
      id
      eventType
    }
  }
` as TypedDocumentNode<onDeviceWasUpdated, onDeviceWasUpdatedVariables>;

export const subscription = gql`
  subscription onCurrentUserWasUpdated(
    $sport: Sport!
    $notificationCategories: [NotificationCategoryInput!]
  ) {
    currentUserWasUpdated {
      slug
      availableBalance
      availableBalanceForWithdrawal
      sorareAddressBalance
      confirmed
      mangopayUser {
        id
        blocked
      }
      pendingDeposits {
        id
        date
        providerType
        transactionHash
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
      }
      myRecentActiveBids {
        id
        maximumAmounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
        auction {
          id
          privateCurrentPrice
          privateMinNextBid
          currency
        }
      }
      ...CurrentUser_conversionCredits
      ...CurrentUseProvider_fiatAccounts
      ...CurrentUserProvider_managerProgression
      ...CurrentUserProvider_coinBalances
      ...CurrentUserProvider_inventoryBalances
      ...CurrentUserProvider_currentSportUnreadNotificationsCount
      ...CurrentUserProvider_completablePromotionalEventCompletion
      ...commonCardMarketplace_currentUser
    }
  }
  ${monetaryAmountFragment}
  ${currentUserConversionCredits}
  ${fiatAccounts}
  ${managerProgression}
  ${coinBalancesFragment}
  ${inventoryBalancesFragment}
  ${currentSportUnreadNotificationsCountFragment}
  ${completablePromotionalEventCompletion}
  ${commonCardMarketplaceFragment}
` as TypedDocumentNode<
  onCurrentUserWasUpdated,
  onCurrentUserWasUpdatedVariables
>;
