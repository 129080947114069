import { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { Vertical } from 'atoms/layout/flex';
import { Overline, Text14, Text16, Title5 } from 'atoms/typography';
import { QRCode } from 'components/QRCode';
import {
  GraphQLResult,
  GraphqlForm,
  SubmitButtonProps,
  TextField,
} from 'components/form/Form';
import { OTP_ATTEMPT_LENGTH } from 'constants/verificationCode';
import { useSnackNotificationContext } from 'contexts/snackNotification';
import { useUpdate2FA } from 'hooks/useUpdate2FA';

const messages = defineMessages({
  dialogSubtitle: {
    id: 'Settings.enable2FA.dialogSubtitle',
    defaultMessage: 'Setup instructions:',
  },
  dialogDescription1: {
    id: 'Settings.enable2FA.dialogDescription1',
    defaultMessage:
      '1. Download an authenticator app (e.g. Google Authenticator, 1Password, Authy)',
  },
  dialogDescription2: {
    id: 'Settings.enable2FA.dialogDescription2',
    defaultMessage: '2. Scan the QR code using your authenticator app',
  },
  dialogDescription2Subtitle: {
    id: 'Settings.enable2FA.dialogDescription2Subtitle',
    defaultMessage:
      'Or copy this key and enter it manually in your authenticator app',
  },
  dialogDescription3: {
    id: 'Settings.enable2FA.dialogDescription3',
    defaultMessage: '3. Enter the six-digit code',
  },
  submit: {
    id: 'Settings.enable2FA.submit',
    defaultMessage: 'Enable 2FA',
  },
});

const StyledGraphqlForm = styled(GraphqlForm)`
  margin-bottom: 0;
`;

const QRCodeContainer = styled(Vertical).attrs({ center: true })`
  text-align: center;
  justify-content: flex-start;
`;

const DialogForm = styled(Vertical).attrs({ gap: 2, center: true })`
  justify-content: flex-start;
`;

const DialogContent = styled(Vertical).attrs({ gap: 2 })`
  padding: var(--triple-unit);
`;

type Props = {
  onEnabling2FA: (codes: string[]) => void;
};
export const Enable2FAForm = ({ onEnabling2FA }: Props) => {
  const { showNotification } = useSnackNotificationContext();

  const { enable2FAMutation, generateOtpProvisioningUriMutation } =
    useUpdate2FA();
  const [otpProvisioningUri, setOtpProvisioningUri] = useState<string | null>(
    null
  );

  useEffect(() => {
    generateOtpProvisioningUriMutation({
      variables: {
        input: {},
      },
    }).then(result => {
      if (result?.data?.generateOtpProvisioningUri) {
        setOtpProvisioningUri(
          result.data.generateOtpProvisioningUri.otpProvisioningUri
        );
      }
    });
  }, [generateOtpProvisioningUriMutation]);

  const otpCode = otpProvisioningUri
    ? new URL(otpProvisioningUri).searchParams.get('secret')
    : null;

  const submit = async (
    attributes: any,
    onResult: (result: GraphQLResult) => void
  ) => {
    const { data: mutationData } = await enable2FAMutation({
      variables: {
        input: { ...attributes },
      },
    });
    if (mutationData?.enable2Fa) {
      onResult(mutationData.enable2Fa);
    }
  };

  const onSuccess = (result: any) => {
    showNotification('2faEnabled');
    onEnabling2FA(result.otpBackupCodes);
  };

  return (
    <StyledGraphqlForm
      onSubmit={(attributes, doOnResult) => {
        submit(attributes, doOnResult);
      }}
      onChange={(values, doSubmit) => {
        const { otpAttempt } = values;
        if (otpAttempt?.length === OTP_ATTEMPT_LENGTH) doSubmit();
      }}
      onSuccess={onSuccess}
      render={(
        Error: React.ComponentType<React.PropsWithChildren<unknown>>,
        SubmitButton: FunctionComponent<
          React.PropsWithChildren<SubmitButtonProps>
        >
      ) => (
        <DialogContent>
          <Title5>
            <FormattedMessage {...messages.dialogSubtitle} />
          </Title5>
          <Text16>
            <FormattedMessage {...messages.dialogDescription1} />
          </Text16>
          <Text16>
            <FormattedMessage {...messages.dialogDescription2} />
          </Text16>
          <QRCodeContainer>
            <QRCode value={otpProvisioningUri!} />
            <Text14 bold>
              <FormattedMessage {...messages.dialogDescription2Subtitle} />
            </Text14>
            <Overline>{otpCode}</Overline>
          </QRCodeContainer>
          <Text16>
            <FormattedMessage {...messages.dialogDescription3} />
          </Text16>
          <DialogForm>
            <div>
              <Error />
            </div>

            <TextField
              required
              name="otpAttempt"
              autoComplete="one-time-code"
              autoFocus
            />
            <SubmitButton size="medium">
              <FormattedMessage {...messages.submit} />
            </SubmitButton>
          </DialogForm>
        </DialogContent>
      )}
    />
  );
};
