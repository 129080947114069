import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { So5State } from '@sorare/core/src/__generated__/globalTypes';
import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { Title3, Title5 } from '@sorare/core/src/atoms/typography';
import { FOOTBALL_PLAY_PRO_FIXTURE } from '@sorare/core/src/constants/__generated__/routes';

import Header from '../Header';

const Root = styled(Vertical).attrs({ gap: 3 })``;
const CenteredFlexContainer = styled(Vertical).attrs({ gap: 2, center: true })`
  align-self: center;
  justify-content: flex-start;
`;

type Props = {
  errors?: { message: string }[];
  loading: boolean;
  title?: MessageDescriptor;
  subtitle?: MessageDescriptor;
  postAction?: 'lobby' | 'email';
};

const Form = ({ loading, errors, title, subtitle, postAction }: Props) => {
  return (
    <Root>
      <Header />
      {loading && <LoadingIndicator />}
      <CenteredFlexContainer>
        {errors?.length === 0 && (
          <>
            {title && (
              <Title3>
                <FormattedMessage {...title} />
              </Title3>
            )}
            {subtitle && (
              <Title5>
                <FormattedMessage {...subtitle} />
              </Title5>
            )}
          </>
        )}
        {errors?.map(({ message }) => <Title3 key={message}>{message}</Title3>)}
        {postAction === 'lobby' && (
          <Button
            to={generatePath(FOOTBALL_PLAY_PRO_FIXTURE, {
              fixture: So5State.UPCOMING.toLowerCase(),
            })}
            color="primary"
            size="medium"
          >
            <FormattedMessage
              id="NoCardEntry.Form.Cta.title"
              defaultMessage="Go back to the lobby"
            />
          </Button>
        )}
        {postAction === 'email' && (
          <Title3>
            <FormattedMessage
              id="NoCardEntry.Form.email"
              defaultMessage="Please check your emails"
            />
          </Title3>
        )}
      </CenteredFlexContainer>
    </Root>
  );
};

export default Form;
