import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { HeadlineL, LabelM } from '@sorare/core/src/atoms/typography';
import { Avatar } from '@sorare/core/src/components/user/Avatar';
import { navLabels, rivals } from '@sorare/core/src/lib/glossary';

import { GridBackground } from '@sorare/gameplay/src/components/rivals/GridBackground';
import { BackgroundAligned } from '@sorare/gameplay/src/components/rivals/GridBackground/BackgroundAligned';

import { SquadOverview_footballRivalsSquad } from './__generated__/index.graphql';

const Root = styled(Vertical).attrs({ gap: 0 })`
  padding-bottom: var(double-unit);
`;
const StyledDivBackground = styled(GridBackground)`
  border-top: 1px solid var(--c-nd-200);
`;
const Logo = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
`;
const BottomSection = styled(Vertical).attrs({ gap: 0 })`
  padding: 0 var(--double-unit);
`;
const DivisionLine = styled(SBHorizontal)`
  padding-bottom: var(--intermediate-unit);
  border-bottom: 1px solid var(--c-nd-200);
`;
const DivisionLogo = styled.img`
  width: var(--triple-unit);
  height: var(--triple-unit);
  object-fit: contain;
`;
const MembersSection = styled(Vertical).attrs({ gap: 1.5 })`
  padding-top: var(--intermediate-unit);
`;

type Props = {
  squad: SquadOverview_footballRivalsSquad;
  squadMembershipsMaxCount: number;
  title?: ReactNode;
};
export const SquadOverview = ({
  squad,
  squadMembershipsMaxCount,
  title,
}: Props) => {
  const {
    logo,
    displayName,
    divisionConfig,
    membershipsCount,
    managers,
    administrator,
  } = squad;

  return (
    <Root gap={0}>
      <StyledDivBackground
        bgColor="var(--c-nd-50)"
        color="#777777"
        height={240}
        intersectionOnCenter
      >
        <BackgroundAligned>
          <Vertical gap={2} center>
            {logo && <Logo src={logo.pictureUrl} alt="" />}
            <Vertical>
              {title}
              <HeadlineL className="text-center">{displayName}</HeadlineL>
            </Vertical>
          </Vertical>
        </BackgroundAligned>
      </StyledDivBackground>
      <BottomSection>
        <DivisionLine>
          <LabelM color="var(--c-nd-700)">
            <FormattedMessage {...rivals.division} />
          </LabelM>
          <DivisionLogo
            src={divisionConfig.badgeUrl}
            alt={divisionConfig.shortTitle}
          />
        </DivisionLine>
        <MembersSection>
          <SBHorizontal>
            <LabelM color="var(--c-nd-700)">
              <FormattedMessage {...navLabels.members} />
            </LabelM>
            <LabelM bold>
              {membershipsCount}/{squadMembershipsMaxCount}
            </LabelM>
          </SBHorizontal>
          <Vertical>
            {managers.map(
              ({ id, user, divisionConfig: managerDivisionConfig }) => {
                const isAdmin = user.slug === administrator?.slug;

                return (
                  <SBHorizontal center key={id}>
                    <Horizontal>
                      <Avatar user={user} rounded variant="small" />
                      <Horizontal gap={0.5}>
                        <LabelM bold>{user.nickname}</LabelM>
                        {isAdmin && (
                          <LabelM color="var(--c-rivals-purple-800)">
                            <FormattedMessage {...rivals.squadCaptain} />
                          </LabelM>
                        )}
                      </Horizontal>
                    </Horizontal>
                    <DivisionLogo
                      src={managerDivisionConfig.badgeUrl}
                      alt={managerDivisionConfig.shortTitle}
                    />
                  </SBHorizontal>
                );
              }
            )}
          </Vertical>
        </MembersSection>
      </BottomSection>
    </Root>
  );
};

SquadOverview.fragments = {
  footballRivalsSquad: gql`
    fragment SquadOverview_footballRivalsSquad on FootballRivalsSquad {
      slug
      id
      displayName
      membershipsCount
      administrator {
        slug
      }
      logo {
        id
        pictureUrl
      }
      divisionConfig {
        id
        badgeUrl
        shortTitle
      }
      managers {
        id
        divisionConfig {
          id
          badgeUrl
          shortTitle
        }
        user {
          nickname
          slug
          ...Avatar_user
        }
      }
    }
    ${Avatar.fragments.user}
  ` as TypedDocumentNode<SquadOverview_footballRivalsSquad>,
};
